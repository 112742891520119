import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UserFormComponent } from "../../forms/user-form/user-form.component";
import { GetDataService } from "src/app/services/get-data.service";
import { DeleteDataService } from "src/app/services/delete-data.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  displayedData: any;
  dtls: any;
  editData: any;
  deleteId: any;
  deleteUId: any;
  metaData: any = {};
  pageLen: number = 10;
  bname: any;
  rname: any;
  roles:any;
  dataPage: number = 1;
  @ViewChild("dialogBox") dialogBox!: TemplateRef<any>;
  constructor(
    public dialog: MatDialog,
    public getData: GetDataService,
    private toastr: ToastrService,
    public deleteData: DeleteDataService
  ) {}
  ngOnInit() {
    this.loadData();
    let role: any = localStorage.getItem("userPrivileges");
    this.roles = JSON.parse(role);
  }
  paginationChange(page: any) {
    if (page == "prev") {
      this.dataPage = this.dataPage - 1;
    } else if (page == "next") {
      this.dataPage = this.dataPage + 1;
    } else {
      this.dataPage = page.target.value;
    }
    this.loadData();
  }
  pageSize(event: any) {
    this.pageLen = event.target.value;
    this.loadData();
  }
  loadData() {
    this.getData.getUsers(this.pageLen, this.dataPage).subscribe(
      (res) => {
        this.dtls = res;
        this.metaData = this.dtls.meta.pagination;
        this.displayedData = this.dtls.data;
        this.displayedData.forEach((ele: any, index: any) => {
          this.getBranchbyId(ele.attributes.bid, index);
          this.getRolebyId(ele.attributes.role, index);
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  addProduct() {
    const dialogRef = this.dialog.open(UserFormComponent, {
      width: "40%",
      data: {
        data: this.editData,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.editData = {};
      this.loadData();
    });
  }
  editProduct(product: any) {
    this.editData = product;
    this.addProduct();
  }
  cancelDelete() {
    this.deleteId = "";
    this.dialog.closeAll();
  }
  confirmDelete() {
    this.deleteData.deleteUsers(this.deleteUId).subscribe(
      (res) => {
        this.deleteData.deleteProfile(this.deleteId).subscribe(
          (res) => {
            this.deleteId = "";
            this.deleteUId = "";
            this.cancelDelete();
            this.loadData();
            this.showToast();
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }
  showToast() {
    this.toastr.success("Product Deleted Succesfully", "Success!");
  }
  async getBranchbyId(id: any, index: any): Promise<void> {
    this.getData.getBrancheName(id).subscribe(
      (res) => {
        this.bname = res;
        this.bname = this.bname.data.attributes.name;
        this.displayedData[index].attributes.bname = this.bname;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  async getRolebyId(id: any, index: any): Promise<void> {
    this.getData.getRoleName(id).subscribe(
      (res) => {
        this.rname = res;
        this.rname = this.rname.data.attributes.Name;
        this.displayedData[index].attributes.rname = this.rname;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  deleteProduct(product: any) {
    this.deleteId = product.id;
    this.deleteUId = product.attributes.uid;
    this.dialog.open(this.dialogBox);
  }
}
