import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InsertDataService } from './../../../services/insert-data.service';
import { UpdateDataService } from './../../../services/update-data.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { WebCamComponent } from '../../web-cam/web-cam.component';
interface rel {
  "name" : String
}
@Component({
  selector: 'app-customers-form',
  templateUrl: './customers-form.component.html',
  styleUrls: ['./customers-form.component.scss']
})
export class CustomersFormComponent implements OnInit {
  formInputs: FormGroup;
  navData : any;
  customerName: string = '';
  customerPrice: string = '';
  relation :Array<rel> = []
  customerPhotoBase64: string | null = null;
  govtIdPhotoBase64: string | null = null;
  idLable : string;
  idtypes:any = {
    "1":"Aadhar Card",
    "2":"PAN Card",
    "3":"Driving Licence",
    "4":"Ration Card"
  }
  constructor(
    public dialogRef: MatDialogRef<CustomersFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
    public addData: InsertDataService,
    private toastr: ToastrService,
    public updatedata : UpdateDataService,
    public dialog: MatDialog,
  ) {
    this.idLable = "ID Proof";
    this.formInputs = this.formBuilder.group({
      sur: ['', [Validators.required, Validators.minLength(2)]],
      name: ['', [Validators.required, Validators.minLength(2)]],
      contact1: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      contact2: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      proofType: ['', [Validators.required, Validators.minLength(2)]],
      nominee: ['', [Validators.required, Validators.minLength(2)]],
      relation: ['', [Validators.required, Validators.minLength(2)]],
      co: ['', [Validators.required, Validators.minLength(2)]],
      fname: ['', [Validators.required, Validators.minLength(2)]],
      proofno: ['', [Validators.required, Validators.minLength(2)]],
      nomineeContact: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      address: ['', [Validators.required, Validators.minLength(2)]],
    });
    this.navData = data.data;
    this.relation = [
      {"name":"Father"},
      {"name":"Mother"},
      {"name":"Son"},
      {"name":"Daughter"},
      {"name":"Son-in-Law"},
      {"name":"Daughter-in-Law"},
      {"name":"Father-in-Law"},
      {"name":"Mother-in-Law"},
      {"name":"Brother"},
      {"name":"Sister"},
      {"name":"Wife"},
      {"name":"Husband"},
      {"name":"Other"}
    ]
  }

  ngOnInit(): void {
    if(this.navData && this.navData.id){
      this.formInputs.controls["name"].setValue(this.navData.attributes.name);
      this.formInputs.controls["sur"].setValue(this.navData.attributes.sur);
      this.formInputs.controls["contact2"].setValue(this.navData.attributes.contact2);
      this.formInputs.controls["contact1"].setValue(this.navData.attributes.contact1);
      this.formInputs.controls["nominee"].setValue(this.navData.attributes.nominee);
      this.formInputs.controls["co"].setValue(this.navData.attributes.co);
      this.formInputs.controls["fname"].setValue(this.navData.attributes.fname);
      this.formInputs.controls["proofno"].setValue(this.navData.attributes.proofno);
      this.formInputs.controls["proofType"].setValue(this.navData.attributes.proofType);
      this.formInputs.controls["relation"].setValue(this.navData.attributes.relation);
      this.formInputs.controls["nomineeContact"].setValue(this.navData.attributes.nomineeContact);
      this.formInputs.controls["address"].setValue(this.navData.attributes.address);
      this.customerPhotoBase64 = this.navData.attributes.photo;
      this.govtIdPhotoBase64 = this.navData.attributes.proof 
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  idProofType(ev:any){
    let idtype:string = ev.target.value;
    this.idLable = this.idtypes[idtype];
  }
  onSubmit(): void {
    if(this.navData && this.navData.id){
      this.formInputs.value.id = this.navData.id;
      this.formInputs.value.photo = this.customerPhotoBase64;
        this.formInputs.value.proof = this.govtIdPhotoBase64;
        this.updatedata.updateCustomer(this.formInputs.value)
        .subscribe(res => {
          this.formInputs.reset();
          this.showToast("2");
          this.onNoClick();
        }, err => {
          this.showToast("0")
          console.log(err);
        })
      }
    else{
      this.formInputs.value.photo = this.customerPhotoBase64;
      this.formInputs.value.proof = this.govtIdPhotoBase64;
      this.addData.addCustomer(this.formInputs.value).subscribe(res => {
        this.formInputs.reset();
        this.showToast("1");
        this.onNoClick();
      }, err => {
        this.showToast("0")
        console.log(err);
      })
    }
}
  showToast(params: any) {
    if (params == '1') {
      this.toastr.success('Customer Added Succesfully', 'Success!');
    }
    else if (params == '2') {
      this.toastr.success('Customer Updated Succesfully', 'Success!');
    }
    else {
      this.toastr.warning('Something went wrong, try again later', 'Oops!');
    }
  }
  handleCustPhoto(event : any) : void{
    this.convertFileToBase64(event.target.files[0])
      .then(base64 => {
        this.customerPhotoBase64 = base64
      })
      .catch(error => console.error('Error converting govt ID photo:', error));    
  }
  handleGovtIdPhoto(event: any): void {
    this.convertFileToBase64(event.target.files[0])
      .then(base64 => {
        this.govtIdPhotoBase64 = base64
      })
      .catch(error => console.error('Error converting govt ID photo:', error));
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String.split(',')[1]);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }
  openWebCam(type:string) {
    const dialogRef = this.dialog.open(WebCamComponent, {
      width: '950px',
      height: '450px',
      data:{
        data : type
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.type == "U"){
        this.customerPhotoBase64 = result.image;
      }else{
        this.govtIdPhotoBase64 = result.image;
      }
    });
  }

}

