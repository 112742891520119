<div class="container my-6 card-bg">
    <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
      <div class="row g-3">
        <div class="col-12">
          <h3 class="mb-0">Add user</h3>
        </div>
        <div class="col-6">
          <label for="name" class="form-label">Name</label>
          <input type="text" class="form-control" name="name" formControlName="name" id="name" placeholder="User's Name" aria-label="User's Name">
        </div>
        <div class="col-6">
          <label for="username" class="form-label">User Name</label>
          <input type="text" class="form-control" [attr.readonly]="navData != null ? true : null" name="username" formControlName="username" id="username" placeholder="User Name" aria-label="User Name">
        </div>
        <div class="col-6">
          <label for="email" class="form-label">E-mail ID</label>
          <input type="email" class="form-control" name="email" formControlName="email" id="email" placeholder="E-mail ID" aria-label="E-mail ID">
        </div>
        <div class="col-6">
          <label for="contact" class="form-label">Contact Number</label>
          <input type="tel" class="form-control" name="mobile" formControlName="mobile" id="contact" placeholder="Contact Number" aria-label="Contact Number">
        </div>
        <div class="col-6" [class.d-none]="navData != null">
          <label for="password" class="form-label">Password</label>
          <input type="password" class="form-control" name="password" formControlName="password" id="password" placeholder="Password" aria-label="Password">
        </div>
        <div class="col-6" [class.d-none]="navData != null">
          <label for="confirmPassword" class="form-label">Confirm Password</label>
          <input type="password" class="form-control" name="confirmPassword" formControlName="confirmPassword" id="confirmPassword" placeholder="Confirm Password" aria-label="Confirm Password">
        </div>
        <div class="col-6">
          <label for="branch" class="form-label">Branch</label>
          <select class="form-select" name="bid" formControlName="bid" id="bid">
            <option value="">-- Select --</option>
            <option *ngFor="let branch of branches" value="{{branch.id}}">{{branch.attributes.name}}</option>
          </select>
        </div>
        <div class="col-6">
          <label for="role" class="form-label">Role</label>
          <select class="form-select" name="role" formControlName="role" id="role">
            <option value="">-- Select --</option>
            <option *ngFor="let role of roles" value="{{role.id}}">{{role.attributes.Name}}</option>
          </select>
        </div>
      </div>
      <div class="mt-3">
        <button type="button" class="btn btn-dark" (click)="onNoClick()">Close</button> &nbsp;
        <input type="submit" class="btn btn-primary" value="Save">
      </div>
    </form>
  </div>
  