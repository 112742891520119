<!--sidebar-->
<c-sidebar
  #sidebar="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  id="sidebar"
  visible
>
  <c-sidebar-brand
    routerLink="./"
  > Cashway</c-sidebar-brand>
  <ng-scrollbar pointerEventsMethod="scrollbar">
    <c-sidebar-nav
      [navItems]="navItems"
      dropdownMode="close"
    />
  </ng-scrollbar>
  <c-sidebar-toggler
    *ngIf="!sidebar.narrow"
    toggle="unfoldable"
    cSidebarToggle="sidebar"
  />
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="mb-4 d-print-none header header-sticky" position="sticky" sidebarId="sidebar" />
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="lg" class="h-auto">
      <router-outlet />
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer />
</div>

<!-- Calculator Toggle Button -->
<!-- <button class="toggle-calculator-btn" (click)="toggleCalculator()">Calculator</button> -->
<!-- Calculator Component -->
<!-- <app-calculator *ngIf="isCalculatorVisible"></app-calculator> -->
