import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ClassToggleService, HeaderComponent } from "@coreui/angular";
import { AuthService } from "src/app/services/auth.service";
import { GetDataService } from "src/app/services/get-data.service";

@Component({
  selector: "app-default-header",
  templateUrl: "./default-header.component.html",
})
export class DefaultHeaderComponent extends HeaderComponent {
  @Input() sidebarId: string = "sidebar";

  public newMessages = new Array(4);
  public newTasks = new Array(5);
  public newNotifications = new Array(5);
  selectedbranch: any;
  constructor(
    private classToggler: ClassToggleService,
    public router: Router,
    public authService: AuthService,
    private getData: GetDataService
  ) {
    super();
  }
  onChangeOfBranch(val: any) {
    console.log(val.target.value);
    // this.getData.getBranches("25","1").subscribe((res:any)=>{
    //   console.log(res)
    // },err=>{
    //   console.log(err)
    // })
  }
  logout() {
    this.authService.logout();
    this.router.navigate(["login"]);
  }
}
