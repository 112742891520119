import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InsertDataService } from './../../../services/insert-data.service';
import { UpdateDataService } from './../../../services/update-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-scheems-form',
  templateUrl: './scheems-form.component.html',
  styleUrls: ['./scheems-form.component.scss']
})
export class ScheemsFormComponent implements OnInit{
  formInputs: FormGroup;
  navData : any;
  constructor(
    public dialogRef: MatDialogRef<ScheemsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
    public addData: InsertDataService,
    private toastr: ToastrService,
    public updatedata : UpdateDataService
  ) {
    this.formInputs = this.formBuilder.group({
      name: ['', [Validators.minLength(2)]],
      roi: ['', [Validators.minLength(1)]],
      tenure: ['', [Validators.minLength(1)]],
      nineone: ['', [Validators.minLength(2)]],
      eightyfive: ['', [Validators.minLength(2)]],
      eighty: ['', [Validators.minLength(2)]],
      seventyfive: ['', [Validators.minLength(2)]],
      silver: ['', [Validators.minLength(2)]],
    });
    this.navData = data.data;
  }
  ngOnInit(): void {
    if(this.navData && this.navData.id){
      this.formInputs.controls["name"].setValue(this.navData.attributes.name);
      this.formInputs.controls["roi"].setValue(this.navData.attributes.roi);
      this.formInputs.controls["tenure"].setValue(this.navData.attributes.tenure);
      this.formInputs.controls["nineone"].setValue(this.navData.attributes.nineone);
      this.formInputs.controls["eightyfive"].setValue(this.navData.attributes.eightyfive);
      this.formInputs.controls["eighty"].setValue(this.navData.attributes.eighty);
      this.formInputs.controls["seventyfive"].setValue(this.navData.attributes.seventyfive);
      this.formInputs.controls["silver"].setValue(this.navData.attributes.silver);
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit(): void {

    if(this.navData && this.navData.id){
      if (this.formInputs.valid) {
        this.formInputs.value.id = this.navData.id
        this.updatedata.updateSchemes(this.formInputs.value)
        .subscribe(res => {
          this.formInputs.reset();
          this.showToast("2");
          this.onNoClick();
        }, err => {
          this.showToast("0")
          console.log(err);
        })
      }
    }
    else{
    if (this.formInputs.valid) {
      this.addData.addScheme(this.formInputs.value).subscribe(res => {
        this.formInputs.reset();
        this.showToast("1");
        this.onNoClick();
      }, err => {
        this.showToast("0")
        console.log(err);
      })
    }
  }
}
  showToast(params: any) {
    if (params == '1') {
      this.toastr.success('Scheme Added Succesfully', 'Success!');
    }
    else if (params == '2') {
      this.toastr.success('Scheme Updated Succesfully', 'Success!');
    }
    else {
      this.toastr.warning('Something went wrong, try again later', 'Oops!');
    }
  }
}
