<ng-template #dialogBox>
  <div class="py-3 px-4 col-12">
    <h2 matDialogTitle>Are you Sure ?</h2>
    <p matDialogContent>Do you want to delete?</p>
    <hr/>
    <div class="float-right">
    <button cButton color="dark" matDialogClose (click)="cancelDelete()">Cancel</button>&nbsp;
    <button cButton color="danger" matDialogClose (click)="confirmDelete()">Confirm</button>    
  </div>
    </div>
</ng-template>
<ng-template #userImg>
  <div class="py-3 px-4 col-12" *ngIf="selectedUserImg !=''">
    <img class="" src="{{selectedUserImg}}" style="width: 100%; height: auto; max-width:400px;">
    <!-- <div class="">
    <button cButton color="dark" matDialogClose (click)="cancelDelete()">Close</button>&nbsp;
  </div> -->
    </div>
    <div class="py-3 px-4 col-12" *ngIf="selectedUserImg ==''">
      <img class="" src="../../../../assets/img/avatars/user-thumb.png" style="width: 100%; height: auto; max-width:400px;">
      <!-- <div class="">
      <button cButton color="dark" matDialogClose (click)="cancelDelete()">Close</button>&nbsp;
    </div> -->
      </div>
  </ng-template>

<c-card class="mb-4">
  <c-card-body>
  <c-row>
  <c-col md="10">
    <h3><b>Customer Details </b></h3>
  </c-col>
  <c-col class="mb-2">
      <button (click)="addCustomer()" cButton color="primary">Add Customer</button>
  </c-col>
  <c-col xs="12">
    <table bordered borderColor="dark" cTable>
      <thead>
        <tr>
          <th scope="col">S.No</th>
          <th></th>
          <th scope="col">Name</th>
          <th scope="col" width="25%">Address</th>
          <th scope="col">Contact</th>
          <!-- <th scope="col">ID Proof</th> -->
          <th scope="col">Nominee Details</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let customer of displayedData; let i = index">
          <th ope="row">{{ i + 1 }}</th>
          <td>
            <div class="avatar" (click)="enlargeImg(customer.attributes.photo)" *ngIf="customer.attributes.photo != ''">
              <img class="avatar-img" src="{{customer.attributes.photo}}" style="max-width: 100px; max-height: 100px;">
              </div>
              <div class="avatar" *ngIf="customer.attributes.photo == ''">
              <img class="avatar-img" src="../../../../assets/img/avatars/user-thumb.png" style="max-width: 100px; max-height: 100px;">
            </div>
          </td>
          <td width="15%">
            <a (click)="veiwDetails(customer.id)">{{customer.attributes.sur}} {{ customer.attributes.name }} {{customer.attributes.co}} {{customer.attributes.fname}} </a></td>
          <td>{{ customer.attributes.address }}</td>
          <td>{{ customer.attributes.contact1 }},<br>{{ customer.attributes.contact2 }}</td>
          <td>{{ customer.attributes.nominee }} / {{ customer.attributes.relation }}<br>{{ customer.attributes.nomineeContact }}</td>
          <td class="action-buttons">
            <!-- <button  cButton color="success" class="py-1" (click)="veiwDetails(customer.attributes.name)"><svg cIcon name="cilPen"></svg></button>&nbsp; -->
            <button  class="btn btn-warning py-1" (click)="editCustomer(customer)"><svg cIcon name="cilPencil"></svg></button>&nbsp;
            <button  class="btn btn-danger py-1" (click)="deleteCustomer(customer)"><svg cIcon name="cilTrash"></svg></button>
            </td>
        </tr>
      </tbody>
    </table>
  </c-col>
</c-row>
</c-card-body>
</c-card>