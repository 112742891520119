import { Injectable } from '@angular/core';

interface LoanData {
  amount: string;
  tenure: string;
  roi: string;
  date: string;
}

interface PaymentData {
  id: number;
  principal: string;
  interest: string;
  penalty: string;
  amount: number;
  date: string;
  user: string;
  uniqKey: string;
  mode: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class CalculationService {
  loanDetails:any;
  constructor() { }

  calculatePendingAmounts(loanData: LoanData, closingDateObj:any, paymentData: PaymentData[]) {
    const annualInterestRate = parseFloat(loanData.roi);
    const monthlyInterestRate = annualInterestRate / 1200;
    let currentDateObj = new Date(closingDateObj);
    currentDateObj.setHours(5, 30, 0, 0);
    let Calcdata = [];
    let pendingInterest = 0;
    let pendingPenalty = 0;
    let principalPaid = 0;
    let interestPaid = 0;
    let penaltyPaid = 0;
    let totalInterest = 0;
    let dueDate = "";
    const principalArray: { amount: string; date: string }[] = [];
    const interestArray: { amount: string; date: string }[] = [];
    const loanStartDate = new Date(loanData.date.split(" ")[0]);
    for (let i = 1; i <= parseInt(loanData.tenure); i++) {
      const currentDueDate = new Date(loanStartDate);
      currentDueDate.setMonth(loanStartDate.getMonth() + i);
      const previousDueDate = new Date(loanStartDate);
      previousDueDate.setMonth(loanStartDate.getMonth() + i - 1);
      const daysElapsed = Math.ceil(
        (currentDueDate.getTime() - previousDueDate.getTime()) /
          (1000 * 60 * 60 * 24)
      );
      const sndCycleDate = new Date(previousDueDate);
      sndCycleDate.setDate(sndCycleDate.getDate() + 14);
      if (
        loanStartDate.getDate() == 31 &&
        i != 1 &&
        currentDueDate.getDate() == 31
      ) {
        sndCycleDate.setDate(15);
      } else if (
        loanStartDate.getDate() == 31 &&
        i != 1 &&
        currentDueDate.getDate() != 31
      ) {
        sndCycleDate.setDate(14);
      }
      if (sndCycleDate > currentDueDate) {
        sndCycleDate.setTime(currentDueDate.getTime());
      }
      Calcdata.push({
        emi: i,
        startDate: previousDueDate.toISOString().split("T")[0],
        date: currentDueDate.toISOString().split("T")[0],
        days: daysElapsed,
        midCycleDate: sndCycleDate.toISOString().split("T")[0],
        fstCycleInt: 0,
        sndCycleInt: 0,
        fstprincipal: 0,
        sndprincipal: 0,
        interest: 0,
        interestPaid: 0,
        isInterestPaid: 0,
        penalty: "",
      });
    }
    paymentData.forEach(({ principal, interest, penalty, date }) => {
      interestPaid += parseFloat(interest) || 0;
      penaltyPaid += parseFloat(penalty) || 0;
      principalPaid += parseFloat(principal) || 0;
      if (principal != "0") {
        principalArray.push({ amount: principal, date: date.split(" ")[0] });
      }
      if (interest != "0") {
        interestArray.push({ amount: interest, date: date.split(" ")[0] });
      }
    });
    let totalPrincpalAmount = parseFloat(loanData.amount);
    let paidSofar = 0;
    const halfMonthlyInterestRate = monthlyInterestRate / 2;
    Calcdata.forEach((item) => {
      let paidfstAmount = 0;
      let paidsndAmount = 0;
      let tempPrincipal = totalPrincpalAmount - paidSofar;
      const midDate = new Date(item.midCycleDate);
      const endDate = new Date(item.date);
      const startDate = new Date(item.startDate);
      let paymentsInCycle = principalArray.filter((elem: any) => {
        const paidDate = new Date(elem.date);
        return paidDate.getTime() >= startDate.getTime() && paidDate.getTime() < endDate.getTime();
      });
      paymentsInCycle.forEach((elem: any) => {
        const paidDate = new Date(elem.date);
        const amount = parseFloat(elem.amount);
        if (paidDate < midDate) {
          paidfstAmount += amount;
        } else {
          paidsndAmount += amount;
        }
      });
      item.fstCycleInt = tempPrincipal * halfMonthlyInterestRate;
      item.fstprincipal = paidfstAmount;
      paidSofar += item.fstprincipal;
      tempPrincipal = totalPrincpalAmount - paidSofar;
      item.sndCycleInt = tempPrincipal * halfMonthlyInterestRate;
      item.sndprincipal = paidsndAmount;
      paidSofar += item.sndprincipal;
      item.interest = item.fstCycleInt + item.sndCycleInt;
    });
    let tempIntPaid = interestPaid;
    Calcdata.forEach((ele: any) => {
      interestArray.forEach((elem: any) => {
        if (ele.interest <= tempIntPaid) {
          ele.interestPaid = ele.interest;
          tempIntPaid = tempIntPaid - ele.interest;
        } else {
          ele.interestPaid = tempIntPaid;
          tempIntPaid = 0;
        }
      });
    });
    let penalty = this.calculatePenalty(
      Calcdata,
      interestArray,
      currentDateObj
    );
    totalInterest = 0;
    Calcdata.forEach((ele: any) => {
      const fstDate = new Date(ele.startDate);
      const sndDate = new Date(ele.midCycleDate);
      const strtDate = new Date(ele.startDate);
      let tempPrevMo = new Date(strtDate);
      tempPrevMo.setMonth(tempPrevMo.getMonth() - 1);
      if (strtDate.getDate() === 1) {
        if (!this.has31Days(tempPrevMo)) {
          if (strtDate.getTime() <= currentDateObj.getTime()) {
            totalInterest += ele.fstCycleInt;
          }
        }
      } else {
        if (ele.emi == 1) {
          if (fstDate.getTime() <= currentDateObj.getTime()) {
            totalInterest += ele.fstCycleInt;
          }
        } else {
          if (fstDate.getTime() < currentDateObj.getTime()) {
            totalInterest += ele.fstCycleInt;
          }
        }
      }
      if (sndDate.getTime() < currentDateObj.getTime()) {
        totalInterest += ele.sndCycleInt;
      }
    });
    for (let i = 0; i < Calcdata.length; i++) {
      const ele = Calcdata[i];
      const itemDate = new Date(ele.date);
      if (itemDate.getTime() > currentDateObj.getTime()) {
        dueDate = ele.date;
        break;
      }
    }
    pendingInterest = totalInterest - interestPaid;
    pendingInterest = pendingInterest < 0 ? 0 : pendingInterest;
    pendingPenalty = penalty - penaltyPaid;
    pendingPenalty = pendingPenalty < 0 ? 0 : pendingPenalty;
    let remainingPrincipal = parseFloat(loanData.amount) - principalPaid;
    let odInt = 0;
    let lastDuedate = new Date(Calcdata[Calcdata.length - 1].date);
    if (lastDuedate.getTime() < currentDateObj.getTime()) {
      odInt = this.intAfterTenure(remainingPrincipal.toFixed(2), lastDuedate, currentDateObj);
    }
    let amountToPay = remainingPrincipal + pendingInterest + odInt + pendingPenalty;
    Calcdata.forEach((ele:any)=>{
      if(ele.interest >= interestPaid){
        ele.interestPaid = interestPaid;
        interestPaid = 0;
      }else{
        ele.interestPaid = ele.interest;
        interestPaid = interestPaid -  parseFloat(ele.interest)
      }
      ele.interestPaid = parseFloat(ele.interestPaid).toFixed(2);
      ele.interest = parseFloat(ele.interest).toFixed(2);
    })
    const result = {
      tenureTable : Calcdata,
      interestDue: Math.round(pendingInterest),
      penaltyDue:  Math.round(pendingPenalty),
      nextDueDate: dueDate,
      lateTenureInt: Math.round(odInt),
      totalAmountPaid: paymentData
        .reduce((sum, payment: any) => sum + parseFloat(payment.amount), 0)
        .toFixed(2),
      remainingPrincipal: remainingPrincipal.toFixed(2),
      tenure: loanData.tenure,
      totalAmountToPay : Math.round(amountToPay).toFixed(2)
    };
    return result;
  }
  has31Days(date: any) {
    const month = date.getMonth();
    const monthsWith31Days = [0, 2, 4, 6, 7, 9, 11];
    return monthsWith31Days.includes(month);
  }
  dateDifference(date1: any, date2: any) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const diffTime = Math.abs(d2.getTime() - d1.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
  intAfterTenure(principalAmt:any, fromDate: any, toDate: any) {
    let formatFromDate = new Date(fromDate);
    let formatToDate = new Date(toDate);
    let yearDifference =
      formatToDate.getFullYear() - formatFromDate.getFullYear();
    let monthDifference = formatToDate.getMonth() - formatFromDate.getMonth();
    let totalMonthsDifference = yearDifference * 12 + monthDifference;
    if (formatToDate.getDate() < formatFromDate.getDate()) {
      totalMonthsDifference -= 1;
    }
    let noTenureData = [];
    for (let i = 0; i <= totalMonthsDifference; i++) {
      const currentDueDate = new Date(formatFromDate);
      currentDueDate.setMonth(formatFromDate.getMonth() + i);
      const previousDueDate = new Date(formatFromDate);
      previousDueDate.setMonth(formatFromDate.getMonth() + i);
      let sndCycleDate = new Date(previousDueDate);
      sndCycleDate.setDate(sndCycleDate.getDate() + 14);
      if (
        formatFromDate.getDate() == 31 &&
        i != 1 &&
        currentDueDate.getDate() == 31
      ) {
        sndCycleDate.setDate(15);
      } else if (
        formatFromDate.getDate() == 31 &&
        i != 1 &&
        currentDueDate.getDate() != 31
      ) {
        sndCycleDate.setDate(14);
      }
      noTenureData.push({
        startDate: previousDueDate.toISOString().split("T")[0],
        midCycleDate: sndCycleDate.toISOString().split("T")[0],
        date: currentDueDate.toISOString().split("T")[0],
        fstCycleInt: 0,
        sndCycleInt: 0,
        interest: 0,
      });
    }
    const firstCycleInterest = principalAmt * 0.015;
    noTenureData.forEach((ele: any) => {
      let frmDate = new Date(ele.date);
      let tooDate = new Date(ele.midCycleDate);
      if (tooDate.getTime() < formatToDate.getTime()) {
        ele.fstCycleInt = firstCycleInterest;
      }
      if (frmDate.getTime() < formatToDate.getTime()) {
        ele.sndCycleInt = firstCycleInterest;
      }
      ele.interest = ele.fstCycleInt + ele.sndCycleInt;
    });
    let newInterest = 0;
    noTenureData.forEach((ele: any) => {
      newInterest += parseFloat(ele.interest);
    });
      return newInterest;
  }
  daysInMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }
  calculatePenalty(
    emiData: any,
    InterestPaid: any,
    closingDate: any,
    penaltyRate = 0.001
  ) {
    let totalPenalty = 0;
    emiData.forEach((emi: any) => {
      const dueDate = new Date(emi.date);
      let interestPaid = emi.interestPaid;
      let pendingInterest = emi.interest - interestPaid;
      let penaltyPaid = emi.penaltyPaid || 0;
      InterestPaid.forEach((payment: any) => {
        const paymentDate = new Date(payment.date);
        const paymentAmount = parseFloat(payment.amount);
        if (paymentDate > dueDate) {
          const overdueDays = this.dateDifference(dueDate, paymentDate);
          if (pendingInterest > 0) {
            const partialPaymentPenalty = Math.min(
              paymentAmount,
              pendingInterest
            );
            const penalty = partialPaymentPenalty * penaltyRate * overdueDays;
            totalPenalty += penalty;
            pendingInterest -= partialPaymentPenalty;
          }
        }
      });
      if (pendingInterest > 0 && penaltyPaid === 0) {
        const currentDate = new Date(closingDate);
        let dueDateWithGrace = new Date(dueDate);
        dueDateWithGrace.setDate(dueDateWithGrace.getDate() + 3);
        if (currentDate >= dueDateWithGrace) {
          const overdueDays = this.dateDifference(dueDate, currentDate);
          const penalty = pendingInterest * penaltyRate * overdueDays;
          totalPenalty += penalty;
        }
      }
      if (penaltyPaid > 0) {
        penaltyPaid = 0;
      }
    });
    return totalPenalty;
  }
}
