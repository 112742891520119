import { Component, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GetDataService } from './../../../services/get-data.service';
import { DeleteDataService } from './../../../services/delete-data.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

interface ProductAttributes {
  product: string;
  weight: string;
  less: string;
  fweight: string;
  description: string;
  loanid: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  touch: string;
  locker: string | null;
  lockno: string | null;
  image: string;
}

interface LoanAttributes {
  sur: string;
  customername: string;
  amount: string;
  tenure: string;
  roi: string;
  remark: string | null;
  scheme: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  custId: string;
  tamount: string;
  fee: string;
  roundoff: string;
  user: string;
  date: string;
  isClosed: string | null;
}

interface Loan {
  id: number;
  attributes: LoanAttributes;
  products: {
      id: number;
      attributes: ProductAttributes;
  }[];
}

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent {
  dtls: any;
  displayedData: any;
  selectedImg: any;
  editData: any;
  interest: any;
  gtotal: any;
  tempPrddata: any;
  Arr = Array;
  total: any;
  months: any;
  @ViewChild('userImg') userImg!: TemplateRef<any>;
  deleteId: any = '';
  dataPage: number = 1;
  pageLen: number = 10;
  id: any
  custDtls: any;
  customerDetails: any;
  touch: any;
  constructor(private route: ActivatedRoute, private toastr: ToastrService, public dialog: MatDialog, public getData: GetDataService, public deleteData: DeleteDataService,
    public router: Router) {
    this.editData = {};
    this.touch = {
      "nineone": 916,
      "eightyfive": 85,
      "eighty": 80,
      "seventyfive": 75,
      "silver": "silver"
    }
    this.id = this.route.snapshot.paramMap.get('id')
    this.loadData(this.id);
    this.getCustomerDtls(this.id);
  }
  ngOnInit() {
  }
  pageSize(event: any) {
    this.pageLen = event.target.value;
  }
  getCustomerDtls(id: any) {
    this.getData.getCustomersId(id).subscribe((res) => {
      this.custDtls = res;
      this.customerDetails = this.custDtls.data[0].attributes;
    })
  }
  loadData(id: any) {
    this.getData.getLoans(id).subscribe((res) => {
      this.dtls = res;
      this.displayedData = this.sortLoans(this.dtls.data);
      this.getLoanProducts();
    }, (err) => {
      console.log(err);
    });
  }
  sortLoans(loans: Loan[]): Loan[] {
    return loans.sort((a, b) => {
        const isClosedA = a.attributes.isClosed === "1" ? 1 : 0;
        const isClosedB = b.attributes.isClosed === "1" ? 1 : 0;
        return isClosedA - isClosedB;
    });
}
  getLoanProducts() {
    this.displayedData.forEach((element: any) => {
      this.getData.getLoanProducts(element.id).subscribe(res => {
        this.tempPrddata = res;
        element.products = this.tempPrddata.data;
      }, err => {
        console.log(err);
      })
    });
  }
  cancelDelete() {
    this.deleteId = '';
    this.dialog.closeAll();
  }
  confirmDelete() {
    this.deleteData.deleteCustomer(this.deleteId).subscribe(res => {
      this.deleteId = '';
      this.cancelDelete()
      this.showToast();
    }, err => {
      console.log(err)
    })
  }
  showToast() {
    this.toastr.success('Customer Deleted Succesfully', 'Success!');
  }
  prdtouch(val: string) {
    return this.touch[val];
  }
  enlargeImg(base64: any) {
    this.selectedImg = base64;
    this.dialog.open(this.userImg);
  }
  loanDetails(idx: any) {
    let id = this.displayedData[idx].id;
    this.router.navigate(['customers/payments/' + id],
      {
        state: {
          id : id,
          data : this.displayedData[idx].attributes,
          products : this.displayedData[idx].products,
        }
      }
    );
  }
}
