<div class="container my-6 card-bg">
  <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
    <div class="row g-3">
      <div class="col-12">
        <h3 class="mb-0">Add Customer</h3>
      </div>
      <div class="col-10">
        <div class="row">
          <div class="col-2">
            <label class="form-label">Title</label>
            <select class="form-select" name="sur" formControlName="sur">
              <option value="">Title</option>
              <option value="Mr">Mr</option>
              <option value="Ms/Miss">Ms/Miss</option>
            </select>
            <div *ngIf="formInputs.get('sur')?.invalid && formInputs.get('sur')?.touched">
              <small class="text-danger" *ngIf="formInputs.get('sur')?.errors?.['required']">
                Please Select Title.
              </small>
            </div>
          </div>
          <div class="col-4">
            <label class="form-label">Customer Name</label>
            <input
              type="text"
              class="form-control"
              name="name"
              formControlName="name"
              placeholder="Customer Name"
            />
            <div *ngIf="formInputs.get('name')?.invalid && formInputs.get('name')?.touched">
              <small class="text-danger" *ngIf="formInputs.get('name')?.errors?.['required']">
                Please Enter Customer Name.
              </small>
            </div>
          </div>
          <div class="col-2 label-space">
            <label class="form-label">Care of</label>
            <select class="form-select" name="co" formControlName="co">
              <option value="">Care of</option>
              <option value="S/o">S/o</option>
              <option value="D/o">D/o</option>
              <option value="W/o">W/o</option>
              <option value="C/o">C/o</option>
            </select>
            <div *ngIf="formInputs.get('co')?.invalid && formInputs.get('co')?.touched">
              <small class="text-danger" *ngIf="formInputs.get('co')?.errors?.['required']">
                Please Select Title.
              </small>
            </div>
          </div>
          <div class="col-4 label-space">
            <label class="form-label">Father/Husband Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Father/Husband Name"
              formControlName="fname"
            />
            <div *ngIf="formInputs.get('fname')?.invalid && formInputs.get('fname')?.touched">
              <small class="text-danger" *ngIf="formInputs.get('fname')?.errors?.['required']">
                Please Enter Father/Husband Name.
              </small>
            </div>
          </div>
          <div class="col-6 label-space">
            <label class="form-label">ID Type</label>
            <select
              class="form-select"
              formControlName="proofType"
              (change)="idProofType($event)"
            >
              <option value="">-- Select ID Proof Type --</option>
              <option value="1">Aadhar Card</option>
              <option value="2">PAN Card</option>
              <option value="3">Driving Licence</option>
              <option value="4">Ration Card</option>
            </select>
            
            <div *ngIf="formInputs.get('proofType')?.invalid && formInputs.get('proofType')?.touched">
              <small class="text-danger" *ngIf="formInputs.get('proofType')?.errors?.['required']">
                Please Select ID Proof Type.
              </small>
            </div>
          </div>
          <div class="col-6 label-space">
            <label class="form-label">{{ idLable }} No</label>
            <input
              type="text"
              class="form-control"
              placeholder="{{ idLable }} No"
              formControlName="proofno"
              (keyup)="toUpperCase()"
            />
            <div *ngIf="formInputs.get('proofno')?.invalid && formInputs.get('proofno')?.touched">
              <small class="text-danger" *ngIf="formInputs.get('proofno')?.errors?.['required']">
                Please Enter {{ idLable }} No.
              </small>
            </div>
          </div>
          <div class="col-6 label-space">
            <label class="form-label">Contact Number</label>
            <input
              type="text"
              class="form-control"
              placeholder="Contact Number"
              formControlName="contact1"
            />
            <div *ngIf="formInputs.get('contact1')?.invalid && formInputs.get('contact1')?.touched">
              <small class="text-danger" *ngIf="formInputs.get('contact1')?.errors?.['required']">
                Please Enter Contact number.
              </small>
            </div>
          </div>
          <div class="col-6 label-space">
            <label class="form-label">Alternate Contact</label>
            <input
              type="text"
              class="form-control"
              placeholder="Alternate Contact"
              formControlName="contact2"
            />
            <div *ngIf="formInputs.get('contact2')?.invalid && formInputs.get('contact2')?.touched">
              <small class="text-danger" *ngIf="formInputs.get('contact2')?.errors?.['required']">
                Please Enter Alternate Contact number.
              </small>
            </div>
          </div>
          <div class="col-4 label-space">
            <label class="form-label">Nominee</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nominee"
              formControlName="nominee"
            />
            <div *ngIf="formInputs.get('nominee')?.invalid && formInputs.get('nominee')?.touched">
              <small class="text-danger" *ngIf="formInputs.get('nominee')?.errors?.['required']">
                Please Enter Nominee.
              </small>
            </div>
          </div>
          <div class="col-4 label-space">
            <label class="form-label">Relationship</label>
            <select class="form-select" formControlName="relation">
              <option *ngFor="let rel of relation">{{ rel.name }}</option>
            </select>
            <div *ngIf="formInputs.get('relation')?.invalid && formInputs.get('relation')?.touched">
              <small class="text-danger" *ngIf="formInputs.get('relation')?.errors?.['required']">
                Please Select Relationship.
              </small>
            </div>
          </div>
          <div class="col-4 label-space">
            <label class="form-label">Nominee Contact</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nominee Contact"
              formControlName="nomineeContact"
            />
            <div *ngIf="formInputs.get('nomineeContact')?.invalid && formInputs.get('nomineeContact')?.touched">
              <small class="text-danger" *ngIf="formInputs.get('nomineeContact')?.errors?.['required']">
                Please Enter Nominee Contact.
              </small>
            </div>
          </div>
          <div class="col-12 label-space">
            <label class="form-label">Address</label>
            <textarea
              type="text"
              class="form-control"
              placeholder="Address"
              formControlName="address"
            ></textarea>
            <div *ngIf="formInputs.get('address')?.invalid && formInputs.get('address')?.touched">
              <small class="text-danger" *ngIf="formInputs.get('address')?.errors?.['required']">
                Please Enter Address.
              </small>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-12">
            <label class="form-label">Customer Photo</label>
            <div class="img-preview">
              <img
                *ngIf="customerPhotoBase64"
                src="{{ customerPhotoBase64 }}"
                style="max-width: 100%"
              />
            </div>
          </div>
          <div class="col-12 py-1">
            <button
              class="btn btn-dark"
              type="button"
              (click)="openWebCam('U')"
            >
              Open Camera
            </button>
            <!-- <label class="form-label">Customer Photo</label> -->
            <!-- <input type="file" class="form-control" id="customerPhoto" (change)="handleCustPhoto($event)"> -->
          </div>
          <div class="col-12">
            <label class="form-label">Id Photo</label>
            <div class="img-preview">
              <img
                *ngIf="govtIdPhotoBase64"
                src="{{ govtIdPhotoBase64 }}"
                style="max-width: 100%"
              />
            </div>
          </div>
          <div class="col-12 py-1">
            <!-- <label class="form-label">ID Photo</label> -->
            <!-- <input type="file" class="form-control" id="govtIdPhoto" (change)="handleGovtIdPhoto($event)"
              aria-label="Government ID Photo"> -->
            <button
              class="btn btn-dark"
              type="button"
              (click)="openWebCam('P')"
            >
              Open Camera
            </button>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <button type="button" class="btn btn-dark" (click)="onNoClick()">
          Close
        </button>
        &nbsp;
        <button type="submit" class="btn btn-primary">Save</button>
      </div>
    </div>
  </form>
</div>
