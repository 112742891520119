<ng-template #dialogBox>
  <div class="py-3 px-4 col-12">
    <img class="" src="{{selectedUserImg}}" alt="Customer Photo" style="width: 100%; height: auto; max-width:400px;">
    <!-- <div class="">
    <button cButton color="dark" matDialogClose (click)="cancelDelete()">Close</button>&nbsp;
  </div> -->
  </div>
</ng-template>
<c-card class="mb-4">
  <c-card-body>
    <c-row>
      <c-col md="4">
        <div class="row">
          <div class="col-1 pt-1"><a (click)="backClicked()"><svg cIcon name="cilArrowLeft"></svg></a></div>
          <div class="col-11">
            <h3><b>Loan Details </b> (Loan ID : {{loanid}})</h3>
          </div>
        </div>
      </c-col>
      <c-col class="mb-2 col-6">
        <button (click)="calculateClosing()" cButton color="danger" class="float-end">Check Closing</button>
      </c-col>
      <c-col class="mb-2 col-2">
        <button (click)="addPayment()" cButton color="primary" class="float-end">Make Payment</button>
      </c-col>
      <c-col xs="12">
        <div class="row">

          <div class="col-md-3">
            <b>Loan Amount</b><br>Rs.{{loanData.amount}}
          </div>
          <div class="col-md-3">
            <b>Tenure</b><br>{{loanData.tenure}} Months
          </div>
          <div class="col-md-3">
            <b>Total Amount Paid</b><br>Rs. {{loanDetails.totalAmountPaid}}
          </div>
          <div class="col-md-3">
            <b>Pending Interest </b>
            <br>Rs.{{loanDetails.interestDue}}
          </div>
          <div class="col-md-3 pt-3">
            <b>Pending Principal Amount</b>
            <br> Rs. {{loanDetails.remainingPrincipal}}
          </div>
          <div class="col-md-3 pt-3">
            <b>Penalty</b><br> Rs.{{loanDetails.penaltyDue}}<b></b>
          </div>
          <div class="col-md-3 pt-3">
            <b>Loan Date</b><br>{{loanData.date}}<b></b>
          </div>
          <div class="col-md-3 pt-3">
            <b>Due Date</b><br>{{loanDetails.nextDueDate}}<b></b>
          </div>
          <div class="col-md-3 pt-3">
            <b>Current Closing Amount</b><br>{{totalAmountToPay}}
          </div>
        </div>
      </c-col>
    </c-row>
    <div class="col-12 pt-3 pb-2">
      <b>Product Details</b>
    </div>
    <div class="col-12">
      <table>
        <thead>
          <tr>
            <th>S.no</th>
            <th>Product</th>
            <th>Weight(grms)</th>
            <th>Touch</th>
            <th>Location</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let prds of products; let j=index;">
            <td>{{j+1}}</td>
            <td><a (click)="enlargeImg(prds.attributes.image)">{{prds.attributes.product}}</a></td>
            <td>{{prds.attributes.weight}}</td>
            <td>{{prdtouch(prds.attributes.touch)}}</td>
            <td>
              <span *ngIf="prds.attributes.locker != null && prds.attributes.locker == 1">Locker :
                {{prds.attributes.lockno}}</span>
              <span *ngIf="prds.attributes.locker != null && prds.attributes.locker == 0">Office</span>
            </td>
            <td><a (click)="updateLocker(prds.id,j)"><svg cIcon name="cilPencil"></svg></a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </c-card-body>
</c-card><c-card class="mb-4">
  <c-card-body>
    <c-row>
      <c-col md="12">
        <h3><b>Payment History</b></h3>
      </c-col>
      <c-col xs="12">
        <table bordered borderColor="dark" cTable>
          <thead>
            <tr>
              <th scope="col" style="width: 5%;">S.No</th>
              <th scope="col" style="width: 20%;">Date </th>
              <th scope="col">Paid Amount</th>
              <th scope="col">Penalty</th>
              <th scope="col">Interest</th>
              <th scope="col">Principal</th>
              <th scope="col">Payment Mode</th>
              <th scope="col">User ID</th>
              <th>Receipt</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let Payment of paintPayment; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ Payment.date }}</td>
              <td>Rs.{{ Payment.amount }}</td>
              <td>Rs.{{ Payment.penalty }}</td>
              <td>Rs.{{ Payment.interest }}</td>
              <td>Rs.{{ Payment.principal }}</td>
              <td>{{ Payment.mode }}</td>
              <td>{{ Payment.user }}</td>
              <td><a (click)="downloadPdf(Payment)"><svg cIcon name="cilPaperclip"></svg></a>
            </tr>
          </tbody>
        </table>
      </c-col>
    </c-row>
  </c-card-body>
</c-card>
  <div id="print-ele" class="d-none" style="padding:10px; margin:10px">
    <table class="table-borderless" style="width:100%; padding: 10px;" cellspacing="0">
      <tr>
        <td rowspan="5" style="text-align: center; width: 25%;">
          <img src="assets/images/cashway.png" style="height: auto; width: 100px;">
        </td>
        <td colspan="3" style="font-weight: bold; text-align:center; font-size: 24px; padding-top: 10px;">
          SRI CASHWAY FINANCE Pvt Ltd
        </td>
        <td style="width: 20%;"></td>
      </tr>
      <tr>
        <td colspan="3" style="text-align:center; font-weight: 500; padding-top: 5px;">
          L. No. 29/2022-2023
        </td>
        <td style="width: 20%;"></td>
      </tr>
      <tr>
        <td colspan="3" style="text-align:center; font-weight: 500; padding-top: 5px;">
          No-133, பாரதியார் ரோடு, மணியகாரம்பாளையம், கணபதி, கோயம்புத்தூர்-641 106.
        </td>
        <td style="width: 20%;"></td>
      </tr>
      <tr>
        <td colspan="3" style="font-weight: bold; text-align:center; padding-top: 5px;">
          பணம் தேவையா? நகையை விற்க தேவையில்லை!
        </td>
        <td style="width: 20%;"></td>
      </tr>
      <tr>
        <td colspan="3" style="text-align:center; padding-top: 5px;  padding-bottom: 10px;">
          செல்: 96299 33336, 98434 86662
        </td>
        <td style="width: 20%;"></td>
      </tr>
      <tr>
        <td colspan="4"
          style="font-weight: 700; border-top:1px solid #000; border-bottom:1px solid #000; padding:10px 10px;">
          இரசீது எண்: {{printDtls.billno}}
        </td>
        <td
          style="width: 30%; font-weight: 700; border-top:1px solid #000;  border-bottom:1px solid #000; padding:10px 10px;">
          தேதி: {{printDtls.date}}
        </td>
      </tr>
      <tr>
        <td colspan="2" style="width: 20%; font-weight: 700; text-align: right; padding:10px;">
          பெயர்:
        </td>
        <td colspan="3">
          <span style="width: 80%; text-align: center; border-bottom:1px solid #414141; display: block; height: 20px;">
            {{loanData.name}}
          </span>
        </td>
      </tr>
      <tr>
        <td colspan="2" style="width: 20%; font-weight: 700; text-align: right; padding:10px">
          நகை கடன் எண்:
        </td>
        <td colspan="3">
          <span
            style="width: 80%; text-align: center; border-bottom:1px solid #414141; display: block; height: 20px;">{{loanid}}</span>
        </td>
      </tr>
      <tr>
        <td colspan="2" style="width: 20%; font-weight: 700; text-align: right; padding:10px">
          ரூபாய்:
        </td>
        <td colspan="3">
          <span
            style="width: 80%; text-align: center; border-bottom:1px solid #414141; display: block; height: 20px;">{{printDtls.amountWord}}</span>
        </td>
      </tr>
      <tr>
        <td colspan="2" style="width: 20%;">
        </td>
        <td style="width: 45%;">
          <span
            style="width: 100%; text-align: center; border-bottom:1px solid #414141; display: block; height: 20px;">
          </span>
        </td>
        <td style="font-weight: 700; text-align: left; padding:10px" colspan="2">
          மட்டும் பெற்றுக்கொண்டோம்.
        </td>
      </tr>
      <tr>
        <td style="width: 15%; padding:10px 5px; text-align: center;">
          <span
            style="display: block; border: 1px solid #414141; padding: 5px 10px; border-radius: 10px; text-align: left;">ரூ. {{printDtls.amount}} /-
          </span>
        </td>
        <td colspan="2" style="text-align: center;">Mode Of Payment: {{printDtls.mode}}</td>
        <td colspan="2" style="text-align: center; padding-top:15px">For SRI CASHWAY FINANCE Pvt Ltd</td>
      </tr>
    </table>
</div>