<div class="container my-6 card-bg p-4">
    <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
        <div class="row g-3">
            <div class="col-12">
                <h3 class="mb-0">Check Closing</h3>
            </div>
            <div class="col-12">
                <mat-form-field appearance="fill">
                    <mat-label>Select Closing Date</mat-label>
                    <input matInput [matDatepicker]="picker" [matDatepickerFilter]="dateFilter" formControlName="date" readonly>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="row g-2" *ngIf="principalAmount">
            <div class="col-12">
                Principal : Rs.{{principalAmount}}
            </div>
            <div class="col-12">
                Total Interest : Rs.{{totalInterestAccrued}}
            </div>
            <div class="col-12">
                Penalty : Rs.{{penalty}}
            </div>
            <div class="col-12">
                Total Amount to be Paid : Rs.{{totalAmountToPay}}
            </div>
        </div>
        <div class="mt-3">
            <button type="button" class="btn btn-dark" (click)="onNoClick()">Close</button> &nbsp;
            <input type="submit" class="btn btn-primary" value="Check">
        </div>
        </div>
    </form>
</div>