import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BranchFormComponent } from "../../forms/branch-form/branch-form.component";
import { GetDataService } from "src/app/services/get-data.service";
import { DeleteDataService } from "src/app/services/delete-data.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-branch",
  templateUrl: "./branch.component.html",
  styleUrls: ["./branch.component.scss"],
})
export class BranchComponent implements OnInit {
  displayedData: any;
  dtls: any;
  editData: any;
  deleteId: any;
  metaData: any = {};
  pageLen: number = 10;
  dataPage: number = 1;
  @ViewChild("dialogBox") dialogBox!: TemplateRef<any>;
  constructor(
    public dialog: MatDialog,
    public getData: GetDataService,
    private toastr: ToastrService,
    public deleteData: DeleteDataService
  ) {}
  ngOnInit() {
    this.loadData();
  }
  paginationChange(page: any) {
    if (page == "prev") {
      this.dataPage = this.dataPage - 1;
    } else if (page == "next") {
      this.dataPage = this.dataPage + 1;
    } else {
      this.dataPage = page.target.value;
    }
    this.loadData();
  }
  pageSize(event: any) {
    this.pageLen = event.target.value;
    this.loadData();
  }
  loadData() {
    this.getData.getBranches(this.pageLen, this.dataPage).subscribe(
      (res) => {
        this.dtls = res;
        this.metaData = this.dtls.meta.pagination;
        this.displayedData = this.dtls.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  addProduct() {
    const dialogRef = this.dialog.open(BranchFormComponent, {
      width: "30%",
      data: {
        data: this.editData,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.editData = {};
      this.loadData();
    });
  }
  editProduct(product: any) {
    this.editData = product;
    this.addProduct();
  }
  cancelDelete() {
    this.deleteId = "";
    this.dialog.closeAll();
  }
  confirmDelete() {
    this.deleteData.deleteBranches(this.deleteId).subscribe(
      (res) => {
        this.deleteId = "";
        this.cancelDelete();
        this.loadData();
        this.showToast();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  showToast() {
    this.toastr.success("Branch Deleted Succesfully", "Success!");
  }

  deleteProduct(product: any) {
    this.deleteId = product.id;
    this.dialog.open(this.dialogBox);
  }
}
