import { INavData } from "@coreui/angular";
interface UserPrivileges {
  role: number;
  makepayment: boolean;
  updatelocker: boolean;
  editcustomer: boolean;
  addcustomer: boolean;
  deletecustomer: boolean;
  editproduct: boolean;
  addproduct: boolean;
  deleteproduct: boolean;
  editscheme: boolean;
  addscheme: boolean;
  deletescheme: boolean;
  viewuser: boolean;
  adduser: boolean;
  edituser: boolean;
  deleteuser: boolean;
  createloan: boolean;
  renewloan: boolean;
}

export function getNavItems(userPrivileges: UserPrivileges): INavData[] {
  let navItems: INavData[] = [
    {
      name: "Dashboard",
      url: "/dashboard",
      iconComponent: { name: "cil-speedometer" },
    },
    {
      name: "Products",
      url: "/products",
      iconComponent: { name: "cil-star" },
    },
    {
      name: "Customers",
      url: "/customers",
      iconComponent: { name: "cil-people" },
    },
    {
      name: "Create Loan",
      url: "/loans",
      iconComponent: { name: "cil-tags" },
    },
    {
      name: "Schemes",
      url: "/schemes",
      iconComponent: { name: "cil-notes" },
    },
    {
      name: "Users",
      url: "/Users",
      iconComponent: { name: "cil-list" },
    },
    {
      name: "Branch",
      url: "/Branch",
      iconComponent: { name: "cil-credit-card" },
    },
    {
      name: "User Privileges",
      url: "/preveliges",
      iconComponent: { name: "cil-code" },
    },
  ];
  if (userPrivileges.role != 1) {
    navItems = navItems.filter(
      (item) => item.name !== "User Privileges" && item.name !== "Branch"
    );
  }
  if (!userPrivileges.viewuser) {
    navItems = navItems.filter((item) => item.name !== "Users");
  }
  if (!userPrivileges.createloan) {
    navItems = navItems.filter((item) => item.name !== "Create Loan");
  }
  return navItems;
}
