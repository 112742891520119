import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

interface ProfileData {
  id: number;
  attributes: {
    loggedInTime: string;
    role: number;
  };
}

interface GetLoggedInTimeResponse {
  data: ProfileData[];
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  url: string = environment.apiUrl;
  constructor(public http: HttpClient) {}

  login(user: any) {
    return this.http.post(this.url + "/auth/local", user);
  }

  getLoggedInTime(id: any): Observable<GetLoggedInTimeResponse> {
    return this.http.get<GetLoggedInTimeResponse>(
      `${this.url}/profiles?filters[uid][$eq]=${id}`
    );
  }

  getUserPrivileges(roleId: number): Observable<any> {
    return this.http.get(
      `${this.url}/user-permissions?filters[role][$eq]=${roleId}`
    );
  }

  async setLocalData(data: any): Promise<void> {
    try {
      localStorage.setItem("userData", JSON.stringify(data));
      const loggedInTime = new Date();
      localStorage.setItem("isSessionActive", JSON.stringify({ loggedInTime }));
      const userId = data.id;
      const profileData = await this.getLoggedInTime(userId).toPromise();
      const postDate = { loggedInTime: loggedInTime };
      localStorage.setItem(
        "profileData",
        JSON.stringify(profileData?.data?.[0])
      );
      const profileId = profileData?.data?.[0]?.id;
      const roleId = profileData?.data?.[0]?.attributes?.role;
      if (roleId) {
        const privileges = await this.getUserPrivileges(roleId).toPromise();
        localStorage.setItem(
          "userPrivileges",
          JSON.stringify(privileges.data[0].attributes)
        );
      } else {
        console.warn(
          "Role ID not found in profile, unable to fetch privileges."
        );
      }
      if (profileId) {
        const updateRes = await this.setLoggedInTime(
          postDate,
          profileId
        ).toPromise();
      } else {
        console.warn("Profile ID not found, unable to update logged-in time.");
      }
    } catch (error) {
      console.error("Error in setLocalData:", error);
    }
  }

  getUserData() {
    return JSON.parse(localStorage.getItem("userData") || "{}");
  }

  async checkLogin(): Promise<boolean> {
    const userData = localStorage.getItem("userData");
    const isSessionActive = localStorage.getItem("isSessionActive");
    return userData !== null && isSessionActive !== null;
  }

  async isLoggedIn(): Promise<boolean> {
    const userData = this.getUserData();
    const sessionData = localStorage.getItem("isSessionActive");
    if (!userData || !sessionData) {
      return false;
    }
    const loggedInTime = new Date(JSON.parse(sessionData).loggedInTime);
    const serverLoggedTimeResponse = await this.getLoggedInTime(
      userData.id
    ).toPromise();
    const serverLoggedTime =
      serverLoggedTimeResponse?.data?.[0]?.attributes?.loggedInTime;
    if (!serverLoggedTime) {
      console.warn("Server logged time is undefined.");
      return false;
    }
    const serverTime = new Date(serverLoggedTime);
    const currentTime = new Date();
    if (serverTime.getTime() !== loggedInTime.getTime()) {
      console.warn("Session invalid: Time mismatch detected.");
      return false;
    }
    const timeDifference = currentTime.getTime() - loggedInTime.getTime();
    const isExpired = timeDifference > 24 * 60 * 60 * 1000;
    if (isExpired) {
      console.warn("Session expired: More than 24 hours since last login.");
    }
    return !isExpired;
  }

  setLoggedInTime(data: any, id: any) {
    return this.http.put(`${this.url}/profiles/${id}`, { data });
  }

  logout() {
    localStorage.removeItem("userData");
    localStorage.removeItem("isSessionActive");
    localStorage.removeItem("userPrivileges");
    localStorage.removeItem("profileData");
  }
}
