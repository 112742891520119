<!-- <ng-template #webCamPreview>
  <div class="py-3 px-4 col-12">
    <webcam
            [trigger]="invokeObservable"
            (imageCapture)="captureImg($event)"
          ></webcam>
  </div>
  <button class="btn btn-warning" (click)="getSnapshot()">
    Capture Image
  </button>
</ng-template> -->
<div class="container my-6 card-bg">
  <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
    <div class="row g-3">
      <div class="col-12">
        <h3 class="mb-0">Add Customer</h3>
      </div>
      <div class="col-10">
        <div class="row">
          <div class="col-2">
            <label class="form-label">Title</label>
            <select class="form-select" name="sur" formControlName="sur">
              <option value="">Title</option>
              <option value="Mr">Mr</option>
              <option value="Ms/Miss">Ms/Miss</option>
            </select>
          </div>
          <div class="col-4">
            <label class="form-label">Customer Name</label>
            <input type="text" class="form-control" name="name" formControlName="name" placeholder="Customer Name">
          </div>
          <div class="col-2 label-space">
            <label class="form-label">Care of</label>
            <select class="form-select" name="co" formControlName="co">
              <option value="">Care of</option>
              <option value="S/o">S/o</option>
              <option value="D/o">D/o</option>
              <option value="W/o">W/o</option>
              <option value="C/o">C/o</option>
            </select>
          </div>
          <div class="col-4 label-space">
            <label class="form-label">Father/Husband Name</label>
            <input type="text" class="form-control" placeholder="Father/Husband Name" formControlName="fname">
          </div>
          <div class="col-6 label-space">
            <label class="form-label">ID Type</label>
            <select class="form-select" formControlName="proofType" (change)="idProofType($event)">
              <option value="">-- SELECT TD PROOF TYPE --</option>
                <option value="1">Aadhar Card</option>
                <option value="2">PAN Card</option>
                <option value="3">Driving Licence</option>
                <option value="4">Ration Card</option>
            </select>
          </div>
          <div class="col-6 label-space">
            <label class="form-label">{{idLable}} No</label>
            <input type="text" class="form-control" placeholder="{{idLable}} No" formControlName="proofno">
          </div>
          <div class="col-6 label-space">
            <label class="form-label">Contact Number</label>
            <input type="text" class="form-control" placeholder="Contact Number" formControlName="contact1">
          </div>
          <div class="col-6 label-space">
            <label class="form-label">Alternate Contact</label>
            <input type="text" class="form-control" placeholder="Alternate Contact" formControlName="contact2">
          </div>
          <div class="col-4 label-space">
            <label class="form-label">Nominee</label>
            <input type="text" class="form-control" placeholder="Nominee" formControlName="nominee">
          </div>
          <div class="col-4 label-space">
            <label class="form-label">Relationship</label>
            <select class="form-select" formControlName="relation">
              <option *ngFor="let rel of relation">{{rel.name}}</option>
            </select>
          </div>
          <div class="col-4 label-space">
            <label class="form-label">Nominee Contact</label>
            <input type="text" class="form-control" placeholder="Nominee Contact" formControlName="nomineeContact">
          </div>
          <div class="col-12 label-space">
            <label class="form-label">Address</label>
            <textarea type="text" class="form-control" placeholder="Address" formControlName="address"></textarea>
          </div>
          <!-- </div> -->
        </div>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-12">
            <label class="form-label">Customer Photo</label>
            <div class="img-preview">
              <img *ngIf="customerPhotoBase64" src="{{customerPhotoBase64}}"
                style="max-width: 100%;">
            </div>
          </div>
          <div class="col-12 py-1">
            <button class="btn btn-dark" type ="button" (click)="openWebCam('U')">Open Camera</button>
            <!-- <label class="form-label">Customer Photo</label> -->
            <!-- <input type="file" class="form-control" id="customerPhoto" (change)="handleCustPhoto($event)"> -->
          </div>
          <div class="col-12">
            <label class="form-label">Id Photo</label>
            <div class="img-preview">
              <img *ngIf="govtIdPhotoBase64" src="{{govtIdPhotoBase64}}" style="max-width: 100%;">
            </div>
          </div>
          <div class="col-12 py-1">
            <!-- <label class="form-label">ID Photo</label> -->
            <!-- <input type="file" class="form-control" id="govtIdPhoto" (change)="handleGovtIdPhoto($event)"
              aria-label="Government ID Photo"> -->
              <button class="btn btn-dark" type ="button" (click)="openWebCam('P')">Open Camera</button>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <button type="button" class="btn btn-dark" (click)="onNoClick()">Close</button> &nbsp;
        <button type="submit" class="btn btn-primary">Save</button>
      </div>
    </div>
  </form>
</div>