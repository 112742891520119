<div class="container my-6 card-bg p-4">
    <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
      <div class="row g-3">
        <div class="col-12">
          <h3 class="mb-0">Update Jwell Location</h3>
        </div>
        <div class="col-6">
          <label class="form-label">Jwellels Location</label>
          <select name="locker" formControlName="locker" class="form-select" (change)="setlockerInfo($event)">
            <option value="0">Office</option>
            <option value="1">Locker</option>
          </select>
          <div
            *ngIf="
              formInputs.get('locker')?.invalid &&
              formInputs.get('locker')?.touched
            "
          >
            <small
              class="text-danger"
              *ngIf="formInputs.get('locker')?.errors?.['required']"
            >
              Please Select Jwellels Location.
            </small>
          </div>
        </div>
        <!-- <div class="col-6"> -->
            <div class="col-6" *ngIf="formInputs.controls['locker'].value =='1'">
                <label for="Price" class="form-label">Locker No</label>
          <input type="text" class="form-control" name="lockno" formControlName="lockno" placeholder="Locker no">
        </div>
      </div>
      <div class="mt-3">
        <button type="button" class="btn btn-dark" (click)="onNoClick()">Close</button> &nbsp;
        <input type="submit" class="btn btn-primary" value="Save">
      </div>
    </form>
  </div>