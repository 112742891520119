<div class="container my-6 card-bg g-3 py-3 px-3">
  <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
    <div class="row g-3">
      <div class="col-12">
        <h3 class="mb-0">Create Branch</h3>
      </div>
      <div class="col-12">
        <label for="Branch Name" class="form-label">Branch Name</label>
        <input
          type="text"
          class="form-control"
          name="name"
          formControlName="name"
          id="name"
          placeholder="Branch Name"
          aria-label="Branch Name"
        />
        <div
          *ngIf="
            formInputs.get('name')?.invalid &&
            formInputs.get('name')?.touched
          "
        >
          <small
            class="text-danger"
            *ngIf="formInputs.get('name')?.errors?.['required']"
          >
            Please Enter Branch Name.
          </small>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <button type="button" class="btn btn-dark" (click)="onNoClick()">
        Close
      </button>
      &nbsp;
      <input type="submit" class="btn btn-primary" value="Save" />
    </div>
  </form>
</div>
