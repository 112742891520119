import { Component } from '@angular/core';

import { navItems } from './_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent {
  public navItems = navItems;
  public isCalculatorVisible = false;

  constructor() {}

  toggleCalculator() {
    this.isCalculatorVisible = !this.isCalculatorVisible;
  }
}
