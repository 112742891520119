import { Component, OnInit,Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebcamImage} from 'ngx-webcam';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-web-cam',
  templateUrl: './web-cam.component.html',
  styleUrls: ['./web-cam.component.scss']
})
export class WebCamComponent implements OnInit {
  navData : any;
  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';
  constructor (    public dialogRef: MatDialogRef<WebCamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){
      this.navData = data.data;
       }

  ngOnInit() { }
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }
  takeAgain(){
    this.getSnapshot();
  }
  closeCam(): void {
    this.dialogRef.close({
      "type" : this.navData,
      "image":this.sysImage
    });
  }
}
