import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NumberToWordsService } from "src/app/services/number-to-words.service";
import { GetDataService } from "src/app/services/get-data.service";

@Component({
  selector: "app-pledge",
  templateUrl: "./pledge.component.html",
  styleUrls: ["./pledge.component.scss"],
})
export class PledgeComponent implements OnInit {
  navData: any;
  custData: any;
  prooftypeData: any;
  weight: any = 0;
  items: any = 0;
  silver: any = 0;
  nineone: any = 0;
  seventyfive: any = 0;
  eighty: any = 0;
  eightyfive: any = 0;
  constructor(
    public dialogRef: MatDialogRef<PledgeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private numberToWords: NumberToWordsService,
    private getData: GetDataService
  ) {
    this.navData = data.loan;
    this.custData = data.custDtls;
    this.prooftypeData = [
      { value: "1", label: "Aadhar Card" },
      { value: "2", label: "PAN Card" },
      { value: "3", label: "Driving Licence" },
      { value: "4", label: "Ration Card" },
    ];
    console.log(this.navData);
    console.log(this.custData);
  }
  ngOnInit(): void {
    this.totalItems();
    this.getSchemeData();
  }
  filterDate(date: any, type: any) {
    if (type == "T") {
      let [hours, minutes, seconds] = date.split(" ")[1].split(":").map(Number);
      let amPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")} ${amPm}`;
    } else {
      return date.split(" ")[0];
    }
  }
  getProofType(id: any) {
    return this.prooftypeData.find((ele: any) => {
      return ele.value == id ? ele : "";
    })?.label;
  }
  getApproxAmt(amt: any) {
    return parseFloat(amt) + parseFloat(amt) * 0.1;
  }
  getClosing(date: string, tenure: string): string {
    let ndate = new Date(date);
    ndate.setMonth(ndate.getMonth() + parseInt(tenure, 10));
    let year = ndate.getFullYear();
    let month = String(ndate.getMonth() + 1).padStart(2, "0");
    let day = String(ndate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  getDueDate(date: any) {
    return new Date(date).getDate();
  }
  calculateInterestForOneMonth(): number {
    const principal = parseFloat(this.navData.attributes.amount);
    const roi = parseFloat(this.navData.attributes.roi);
    const timeInYears = 1 / 12;
    const interest = (principal * roi * timeInYears) / 100;
    return interest;
  }
  getTouch(tou: any) {
    let touchMap = [
      {
        lable: "silver",
        value: "S",
      },
      {
        lable: "nineone",
        value: "916",
      },
      {
        lable: "seventyfive",
        value: "75",
      },
      {
        lable: "eightyfive",
        value: "85",
      },
      {
        lable: "eighty",
        value: "80",
      },
    ];
    return touchMap.find((ele: any) => {
      return ele.lable == tou ? ele : "";
    })?.value;
  }
  totalItems() {
    this.navData.products.forEach((ele: any, i: number) => {
      this.weight += parseFloat(ele.attributes.weight);
    });
    this.items = this.navData.products.length;
  }
  amountinWords(amount: any) {
    return this.numberToWords.numberToWords(amount);
  }
  getSchemeData() {
    this.getData.getSchemesByName(this.navData.attributes.scheme).subscribe(
      (res: any) => {
        console.log(res.data[0].attributes.silver);
        this.silver = res.data[0].attributes.silver;
        this.nineone = res.data[0].attributes.nineone;
        this.eighty = res.data[0].attributes.eighty;
        this.eightyfive = res.data[0].attributes.eightyfive;
        this.seventyfive = res.data[0].attributes.seventyfive;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
