import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CustomersFormComponent } from "../../forms/customers-form/customers-form.component";
import { GetDataService } from "./../../../services/get-data.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { DeleteDataService } from "./../../../services/delete-data.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
  selector: "app-customers",
  templateUrl: "./customers.component.html",
  styleUrls: ["./customers.component.scss"],
})
export class CustomersComponent {
  formInputs: FormGroup;
  dtls: any;
  displayedData: any;
  editData: any;
  @ViewChild("dialogBox") dialogBox!: TemplateRef<any>;
  @ViewChild("userImg") userImg!: TemplateRef<any>;
  deleteId: any = "";
  dataPage: number = 1;
  pageLen: number = 10;
  metaData: any = {};
  sname: any;
  stype: any;
  selectedUserImg: any;
  isSearched:any;
  roles: any;
  constructor(
    private route: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public getData: GetDataService,
    public deleteData: DeleteDataService,
    public formBuilder: FormBuilder
  ) {
    this.editData = {};
    this.formInputs = this.formBuilder.group({
      sname: ["", [Validators.required, Validators.minLength(2)]],
      stype: ["", [Validators.required]],
    });
    this.isSearched = false;
  }

  ngOnInit() {
    this.loadData();
    let role: any = localStorage.getItem("userPrivileges");
    this.roles = JSON.parse(role);
  }
  initSearch() {
    if (this.formInputs.valid) {
      this.isSearched = true;
      this.getData.getCustSearch(this.formInputs.value.sname,this.formInputs.value.stype).subscribe((res:any)=>{
        this.dtls = res;
        this.displayedData = this.dtls.data;
        this.metaData = this.dtls.meta.pagination;
      },err=>{
        console.log(err)
      })
      console.log(this.formInputs.value);
    } else {
      this.formInputs.markAllAsTouched();
    }
  }
  closeSearch(){
    this.formInputs.reset();
    this.loadData();
    this.isSearched = false;
  }
  paginationChange(page: any) {
    if (page == "prev") {
      this.dataPage = this.dataPage - 1;
    } else if (page == "next") {
      this.dataPage = this.dataPage + 1;
    } else {
      this.dataPage = page.target.value;
    }
    this.loadData();
  }
  pageSize(event: any) {
    this.pageLen = event.target.value;
    this.loadData();
  }
  loadData() {
    this.getData.getCustomer(this.pageLen, this.dataPage).subscribe(
      (res) => {
        this.dtls = res;
        this.displayedData = this.dtls.data;
        this.metaData = this.dtls.meta.pagination;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  addCustomer() {
    const dialogRef = this.dialog.open(CustomersFormComponent, {
      width: "950px",
      height: "550px",
      data: {
        data: this.editData,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadData();
    });
  }

  editCustomer(product: any) {
    this.editData = product;
    this.addCustomer();
  }
  cancelDelete() {
    this.deleteId = "";
    this.dialog.closeAll();
  }
  confirmDelete() {
    this.deleteData.deleteCustomer(this.deleteId).subscribe(
      (res) => {
        this.deleteId = "";
        this.cancelDelete();
        this.loadData();
        this.showToast();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  showToast() {
    this.toastr.success("Customer Deleted Succesfully", "Success!");
  }
  veiwDetails(id: any) {
    this.route.navigate(["customers/loan-details/" + id]);
  }

  deleteCustomer(product: any) {
    this.deleteId = product.id;
    this.dialog.open(this.dialogBox);
  }
  enlargeImg(base64: any) {
    this.selectedUserImg = base64;
    this.dialog.open(this.userImg);
  }
}
