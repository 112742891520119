import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InsertDataService {
  url: string = environment.apiUrl;
  constructor(public http: HttpClient) { }
  addProduct(values: any) {
    values.status = "1";
    return this.http.post(this.url + '/products', { data: values });
  }
  addCustomer(values: any) {
    return this.http.post(this.url + '/customers', { data: values });
  }
  addUsers(values: any) {
    return this.http.post(this.url + '/auth/local/register', values);
  }
  addProfile(values: any) {
    return this.http.post(this.url + '/profiles', { data: values });
  }
  addRole(values: any) {
    return this.http.post(this.url + '/privileges', { data: values });
  }
  addPermissions(values: any) {
    return this.http.post(this.url + '/user-permissions', { data: values });
  }
  addBranches(values: any) {
    return this.http.post(this.url + '/branches', { data: values });
  }
  addLoan(values: any) {
    return this.http.post(this.url + '/loanmasters', { data: values });
  }
  addProducts(values: any) {
    return this.http.post(this.url + '/loans', { data: values });
  }
  addScheme(values: any) {
    return this.http.post(this.url + '/schemes', { data: values });
  }
  addPayment(values: any) {
    return this.http.post(this.url + '/payments', { data: values });
  }
}
