<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
    >
      <svg cIcon
        name="cilMenu"
        size="lg"
      ></svg>
    </button>
    <c-header-nav class="d-none d-lg-flex me-auto">
      <c-nav-item>
        <a cNavLink routerLink="/dashboard" routerLinkActive="active">
          Dashboard
        </a>
      </c-nav-item>
    </c-header-nav>
    <c-header-nav class="ms-3">
      <div class="row float-right">
        <div class="col-9 px-0">
          <select class="form-select" (change)="onChangeOfBranch($event)">
            <option value="0">All branch</option>
            <option value="1">Test</option>
          </select>
        </div>
        <div class="col-3 ps-0">
          <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
        </div>        
      </div>
    </c-header-nav>

  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
        <button
        cButton
        color=""
        [caret]="false"
        cDropdownToggle
        class="py-0"
      >
        <c-avatar
          cTextColor="primary"
          shape="rounded-1"
          size="md"
          src="./assets/images/cashway.png"
          status="success"
        >
        </c-avatar>
      </button>  
    <ul cDropdownMenu class="pt-0 pr-5 w-auto" style="border-radius: 3px;
    border: 0px;
    box-shadow: 1px 3px 6px #959393;">
      <li style="min-width: 250px;">
        <h4 cDropdownHeader class="bg-light py-2 mb-0">Account</h4>
      </li>
      <!-- <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilBell"></svg>
          Updates
          <c-badge class="ms-2 float-end" color="info">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilEnvelopeOpen"></svg>
          Messages
          <c-badge class="ms-2 float-end" color="success">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilTask"></svg>
          Tasks
          <c-badge class="ms-2 float-end" color="danger">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name='cilCommentSquare'></svg>
          Comments
          <c-badge class="ms-auto" color="warning">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Settings</h6>
      </li>
      <li></li> -->
      <li class="py-2">
        <a class="ps-3 prof__link">
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
     <!--  <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilSettings"></svg>
          Settings
        </a>
      </li> -->
      <li class="py-2">
        <!-- <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilCreditCard"></svg>
          Payments
          <c-badge class="ms-2 float-end" color="secondary">
            42
          </c-badge>
        </a> -->
        <a (click)="logout()" class="ps-3 prof__link">
          <svg cIcon class="me-2" name="cil-account-logout"></svg>
          Logout
        </a>
      </li>
      <!-- <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilFile"></svg>
          Projects
          <c-badge class="ms-2 float-end" color="primary">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <hr cDropdownDivider/>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Lock Account
        </a>
      </li>
      <li></li> -->
    </ul>
  </c-dropdown>
</ng-template>

