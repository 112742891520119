import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InsertDataService } from './../../../services/insert-data.service';
import { UpdateDataService } from './../../../services/update-data.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-payments-form',
  templateUrl: './payments-form.component.html',
  styleUrls: ['./payments-form.component.scss']
})
export class PaymentsFormComponent {
  formInputs: FormGroup;
  currentDateAndTime: any;
  navData: any;
  uniqKey:any;
  userData: any;
  constructor(
    public dialogRef: MatDialogRef<PaymentsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
    public addData: InsertDataService,
    private toastr: ToastrService,
    public updatedata: UpdateDataService,
    private datePipe: DatePipe
  ) {
    this.userData = localStorage.getItem("userData");
    this.formInputs = this.formBuilder.group({
      amount: ['', [Validators.required]],
      mode: ['', [Validators.required]],
    });
    this.navData = data.data;
  }
  ngOnInit(): void {
    this.currentDateAndTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    this.uniqKey = this.datePipe.transform(new Date(), 'yyyyMMddHHmmss')+this.navData.loanid.toString();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  allocatePayment(totalPaid: any, penaltyDue: any, interestDue: any, principalDue: any) {
    let remainingPayment = totalPaid;
    let penaltyPaid:number = 0.0;
    let interestPaid:number = 0.0;
    let principalPaid:number = 0.0;
    penaltyDue = parseFloat(penaltyDue);
    interestDue = parseFloat(interestDue);
    principalDue = parseFloat(principalDue);
    if (remainingPayment >= penaltyDue) {
      penaltyPaid = penaltyDue;
      remainingPayment -= penaltyDue;
    } else {
      penaltyPaid = remainingPayment;
      remainingPayment = 0;
    }
    if (remainingPayment > 0) {
      if (remainingPayment >= interestDue) {
        interestPaid = interestDue;
        remainingPayment -= interestDue;
      } else {
        interestPaid = remainingPayment;
        remainingPayment = 0;
      }
    }
    if (remainingPayment > 0) {
      principalPaid = remainingPayment;
      remainingPayment = 0;
    }
    if (penaltyDue != 0) {
      this.callServer(penaltyPaid, '2')
    }
    if (interestPaid != 0) {
      this.callServer(interestPaid, '0')
    }
    if (principalPaid != 0) {
      this.callServer(principalPaid, '1')
    }
    if(principalDue == principalPaid){
      this.closeLoan();
    }
  }
  closeLoan(){
    let postData = {
      isClosed: "1",
      id: this.navData.loanid.toString(),
    }
    this.updatedata.updateClosing(postData).subscribe(res=>{
      return res;
    },err=>{
      console.log(err);
    })
  }
  callServer(amount: any, type: string) {
    let postData = {
      amount: amount.toString(),
      isTotal: type.toString(),
      loanid: this.navData.loanid.toString(),
      user: JSON.parse(this.userData).id.toString(),
      date: this.currentDateAndTime,
      uniqKey : this.uniqKey,
      mode : this.formInputs.value.mode.toString()
    }
    this.addData.addPayment(postData).subscribe(res => {
      this.formInputs.reset();
      this.showToast("1");
      this.onNoClick();
    }, err => {
      this.showToast("0")
      console.log(err);
    })
  }
  onSubmit(): void {
    if (this.formInputs.valid) {
      this.allocatePayment(this.formInputs.value.amount.toString(), this.navData.paymentData.penaltyDue, this.navData.paymentData.interestDue, this.navData.paymentData.remainingPrincipal)
    }else {
      this.formInputs.markAllAsTouched();
    }
  }
  showToast(params: any) {
    if (params == '1') {
      this.toastr.success('Payment completed!', 'Success!');
    }
    else {
      this.toastr.warning('Something went wrong, try again later', 'Oops!');
    }
  }
}
