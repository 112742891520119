import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CalculationService } from "src/app/services/calculation.service";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-closing",
  templateUrl: "./closing.component.html",
  styleUrls: ["./closing.component.scss"],
  providers: [DatePipe],
})
export class ClosingComponent {
  formInputs: FormGroup;
  currentDateAndTime: any;
  principalAmount: any;
  totalInterestAccrued: any;
  totalAmountToBePaid: any;
  penalty: any;
  navData: any;
  userData: any;
  totalAmountToPay: any;
  loanDetails: any;
  today = new Date();
  lateTenureInt: any;
  dateFilter = (date: Date | null): boolean => {
    if (date) {
      let tempDate = new Date(this.navData.loanData.date);
      tempDate.setDate(tempDate.getDate() - 1);
      const currentDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      const todayDate = new Date(tempDate);
      return currentDate >= todayDate;
    }
    return false;
  };
  constructor(
    public dialogRef: MatDialogRef<ClosingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
    private toastr: ToastrService,
    public calculationService: CalculationService
  ) {
    this.navData = data;
    this.formInputs = this.formBuilder.group({
      date: [new FormControl(), Validators.required],
    });
  }
  onSubmit() {
    const closingDate = this.formInputs.value.date;
    if (this.formInputs.valid) {
      let calSerData = this.calculationService.calculatePendingAmounts(
        this.navData.loanData,
        closingDate,
        this.navData.paymentdata
      );
      this.totalAmountToPay = calSerData.totalAmountToPay;
      this.principalAmount = calSerData.remainingPrincipal;
      this.totalInterestAccrued = calSerData.interestDue;
      this.penalty = calSerData.penaltyDue;
      this.lateTenureInt = calSerData.lateTenureInt;
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
