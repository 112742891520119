<div class="container my-2">
<div class="row">
    <div class="col-6">
        <h2>User Picture</h2>
        <div class="row">
            <div class="col-12">
                <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)"></webcam>
            </div>
            <div class="col-12">
                <button class="btn btn-danger" (click)="getSnapshot()">
                    <span *ngIf="webcamImage">
                        Retake
                    </span>
                    <span *ngIf="!webcamImage">
                        Capture Image
                    </span>
                </button> &nbsp;
                <button class="btn btn-success" (click)="closeCam()">
                    close
                </button>
            </div>
        </div>
    </div>
    <div class="col-6">
        <h2>Preview</h2>
            <img [src]="webcamImage.imageAsDataUrl" height="auto" style="height:auto; width: 100%;" />
    </div>
</div>
</div>