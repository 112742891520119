<div class="container my-6 card-bg">
  <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
    <div class="row g-3">
      <div class="col-12">
        <h3 class="mb-0">{{pageTitle}}</h3>
      </div>
      <div class="col-12">
        <label for="name" class="form-label">Role Title</label>
        <input type="text" class="form-control" name="Name" formControlName="Name" id="name" placeholder="Role Title" aria-label="Role's Name">
        <div *ngIf="formInputs.get('Name')?.invalid && formInputs.get('Name')?.touched">
          <small class="text-danger" *ngIf="formInputs.get('Name')?.errors?.['required']">
            Please Enter Role Title.
          </small>
        </div>
      </div>

      <div class="col-12 pt-2">
        <label for="name" class="form-label">Permissions</label>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="makepayment" formControlName="makepayment">
          <label class="form-check-label" for="makepayment">
            Make Payment
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="updatelocker" formControlName="updatelocker">
          <label class="form-check-label" for="updatelocker">
            Update Locker
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="editcustomer" formControlName="editcustomer">
          <label class="form-check-label" for="editcustomer">
            Edit Customer
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="addcustomer" formControlName="addcustomer">
          <label class="form-check-label" for="addcustomer">
            Add Customer
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="deletecustomer" formControlName="deletecustomer">
          <label class="form-check-label" for="deletecustomer">
            Delete Customer
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="editproduct" formControlName="editproduct">
          <label class="form-check-label" for="editproduct">
            Edit Product
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="addproduct" formControlName="addproduct">
          <label class="form-check-label" for="addproduct">
            Add Product
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="deleteproduct" formControlName="deleteproduct">
          <label class="form-check-label" for="deleteproduct">
            Delete Product
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="editscheme" formControlName="editscheme">
          <label class="form-check-label" for="editscheme">
            Edit Scheme
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="addscheme" formControlName="addscheme">
          <label class="form-check-label" for="addscheme">
            Add Scheme
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="deletescheme" formControlName="deletescheme">
          <label class="form-check-label" for="deletescheme">
            Delete Scheme
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="viewuser" formControlName="viewuser">
          <label class="form-check-label" for="viewuser">
            View User
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="adduser" formControlName="adduser">
          <label class="form-check-label" for="adduser">
            Add User
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="edituser" formControlName="edituser">
          <label class="form-check-label" for="edituser">
            Edit User
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="deleteuser" formControlName="deleteuser">
          <label class="form-check-label" for="deleteuser">
            Delete User
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="createloan" formControlName="createloan">
          <label class="form-check-label" for="createloan">
            Create Loan
          </label>
        </div>
      </div>

      <div class="col-3 mt-0 p-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="renewloan" formControlName="renewloan">
          <label class="form-check-label" for="renewloan">
            Renew Loan
          </label>
        </div>
      </div>

    </div>
    <div class="mt-3">
      <button type="button" class="btn btn-dark" (click)="onNoClick()">Close</button> &nbsp;
      <input type="submit" class="btn btn-primary" value="Save">
    </div>
  </form>
</div>
