import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateDataService {
  url: string = environment.apiUrl;
  constructor(public http: HttpClient) { }
  updateProduct(values: any) {
    return this.http.put(this.url + '/products/' + values.id, { data: values });
  }
  updateCustomer(values: any) {
    return this.http.put(this.url + '/customers/' + values.id, { data: values });
  }
  updateUsers(values: any) {
    return this.http.put(this.url + '/profiles/' + values.id, { data: values });
  }
  updateRoles(values: any) {
    return this.http.put(this.url + '/privileges/' + values.id, { data: values });
  }
  updatePermissions(values: any) {
    return this.http.put(this.url + '/user-permissions/' + values.id, { data: values });
  }
  updateBranches(values: any) {
    return this.http.put(this.url + '/branches/' + values.id, { data: values });
  }
  updateSchemes(values: any) {
    return this.http.put(this.url + '/schemes/' + values.id, { data: values });
  }
  updateLocker(values: any) {
    return this.http.put(this.url + '/loans/' + values.id, { data: values });
  }
  updateClosing(values: any) {
    return this.http.put(this.url + '/loanmasters/' + values.id, { data: values });
  }
}
