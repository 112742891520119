import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeleteDataService {
  url: string = environment.apiUrl;
  constructor(public http: HttpClient) { }
  deleteProduct(id: any) {
    return this.http.delete(this.url + '/products/' + id);
  }
  deleteCustomer(id: any) {
    return this.http.delete(this.url + '/customers/' + id);
  }
  deleteUsers(id: any) {
    return this.http.delete(this.url + '/users/' + id);
  }
  deleteProfile(id: any) {
    return this.http.delete(this.url + '/profiles/' + id);
  }
  deleteRoles(id: any) {
    return this.http.delete(this.url + '/privileges/' + id);
  }
  deleteBranches(id: any) {
    return this.http.delete(this.url + '/branches/' + id);
  }
  deleteSchemes(id: any) {
    return this.http.delete(this.url + '/schemes/' + id);
  }
}
