import { Component } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  loginForm: FormGroup;
  tempData: any;
  userData: any;
  constructor(
    public authService: AuthService,
    private toastr: ToastrService,
    public router: Router
  ) {
    this.loginForm = new FormGroup({
      identifier: new FormControl(""),
      password: new FormControl(""),
    });
    this.userData = {
      jwt: "",
      user: "",
      id: "",
      isBlocked: "",
    };
  }
  async authUser(): Promise<void> {
    try {
      const res = await this.authService
        .login(this.loginForm.value)
        .toPromise();
      this.tempData = res;
      this.userData.token = this.tempData.jwt;
      this.userData.user = this.tempData.user.username;
      this.userData.id = this.tempData.user.id;
      this.userData.email = this.tempData.user.email;
      this.userData.isBlocked = this.tempData.user.blocked;
      if (!this.userData.isBlocked) {
        this.showToast("1");
        await this.authService.setLocalData(this.userData);
        this.router.navigate(["dashboard"]);
      } else {
        this.showToast("2");
      }
    } catch (err) {
      console.error("Login error:", err);
      this.showToast("0");
    }
  }
  showToast(params: any) {
    if (params == "1") {
      this.toastr.success("Loggedin Succesfully", "Success!");
    } else if (params == "2") {
      this.toastr.error("User Blocked! contact Administrator", "Error!");
    } else {
      this.toastr.warning("Invalid Username/Password", "Oops!");
    }
  }
}
