<ng-template #dialogBox>
  <div class="py-3 px-4 col-12">
    <h2 matDialogTitle>Are you Sure ?</h2>
    <p matDialogContent>Do you want to delete?</p>
    <hr />
    <div class="float-right">
      <button cButton color="dark" matDialogClose (click)="cancelDelete()">
        Cancel</button
      >&nbsp;
      <button cButton color="danger" matDialogClose (click)="confirmDelete()">
        Confirm
      </button>
    </div>
  </div>
</ng-template>
<ng-template #userImg>
  <div class="py-3 px-4 col-12" *ngIf="selectedUserImg != ''">
    <img
      class=""
      src="{{ selectedUserImg }}"
      style="width: 100%; height: auto; max-width: 400px"
    />
    <!-- <div class="">
    <button cButton color="dark" matDialogClose (click)="cancelDelete()">Close</button>&nbsp;
  </div> -->
  </div>
  <div class="py-3 px-4 col-12" *ngIf="selectedUserImg == ''">
    <img
      class=""
      src="../../../../assets/img/avatars/user-thumb.png"
      style="width: 100%; height: auto; max-width: 400px"
    />
    <!-- <div class="">
      <button cButton color="dark" matDialogClose (click)="cancelDelete()">Close</button>&nbsp;
    </div> -->
  </div>
</ng-template>

<c-card class="mb-4">
  <c-card-body>
    <c-row>
      <c-col sm="4" class="pt-3">
        <h3><b>Customer Details </b></h3>
      </c-col>
      <c-col sm="6" class="mb-4 pt-3">
        <form [formGroup]="formInputs" (ngSubmit)="initSearch()">
        <div class="row">
          <div class="col-5">
            <input type="text" class="form-control" name="sdata" placeholder="Search here.." formControlName="sname">
            <div
              *ngIf="
                formInputs.get('sname')?.invalid &&
                formInputs.get('sname')?.touched">
              <small
                class="text-danger"
                *ngIf="formInputs.get('sname')?.errors?.['required']"
              >
                Please Enter Input.
              </small>
            </div>
          </div>
          <div class="col-4">
            <select class="form-select" name="stype" formControlName="stype">
              <option value="">-- Select --</option>
              <option value="1">Name</option>
              <option value="2">Proof No</option>
              <option value="3">Mobile No</option>
            </select>
            <div
              *ngIf="
                formInputs.get('stype')?.invalid &&
                formInputs.get('stype')?.touched
              "
            >
              <small
                class="text-danger"
                *ngIf="formInputs.get('stype')?.errors?.['required']"
              >
                Please Select Search Type.
              </small>
            </div>
          </div>
          <div class="col-3">
            <button type="submit" cButton color="warning" class="mx-1">
              <svg cIcon name="cilSearch"></svg>
            </button>&nbsp;
            <button type="button" (click)="closeSearch()" cButton color="danger" *ngIf="isSearched">
              <svg cIcon name="cilPlus" class="rotate-box"></svg>
            </button>
            </div>
        </div>
        </form>
      </c-col>
      <c-col sm="2" class="mb-1 float-right pt-3">
        <button (click)="addCustomer()" cButton color="primary" *ngIf="roles.addcustomer" style="width:100%">
          Add Customer
        </button>
      </c-col>
      <c-col xs="12">
        <table bordered borderColor="dark" cTable>
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th></th>
              <th scope="col">Name</th>
              <th scope="col" width="25%">Address</th>
              <th scope="col">Contact</th>
              <!-- <th scope="col">ID Proof</th> -->
              <th scope="col">Nominee Details</th>
              <th scope="col"  *ngIf="roles.editcustomer || roles.deletecustomer">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let customer of displayedData; let i = index">
              <th ope="row">{{ i + 1 }}</th>
              <td>
                <div
                  class="avatar"
                  (click)="enlargeImg(customer.attributes.photo)"
                  *ngIf="customer.attributes.photo != ''"
                >
                  <img
                    class="avatar-img"
                    src="{{ customer.attributes.photo }}"
                    style="max-width: 100px; max-height: 100px"
                  />
                </div>
                <div class="avatar" *ngIf="customer.attributes.photo == ''">
                  <img
                    class="avatar-img"
                    src="../../../../assets/img/avatars/user-thumb.png"
                    style="max-width: 100px; max-height: 100px"
                  />
                </div>
              </td>
              <td width="15%">
                <a (click)="veiwDetails(customer.id)"
                  >{{ customer.attributes.sur }} {{ customer.attributes.name }}
                  {{ customer.attributes.co }} {{ customer.attributes.fname }}
                </a>
              </td>
              <td>{{ customer.attributes.address }}</td>
              <td>
                {{ customer.attributes.contact1 }},<br />{{
                  customer.attributes.contact2
                }}
              </td>
              <td>
                {{ customer.attributes.nominee }} /
                {{ customer.attributes.relation }}<br />{{
                  customer.attributes.nomineeContact
                }}
              </td>
              <td class="action-buttons" *ngIf="roles.editcustomer || roles.deletecustomer">
                <button
                  class="btn btn-warning py-1"
                  (click)="editCustomer(customer)" *ngIf="roles.editcustomer"
                >
                  <svg cIcon name="cilPencil"></svg></button
                >&nbsp;
                <button
                  class="btn btn-danger py-1"
                  (click)="deleteCustomer(customer)" *ngIf="roles.deletecustomer"
                >
                  <svg cIcon name="cilTrash"></svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-4 row">
            <div class="col-6 pt-4 pb-2">Rows per Page</div>
            <div class="col-4 pt-4 pb-2">
              <select class="form-select" (change)="pageSize($event)">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>75</option>
                <option>100</option>
              </select>
            </div>
          </div>
          <div class="text-end offset-5 col-3 pt-4 pb-2 row">
            <div class="col-4">
              <span *ngIf="dataPage > 1"
                ><button
                  class="btn btn-light"
                  (click)="paginationChange('prev')"
                >
                  Prev
                </button>
              </span>
            </div>
            <div class="col-2 p-0">
              <input
                type="text"
                value="{{ dataPage }}"
                class="form-control"
                style="width: 40px"
                (blur)="paginationChange($event)"
              />
            </div>
            <div class="col-2 p-0 fs-18">
              / <b>{{ metaData.pageCount }}</b>
            </div>
            <div class="col-4">
              <span *ngIf="metaData && dataPage < metaData.pageCount"
                ><button
                  class="btn btn-light"
                  (click)="paginationChange('next')"
                >
                  Next
                </button></span
              >
            </div>
          </div>
        </div>
      </c-col>
    </c-row>
  </c-card-body>
</c-card>
