<div class="calculator">
    <div class="calculator-screen">{{ screen }}</div>
    <div class="calculator-buttons">
      <button class="btn" (click)="clearScreen()">C</button>
      <button class="btn" (click)="deleteLast()">DEL</button>
      <button class="btn" (click)="appendCharacter('%')">%</button>
      <button class="btn" (click)="appendCharacter('/')">/</button>
      <button class="btn" (click)="appendCharacter('7')">7</button>
      <button class="btn" (click)="appendCharacter('8')">8</button>
      <button class="btn" (click)="appendCharacter('9')">9</button>
      <button class="btn" (click)="appendCharacter('*')">*</button>
      <button class="btn" (click)="appendCharacter('4')">4</button>
      <button class="btn" (click)="appendCharacter('5')">5</button>
      <button class="btn" (click)="appendCharacter('6')">6</button>
      <button class="btn" (click)="appendCharacter('-')">-</button>
      <button class="btn" (click)="appendCharacter('1')">1</button>
      <button class="btn" (click)="appendCharacter('2')">2</button>
      <button class="btn" (click)="appendCharacter('3')">3</button>
      <button class="btn" (click)="appendCharacter('+')">+</button>
      <button class="btn" (click)="appendCharacter('0')">0</button>
      <button class="btn" (click)="appendCharacter('.')">.</button>
      <button class="btn equal-btn" (click)="calculateResult()">=</button>
    </div>
  </div>
  