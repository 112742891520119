import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InsertDataService } from './../../../services/insert-data.service';
import { UpdateDataService } from './../../../services/update-data.service';
import { GetDataService } from './../../../services/get-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-role-form',
  templateUrl: './user-role-form.component.html',
  styleUrls: ['./user-role-form.component.scss']
})
export class UserRoleFormComponent implements OnInit {
  formInputs: FormGroup;
  navData: any;
  pageTitle: any;
  idvar:any;
  perId : any;
  selectedPermission:any;
  constructor(
    public dialogRef: MatDialogRef<UserRoleFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private addDataService: InsertDataService,
    private updateDataService: UpdateDataService,
    private getDataServices: GetDataService,
    private toastr: ToastrService
  ) {
    this.formInputs = this.formBuilder.group({
      Name: ['', Validators.required],
      settings: [],
      loans: [],
      users: [],
      payments: []
    });

    this.navData = data.data;
    this.pageTitle = "Add User Role"
  }

  ngOnInit(): void {
    if (this.navData && this.navData.id) {
      this.pageTitle = "Update User Role"
      this.formInputs.patchValue({
        Name: this.navData.attributes.Name,
      });
      this.getPermissions(this.navData.id)
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.formInputs.valid) {
      const formData = this.formInputs.value;
        if (this.navData && this.navData.id) {
          this.updateUser(formData);
        } else {
          this.addUser(formData);
        }
      } else {
        this.toastr.error('Please fill in all required fields.', 'Form Invalid');
    }
  }

  setPermissions(data: any, id: any) {
    var postData = {
      loans: data.loans != null ? true: false,
      payments: data.payments != null ? true: false,
      settings: data.settings != null ? true: false,
      users: data.users != null ? true: false,
      role: id.toString()
    }
    this.addDataService.addPermissions(postData).subscribe(res => {
      this.formInputs.reset();
      this.showToast('User Role Added Successfully', 'Success!');
      this.onNoClick();
    }, err => { console.log(err) })
  }

  getPermissions(id:any){
    this.getDataServices.getPermissions(id).subscribe(res=>{
      this.selectedPermission = res;
      const currentData = this.selectedPermission.data[0].attributes;
      this.formInputs.patchValue({
        loans: currentData.loans,
        payments: currentData.payments,
        settings: currentData.settings,
        users: currentData.users,
      });
    })
  }
  getPermissionsId(postData:any, id:any){
    this.getDataServices.getPermissions(id).subscribe(res=>{
      this.perId = res;
      postData.id = this.perId.data[0].id;
      this.updateDataService.updatePermissions(postData).subscribe(res => {
        this.formInputs.reset();
        this.showToast('User Role Updated Successfully', 'Success!');
        this.onNoClick();
    }, err => { console.log(err) })  
    })
  }
  updatePermissions(data: any, id: any) {
    var postData = {
      loans: data.loans != null ? true: false,
      payments: data.payments != null ? true: false,
      settings: data.settings != null ? true: false,
      users: data.users != null ? true: false
    }
    this.getPermissionsId(postData, id.toString())
  }

  addUser(formData: any): void {
    this.addDataService.addRole({ "Name": formData.Name }).subscribe(
      (res) => {
        this.idvar = res;
        this.setPermissions(formData, this.idvar.data.id);
      },
      (err) => {
        this.showToast('Something went wrong, try again later', 'Oops!');
        console.error(err);
      }
    );
  }

  updateUser(formData: any): void {
    var postdata = {
      "Name": formData.Name,
      "id": this.navData.id
    };
    this.updateDataService.updateRoles(postdata).subscribe(
      () => {
        this.updatePermissions(formData,this.navData.id);
      },
      (err) => {
        this.showToast('Something went wrong, try again later', 'Oops!');
        console.error(err);
      }
    );
  }

  showToast(message: string, title: string): void {
    this.toastr.success(message, title);
  }
}