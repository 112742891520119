import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url: string = environment.apiUrl;
  constructor(public http: HttpClient) { }
  login(user: any) {
    return this.http.post(this.url + '/auth/local', user);
  }
  setLocalData(data: any) {
    localStorage.setItem("userData", JSON.stringify(data))
  }
  getUserData(){
    JSON.parse(localStorage.getItem('userData')!);
  }
  isLoggedIn() {
    if (localStorage.getItem("userData")) {
      return true;
    }
    else {
      return false;
    }
  }
  logout() {
    localStorage.removeItem("userData");
  }
}
