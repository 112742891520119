<div class="container my-6 card-bg">
  <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
    <div class="row g-3">
      <div class="col-12">
        <h3 class="mb-0">Add Scheme</h3>
      </div>
      <div class="col-6">
        <label for="Scheme Name" class="form-label">Scheme Name</label>
        <input type="text" class="form-control" name="name" formControlName="name" id="name" placeholder="Scheme Name">
      </div>
      <div class="col-3">
        <label for="Scheme Name" class="form-label">Rate of Interest (%)</label>
        <input type="text" class="form-control" name="roi" formControlName="roi" id="roi" placeholder="Rate of Interest">
      </div>
      <div class="col-3">
        <label for="Scheme Name" class="form-label">Tenure (in Months)</label>
        <input type="text" class="form-control" name="tenure" formControlName="tenure" id="tenure" placeholder="Tenure">
      </div>
      <div class="col-3">
        <label for="Scheme Name" class="form-label">916 Price</label>
        <input type="text" class="form-control" name="nineone" formControlName="nineone" id="nineone" placeholder="916 Price">
      </div>
      <div class="col-3">
        <label for="Scheme Name" class="form-label">85 Price</label>
        <input type="text" class="form-control" name="eightyfive" formControlName="eightyfive" placeholder="85 Price">
      </div>
      <div class="col-3">
        <label for="Scheme Name" class="form-label">80 Price</label>
        <input type="text" class="form-control" name="eighty" formControlName="eighty" placeholder="80 Price">
      </div>
      <div class="col-3">
        <label for="Scheme Name" class="form-label">75 Price</label>
        <input type="text" class="form-control" name="seventyfive" formControlName="seventyfive" placeholder="75 Price">
      </div>
      <div class="col-3">
        <label for="Scheme Name" class="form-label">Silver Price</label>
        <input type="text" class="form-control" name="silver" formControlName="silver" placeholder="Silver Price">
      </div>
    </div>
    <div class="mt-3">
      <button type="button" class="btn btn-dark" (click)="onNoClick()">Close</button> &nbsp;
      <input type="submit" class="btn btn-primary" value="Save">
    </div>
  </form>
</div>