import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InsertDataService } from './../../../services/insert-data.service';
import { UpdateDataService } from './../../../services/update-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-locker',
  templateUrl: './locker.component.html',
  styleUrls: ['./locker.component.scss']
})

export class LockerComponent {
  formInputs: FormGroup;
  currentDateAndTime : any;
  navData : any;
  userData : any;
  constructor(
    public dialogRef: MatDialogRef<LockerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
    public addData: InsertDataService,
    private toastr: ToastrService,
    public updatedata : UpdateDataService
  ) {
    this.formInputs = this.formBuilder.group({
      locker: ['', [Validators.required]],
      lockno: [''],
    });
    this.navData = data;
    this.formInputs.controls['locker'].setValue(this.navData.prdData.locker)
    this.formInputs.controls['lockno'].setValue(this.navData.prdData.lockno)
  }
  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  setlockerInfo(ev:any){
    if(this.formInputs.controls['locker'].value == 0){
      this.formInputs.controls['lockno'].setValue("")
    }
  }
  onSubmit(): void {
    if (this.formInputs.valid) {
     let postData = this.navData.prdData;
      postData.locker = this.formInputs.value.locker.toString();
      postData.lockno = this.formInputs.value.lockno.toString();
      postData.id = this.navData.prdId;
      this.updatedata.updateLocker(postData).subscribe(res => {
        this.formInputs.reset();
        this.showToast("1");
        this.onNoClick();
      }, err => {
        this.showToast("0")
        console.log(err);
      })
    } else {
      this.formInputs.markAllAsTouched();
    }
}
  showToast(params: any) {
    if (params == '1') {
      this.toastr.success('Jwellels details updated!', 'Success!');
    }
    else {
      this.toastr.warning('Something went wrong, try again later', 'Oops!');
    }
  }
}
