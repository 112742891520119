import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { InsertDataService } from "./../../../services/insert-data.service";
import { UpdateDataService } from "./../../../services/update-data.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-products-form",
  templateUrl: "./products-form.component.html",
  styleUrls: ["./products-form.component.scss"],
})
export class ProductsFormComponent implements OnInit {
  formInputs: FormGroup;
  navData: any;
  constructor(
    public dialogRef: MatDialogRef<ProductsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
    public addData: InsertDataService,
    private toastr: ToastrService,
    public updatedata: UpdateDataService
  ) {
    this.formInputs = this.formBuilder.group({
      name: ["", [Validators.required, Validators.minLength(2)]],
    });
    this.navData = data.data;
  }
  ngOnInit(): void {
    if (this.navData && this.navData.id) {
      this.formInputs.controls["name"].setValue(this.navData.attributes.name);
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit(): void {
    if (this.formInputs.valid) {
      if (this.navData && this.navData.id) {
        if (this.formInputs.valid) {
          this.formInputs.value.id = this.navData.id;
          this.updatedata.updateProduct(this.formInputs.value).subscribe(
            (res) => {
              this.formInputs.reset();
              this.showToast("2");
              this.onNoClick();
            },
            (err) => {
              this.showToast("0");
              console.log(err);
            }
          );
        }
      } else {
        if (this.formInputs.valid) {
          this.addData.addProduct(this.formInputs.value).subscribe(
            (res) => {
              this.formInputs.reset();
              this.showToast("1");
              this.onNoClick();
            },
            (err) => {
              this.showToast("0");
              console.log(err);
            }
          );
        }
      }
    } else {
      this.formInputs.markAllAsTouched();
    }
  }
  showToast(params: any) {
    if (params == "1") {
      this.toastr.success("Product Added Succesfully", "Success!");
    } else if (params == "2") {
      this.toastr.success("Product Updated Succesfully", "Success!");
    } else {
      this.toastr.warning("Something went wrong, try again later", "Oops!");
    }
  }
}
