import { Component } from '@angular/core';
import { getNavItems } from './_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent {
  public navItems:any;
  public newNavItemes :any;
  roles:any;
  public isCalculatorVisible = false;

  constructor() {
    let role: any = localStorage.getItem("userPrivileges");
    this.roles = JSON.parse(role);
    this.navItems = getNavItems(this.roles);
  }

  toggleCalculator() {
    this.isCalculatorVisible = !this.isCalculatorVisible;
  }
}
