import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberToWordsService {

  constructor() { }
  numberToWords(num: number): string {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const thousands = ['', 'Thousand', 'Lakh', 'Crore'];
    const convertHundreds = (num: number): string => {
      let result = '';
      if (num > 99) {
        result += units[Math.floor(num / 100)] + ' Hundred ';
        num = num % 100;
      }
      if (num > 0) {
        if (num < 10) {
          result += units[num];
        } else if (num < 20) {
          result += teens[num - 10];
        } else {
          result += tens[Math.floor(num / 10)] + ' ';
          result += units[num % 10];
        }
      }

      return result.trim();
    };
    const convertThousands = (num: number, index: number): string => {
      if (num === 0) return '';
      return convertHundreds(num) + ' ' + thousands[index] + ' ';
    };
    if (num === 0) return 'Zero';
    let result = '';
    let thousandCounter = 0;
    while (num > 0) {
      let chunk = num % 1000;
      if (chunk > 0) {
        result = convertThousands(chunk, thousandCounter) + result;
      }
      thousandCounter++;
      num = Math.floor(num / 1000);
    }
    return result;
    // var res = result.trim() + ' Only';
    // return res;
  }
}
