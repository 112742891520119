import { Component } from '@angular/core';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent {
  screen: string = '';

  clearScreen() {
    this.screen = '';
  }

  deleteLast() {
    this.screen = this.screen.slice(0, -1);
  }

  appendCharacter(char: string) {
    this.screen += char;
  }

  calculateResult() {
    try {
      this.screen = eval(this.screen);
    } catch {
      this.screen = 'Error';
    }
  }
}
