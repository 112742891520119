import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { IconSetService } from "@coreui/icons-angular";
import { iconSubset } from "./icons/icon-subset";
import { Title } from "@angular/platform-browser";
import { AuthService } from "./services/auth.service";

@Component({
  selector: "app-root",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit {
  title = "Cashway Finance";
  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    private authService: AuthService
  ) {
    titleService.setTitle(this.title);
    iconSetService.icons = { ...iconSubset };
  }
  ngOnInit(): void {
    this.router.events.subscribe(async (evt) => {
      if (evt instanceof NavigationEnd) {
        const isLoggedIn = await this.authService.isLoggedIn();
        if (!isLoggedIn) {
          this.router.navigate(["login"]);
        }
      }
    });
  }
}
