<ng-template #userImg>
  <div class="py-3 px-4 col-12">
    <img class="" src="{{selectedImg}}" alt="Customer Photo" style="width: 100%; height: auto; max-width:400px;">
  </div>
</ng-template>
<div class="row">
  <div class="col-8">
    <c-card class="mb-4">
      <c-card-header>
        <h3 class="mb-0"><b>{{customerDetails?.sur}} {{customerDetails?.name}}</b>'s Loans</h3>
      </c-card-header>
      <c-card-body  *ngFor="let loanList of displayedData; let i = index;">
        <div class="row">
          <div class="col-3">
            Loan ID : {{loanList.id}}<br>
            <b>Date :</b> {{loanList.attributes.date}}
          </div>
          <div class="col-3">
            <b>Loan Amount</b><br>
            Rs.{{loanList.attributes.amount}}
          </div>
          <div class="col-2">
            <b>Scheme</b><br>
            {{loanList.attributes.scheme}}
          </div>
          <div class="col-4" *ngIf="loanList.attributes.isClosed != '1'">
            <button class="btn btn-dark" (click)="loanDetails(i)" style="width: 100%;" >View/Update Payment</button>
          </div>
          <div class="col-4 text-center" *ngIf="loanList.attributes.isClosed == '1'">
            <span class="closed-badge" (click)="loanDetails(i)">Closed</span>
          </div>
        </div>
      </c-card-body>
    </c-card>
  </div>
  <div class="col-4">
    <c-card class="mb-4">
      <c-card-header>
        <b>Customer Details</b>
      </c-card-header>
      <div class="row px-3 pt-3">
        <div class="col-12 pb-3">
          <b>Name</b>
          {{customerDetails?.sur}} {{customerDetails?.name}}
        </div>
        <div class="col-12 pb-3">
          <b>Care of</b>
          {{customerDetails?.co}}
        </div>
        <div class="col-12 pb-3">
          <b>Parents/Husband/Guardian Name</b><br>
          {{customerDetails?.fname}}
        </div>
        <div class="col-12 pb-3">
          <b>Address</b><br>
          {{customerDetails?.address}}
        </div>
        <div class="col-12 pb-3">
          <b>Contact No</b><br>
          {{customerDetails?.contact1}}<br>
          {{customerDetails?.contact2}}
        </div>
        <div class="col-12 pb-3">
          <b>Nominee</b>
          {{customerDetails?.nominee}}
        </div>
        <div class="col-12 pb-3">
          <b>Relation & Contact</b><br>
          {{customerDetails?.relation}}<br>
          {{customerDetails?.nomineeContact}}
        </div>
        <div class="col-12 pb-3">
          <b>Documents</b><br>
          <a (click)="enlargeImg(customerDetails?.photo)">Customer Image</a><br>
          <a (click)="enlargeImg(customerDetails?.proof)">Proof Image</a>
        </div>
        <div class="col-12 pb-3">
          <b>Date of Joining</b>
          {{customerDetails?.date}}
        </div>
      </div>
      <c-card-body>
      </c-card-body>
    </c-card>

  </div>
</div>