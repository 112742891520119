import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';


interface LoanData {
  amount: string;
  tenure: string;
  roi: string;
  date: string;
}

interface Payment {
  id: number;
  amount: string;
  isTotal: string;
  user: number;
  date: string;
  createdAt: string;
  uniqKey: string;
  mode: string;
}
interface PaymentData {
  id: number;
  principal: string;
  interest: string;
  penalty: string;
  amount: number;
  date: string;
  user: string;
  uniqKey: string;
  mode: string | null;
}

@Component({
  selector: 'app-closing',
  templateUrl: './closing.component.html',
  styleUrls: ['./closing.component.scss'],
  providers: [DatePipe]
})
export class ClosingComponent {
  formInputs: FormGroup;
  currentDateAndTime: any;
  principalAmount: any;
  totalInterestAccrued: any;
  totalAmountToBePaid: any;
  penalty: any;
  navData: any;
  userData: any;
  totalAmountToPay: any;
  loanDetails: any
  today = new Date();
  dateFilter = (date: Date | null): boolean => {
    if (date) {
      let tempDate = new Date(this.navData.loanData.date)
      tempDate.setDate(tempDate.getDate() - 1); 
      const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      const todayDate = new Date(tempDate);
      return currentDate >= todayDate;
    }
    return false;
  };
  constructor(
    public dialogRef: MatDialogRef<ClosingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.navData = data;
    this.formInputs = this.formBuilder.group({
      date: [new FormControl(), Validators.required]
    });
  }
  onSubmit() {
    const closingDate = this.formInputs.value.date;
    if (this.formInputs.valid) {
      this.calculateClosing(this.navData.loanData, closingDate, this.navData.paymentdata)
    }
  }

  getMonthsAndDays(loanData: LoanData, closingDate: any) {
    const dueDateArray = [];
    const monthlyInterestRate = parseInt(loanData.roi) / 12 / 100;
    const halfMonthlyInterest = parseFloat(loanData.amount) * (monthlyInterestRate / 2);
    const loanStartDate = new Date(loanData.date);
    
    for (let i = 0; i <= parseInt(loanData.tenure); i++) {
        const currentDueDate = new Date(loanStartDate);
        currentDueDate.setMonth(loanStartDate.getMonth() + i);
        const previousDueDate = new Date(loanStartDate);
        previousDueDate.setMonth(loanStartDate.getMonth() + i - 1);
        const daysElapsed = Math.ceil((currentDueDate.getTime() - previousDueDate.getTime()) / (1000 * 60 * 60 * 24));

        dueDateArray.push({
            emi: i + 1,
            startDate: previousDueDate.toISOString().split('T')[0],
            date: currentDueDate.toISOString().split('T')[0],
            days: daysElapsed,
            interest: (halfMonthlyInterest * 2).toFixed(2), // Ensure consistent interest for the full month
            penalty: 0,
            interestElapsed: 0,
            penaltyElapsed: 0,
            interestPaid: 0,
            penaltyPaid: 0,
        });
    }

    let totalCycles = 0;
    let monthsElapsed = 0;
    const currentDate = new Date(closingDate);

    dueDateArray.forEach((item) => {
        const itemDate = new Date(item.date);
        if (itemDate <= currentDate) {
            monthsElapsed++;
            const daysPassed = Math.ceil((currentDate.getTime() - itemDate.getTime()) / (1000 * 60 * 60 * 24));
            
            if (daysPassed <= 15) {
                totalCycles++;
                item.interestElapsed = parseFloat(halfMonthlyInterest.toFixed(2));
            } else {
                totalCycles += 2;
                item.interestElapsed = parseFloat((halfMonthlyInterest * 2).toFixed(2));
            }
        }
    });

    return { totalCycles, dueDateArray, monthsElapsed };
}

calculateClosing(loanData: LoanData, closingDate: any, paymentData: PaymentData[]) {
    const is31stLoan = (new Date(loanData.date).getDate() == 31);
    const annualInterestRate = parseFloat(loanData.roi);
    const monthlyInterestRate = annualInterestRate / 1200;
    const currentDateObj = new Date(closingDate);
    const Calcdata = this.getMonthsAndDays(loanData, closingDate);

    let pendingInterest = 0;
    let pendingPenalty = 0;
    let principalPaid = 0;
    let interestPaid = 0;
    let penaltyPaid = 0;
    let totalInterest = 0;
    let dueDate = '';
    let totalPenalty = 0;
    let tempInterestPaid = 0;

    const principalArray: { amount: string; date: string; }[] = [];
    const interestArray: { amount: string; date: string; }[] = [];

    paymentData.forEach(({ principal, interest, penalty, date }) => {
        interestPaid += parseFloat(interest) || 0;
        penaltyPaid += parseFloat(penalty) || 0;
        principalPaid += parseFloat(principal) || 0;

        if (principal != "0") {
            principalArray.push({ "amount": principal, "date": date.split(" ")[0] });
        }
        if (interest != "0") {
            interestArray.push({ "amount": interest, "date": date.split(" ")[0] });
        }
    });

    tempInterestPaid = interestPaid;

    Calcdata.dueDateArray.forEach((ele: any) => {
        const itemDate = new Date(ele.date);
        const daysPassed = Math.ceil((currentDateObj.getTime() - itemDate.getTime()) / (1000 * 60 * 60 * 24));
        let pendingPrincipal = parseFloat(loanData.amount);

        // Adjust principal based on previous payments
        principalArray.forEach((paidInfo: any) => {
            const paidDate = new Date(paidInfo.date);
            if (itemDate > paidDate) {
                pendingPrincipal -= parseFloat(paidInfo.amount);
            }
        });

        let paidMonthlyInterest = 0;

        // Deduct paid interest
        interestArray.forEach((paidInfo: any) => {
            const paidDate = new Date(paidInfo.date);
            if (itemDate > paidDate) {
                paidMonthlyInterest -= parseFloat(paidInfo.amount);
            }
        });

        const interest = pendingPrincipal * (monthlyInterestRate / 2);
        ele.interest = (interest * 2).toFixed(2);

        if (paidMonthlyInterest < ele.interest) {
            // Penalty logic for late payments
            if (itemDate.getTime() < currentDateObj.getTime() && daysPassed > 3) {
                const pendingInt = ele.interest - paidMonthlyInterest;
                ele.penaltyElapsed = (pendingInt * 0.001 * daysPassed).toFixed(2); // Proper penalty based on remaining interest
            }
        }

        if (itemDate.getTime() < currentDateObj.getTime()) {
            ele.interestElapsed = (daysPassed <= 15 ? interest : interest * 2).toFixed(2);
        }

        totalInterest += parseFloat(ele.interestElapsed);
        totalPenalty += parseFloat(ele.penaltyElapsed);
    });

    Calcdata.dueDateArray.forEach((ele: any) => {
        const itemDate = new Date(ele.date);
        if (itemDate.getTime() > currentDateObj.getTime()) {
            dueDate = ele.date;
            return;
        }
    });

    pendingInterest = totalInterest - interestPaid;
    pendingInterest = pendingInterest < 0 ? 0 : pendingInterest;

    pendingPenalty = totalPenalty - penaltyPaid;
    pendingPenalty = pendingPenalty < 0 ? 0 : pendingPenalty;

    let remainingPrincipal = parseFloat(loanData.amount) - principalPaid;

    const result = {
        interestDue: pendingInterest.toFixed(2),
        penaltyDue: pendingPenalty.toFixed(2),
        nextDueDate: dueDate,
        totalAmountPaid: paymentData.reduce((sum, payment:any) => sum + parseFloat(payment.amount), 0).toFixed(2),
        remainingPrincipal: remainingPrincipal.toFixed(2),
        tenure: loanData.tenure
    };

    this.totalAmountToPay = (pendingInterest + pendingPenalty + remainingPrincipal).toFixed(2);
    this.principalAmount = result.remainingPrincipal;
    this.totalInterestAccrued = result.interestDue;
    this.penalty = result.penaltyDue;
}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
