<div class="container-fluid px-0" id="bg-div">
    <div class="row justify-content-center">
        <div class="col-lg-7 col-12">
            <div class="card card0">
                <div class="d-flex" id="wrapper">
                    <!-- Sidebar -->
                    <div class="bg-light border-right" id="sidebar-wrapper">
                        <div class="sidebar-heading pt-5 pb-4 text-center">
                            <img src="assets/images/cashway.png" class="mb-2" style="height: auto; width: 100px;"><br>
                            Cashway Finance
                        </div>
                        <div class="list-group list-group-flush">
                            <a data-toggle="tab" id="tab1"
                                [ngClass]="isTab1 ? 'tabs list-group-item active1': 'tabs bg-light list-group-item'">
                                <div class="list-div my-2 py-3">
                                    <div class="fa fa-home"></div> &nbsp;&nbsp; Account
                                </div>
                            </a> <a data-toggle="tab" id="tab2"
                                [ngClass]="isTab2 ? 'tabs list-group-item active1': 'tabs bg-light list-group-item'">
                                <div class="list-div my-2 py-3">
                                    <div class="fa fa-credit-card"></div> &nbsp;&nbsp; Make Payment
                                </div>
                            </a>
                            <!-- <a data-toggle="tab" href="#menu3" id="tab3" class="tabs list-group-item bg-light">
                                <div class="list-div my-2">
                                    <div class="fa fa-qrcode"></div> &nbsp;&nbsp;&nbsp; Verify  <span
                                        id="new-label">NEW</span>
                                </div>
                            </a>  -->
                        </div>
                    </div> <!-- Page Content -->
                    <div id="page-content-wrapper">
                        <div class="row pt-3" id="border-btm">
                            <div class="col-8">
                                <div class="row justify-content-right">
                                    <div class="col-1">
                                    </div>
                                    <div class="col-10">
                                        <p class="mb-0 mt-4 text-right" style="font-size: 18px;
    font-weight: 700;">Make your payment</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row justify-content-center">
                            <div class="text-center" id="test">Verify</div>
                        </div> -->
                        <div class="tab-content">
                            <div id="menu1" [ngClass]="isTab1 ? 'tab-pane in active' : 'tab-pane'">
                                <div class="row justify-content-center">
                                    <div class="col-11">
                                        <div class="form-card">
                                            <div class="mt-4 mb-4 text-center text-heading">Verify your account</div>
                                            <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="input-group">
                                                            <input type="text" name="loanid" formControlName="loanid">
                                                            <label>Loan ID</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <input type="submit" value="Next" class="btn btn-primary py-3"
                                                            style="color:#fff;">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="menu2" [ngClass]="isTab2 ? 'tab-pane in active' : 'tab-pane'">
                                <!-- <div id="menu2" class="tab-pane in active"> -->
                                <div class="row justify-content-center">
                                    <div class="col-11">
                                        <div class="form-card">
                                            <div class="mt-4 mb-4 text-center  text-heading">You Account Details</div>
                                            <div class="row">
                                                <div class="col-12 pt-2 fs-16">
                                                    <b>Name / Mobile :</b> {{prtData?.customername}}
                                                </div>
                                                <div class="col-12 pt-2 fs-16">
                                                    <b>Loan Amount :</b> Rs.{{prtData?.amount}}
                                                </div>
                                                <div class="col-12 pt-2 fs-16">
                                                    <b>Due pending :</b> Rs. 400
                                                </div>
                                                <div class="col-12 pt-2 fs-16">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                                        <label class="form-check-label" for="flexRadioDefault1">
                                                          Pending anount Rs.400
                                                        </label>
                                                      </div>
                                                      <div class="form-check">
                                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                                        <label class="form-check-label" for="flexRadioDefault1">
                                                          Total Outstanding Due
                                                        </label>
                                                      </div>
                                                </div>
                                                <div class="col-12 pt-2 fs-16">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="aggree"
                                                            id="aggree" #aggree>
                                                        <label class="form-check-label" for="users">
                                                            I understand and aggree to the <a href="#"
                                                                target="_blank">terms and conditions</a> and <a href="#"
                                                                target="_blank">privacy policies</a>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="col-12 mt-4">
                                                    <button class="btn btn-success p-3"
                                                        style="text-align: center; width: 100%;">
                                                        Make Payment
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>