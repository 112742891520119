import { Component, ViewChild, TemplateRef, Renderer2 } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PaymentsFormComponent } from "../../forms/payments-form/payments-form.component";
import { LockerComponent } from "../../forms/locker/locker.component";
import { Location } from "@angular/common";
import { ClosingComponent } from "../closing/closing.component";
import { ActivatedRoute } from "@angular/router";
import { GetDataService } from "src/app/services/get-data.service";
import { CalculationService } from "src/app/services/calculation.service";
import { NumberToWordsService } from "src/app/services/number-to-words.service";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

interface Payment {
  id: number;
  amount: string;
  isTotal: string;
  user: number;
  date: string;
  createdAt: string;
  uniqKey: string;
  mode: string;
}

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
})
export class PaymentsComponent {
  loanid: any;
  paymentApiData: any;
  paymentData: any;
  loanData: any;
  selectedUserImg: any;
  touch: any;
  prdNow: any;
  products: any;
  custDtls: any;
  printDtls: any;
  paintPayment: any = [];
  totalAmountToPay: any;
  loanDetails: any = {
    interestDue: "0.00",
    penaltyDue: "0.00",
    nextDueDate: "",
    totalAmountPaid: "0.00",
    remainingPrincipal: "0.00",
    monthsElapsed: 0,
    tenure: "",
  };
  closinDate: any;
  roles: any;
  @ViewChild("dialogBox") dialogBox!: TemplateRef<any>;
  constructor(
    public dialog: MatDialog,
    private _location: Location,
    public route: ActivatedRoute,
    public getData: GetDataService,
    private renderer: Renderer2,
    private numberToWords: NumberToWordsService,
    public calculationService: CalculationService
  ) {
    this.custDtls = history.state.customer;
    this.loanid = history.state.id;
    this.loanData = history.state.data;
    this.products = history.state.products;
    this.touch = {
      nineone: 916,
      eightyfive: 85,
      eighty: 80,
      seventyfive: 75,
      silver: "silver",
    };
    this.printDtls = {
      billno: "",
      date: "",
      mode: "",
      amount: "",
      amountWord: "",
    };
  }
  ngOnInit() {
    this.getPaymentInfo();
    this.loanData.name = this.loanData.customername.split(":")[0];
    this.closinDate = "";
    let role: any = localStorage.getItem("userPrivileges");
    this.roles = JSON.parse(role);
  }
  formatDateToLocalISOString(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  mapPaymentData(data: any) {
    const result: any = {};
    data.forEach((item: any) => {
      if (!result[item.uniqKey]) {
        result[item.uniqKey] = {
          id: item.id,
          principal: "0",
          interest: "0",
          penalty: "0",
          amount: item.amount,
          date: item.date,
          user: item.user,
          uniqKey: item.uniqKey,
          mode: item.mode,
        };
      }
      if (item.isTotal === "0") {
        result[item.uniqKey].interest = parseFloat(item.amount).toFixed(2);
      } else if (item.isTotal === "1") {
        result[item.uniqKey].principal = parseFloat(item.amount).toFixed(2);
      } else if (item.isTotal === "2") {
        result[item.uniqKey].penalty = parseFloat(item.amount).toFixed(2);
      }
      result[item.uniqKey].amount =
        parseFloat(result[item.uniqKey].principal) +
        parseFloat(result[item.uniqKey].interest) +
        parseFloat(result[item.uniqKey].penalty);
    });
    return Object.values(result);
  }
  getPaymentInfo() {
    this.paymentData = [];
    this.getData.getPayments(this.loanid).subscribe(
      (res) => {
        this.paymentApiData = res;
        this.paymentApiData.data.map((ele: any) => {
          let tempData: Payment = {
            id: ele.id,
            amount: ele.attributes.amount,
            date: ele.attributes.date,
            user: ele.attributes.user,
            isTotal: ele.attributes.isTotal,
            createdAt: ele.attributes.createdAt,
            uniqKey: ele.attributes.uniqKey,
            mode: ele.attributes.mode,
          };
          this.paymentData.push(tempData);
          this.paintPayment = this.mapPaymentData(this.paymentData);
        });
        this.loanDetails = this.calculationService.calculatePendingAmounts(
          this.loanData,
          new Date(),
          this.paintPayment
        );
        this.closinDate =
          this.loanDetails.tenureTable[
            this.loanDetails.tenureTable.length - 1
          ].date;
      },
      (err) => console.log(err)
    );
  }
  isDateBigger(d1: any) {
    let dd1 = new Date(d1);
    let dd2 = new Date();
    if (dd1.getTime() > dd2.getTime()) {
      return true;
    } else {
      return false;
    }
  }
  addPayment() {
    const dialogRef = this.dialog.open(PaymentsFormComponent, {
      width: "30%",
      data: {
        data: {
          loanid: this.loanid,
          paymentData: this.loanDetails,
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getPaymentInfo();
    });
  }
  backClicked() {
    this._location.back();
  }
  updateLocker(id: any, index: any) {
    const dialogRef = this.dialog.open(LockerComponent, {
      width: "40%",
      data: {
        prdId: id,
        prdData: this.products[index].attributes,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getData.getLoanProducts(this.loanid).subscribe(
        (res) => {
          this.prdNow = res;
          this.products = this.prdNow.data;
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }
  enlargeImg(add: any) {
    this.selectedUserImg = add;
    this.dialog.open(this.dialogBox);
  }
  calculateClosing() {
    const dialogRef = this.dialog.open(ClosingComponent, {
      width: "30%",
      data: {
        loandId: this.loanid,
        loanData: this.loanData,
        paymentdata: this.paintPayment,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  prdtouch(val: string) {
    return this.touch[val];
  }
  downloadPdf(dtls: any): void {
    let number = parseInt(dtls.amount.toFixed(0));
    let words = this.numberToWords.numberToWords(number);
    this.printDtls.billno = dtls.id;
    this.printDtls.date = dtls.date.split(" ")[0];
    this.printDtls.mode = dtls.mode;
    this.printDtls.amount = dtls.amount;
    this.printDtls.amountWord = words;
    setTimeout(() => {
      const data = document.getElementById("print-ele");
      this.renderer.removeClass(data, "d-none");
      if (data) {
        html2canvas(data).then((canvas) => {
          const paperWidth = 76.2;
          const paperHeight = 230;
          const imgWidth = paperWidth;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const contentDataURL = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", [paperWidth, paperHeight]);
          const positionX = 0;
          const positionY = (paperHeight - imgHeight) / 2;
          pdf.addImage(
            contentDataURL,
            "PNG",
            positionX,
            positionY,
            imgWidth,
            imgHeight
          );
          pdf.save("payment_receipt_" + this.printDtls.billno + ".pdf");
        });
      }
      this.renderer.addClass(data, "d-none");
    }, 20);
  }
}
