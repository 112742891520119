<div class="container my-6 card-bg">
  <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
    <div class="row g-3">
      <div class="col-12">
        <h3 class="mb-0">Add Product</h3>
      </div>
      <div class="col-12">
        <label for="Product Name" class="form-label">Product Name</label>
        <input type="text" class="form-control" name="name" formControlName="name" id="name" placeholder="Product Name" aria-label="Product Name">
      </div>
      <!-- <div class="col-6">
        <label for="Price" class="form-label">Price</label>
        <input type="text" class="form-control" name="productPrice" formControlName="productPrice" id="price" placeholder="Price" aria-label="Price">
      </div> -->
    </div>
    <div class="mt-3">
      <button type="button" class="btn btn-dark" (click)="onNoClick()">Close</button> &nbsp;
      <input type="submit" class="btn btn-primary" value="Save">
    </div>
  </form>
</div>