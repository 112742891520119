<ng-template #dialogBox>
    <div class="py-3 px-4 col-12">
        <img class="" src="{{selectedUserImg}}" alt="Customer Photo"
            style="width: 100%; height: auto; max-width:400px;">
        <!-- <div class="">
      <button cButton color="dark" matDialogClose (click)="cancelDelete()">Close</button>&nbsp;
    </div> -->
    </div>
</ng-template>
<div class="container my-6 card-bg">
    <div class="justify-content-center">
        <div class="row">
            <div class="col-12">
                <h2>Create Loan Application</h2>
            </div>
        </div>
        <form [formGroup]="loanForm" (ngSubmit)="getFormData()">
            <div class="row">
                <div class="col-3 label-space">
                    <label class="form-label">Customer Name</label>
                    <input type="text" class="form-control" placeholder="Customer Name" formControlName="customername"
                        [matAutocomplete]="auto" (blur)="getCustId($event)" />
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let sVal of filteredCustomer | async" [value]="sVal">{{sVal}}</mat-option>
                    </mat-autocomplete>
                    <input type="hidden" formControlName="custId" />
                </div>
                <div class="col-2 label-space">
                    <label class="form-label">Scheme</label>
                    <select class="form-select" formControlName="scheme" (change)="selectedScheme($event)">
                        <option value="">-- Select Scheme --</option>
                        <option *ngFor="let scheme of schemeData">
                            {{scheme.attributes.name}}
                        </option>
                    </select>
                </div>
                <div class="col-2 label-space">
                    <label class="form-label">Paid Amount</label>
                    <input type="text" class="form-control" placeholder="Paid Amount" formControlName="pp"
                        (blur)="calculateCh()" />
                </div>
                <div class="col-2 pb-3 label-space">
                    <label class="form-label">Loan Amount</label>
                    <input type="text" class="form-control" placeholder="Loan Amount" formControlName="amount"
                        readonly="true" />
                </div>
                <div class="col-2 label-space">
                    <label class="form-label">Processing Fee</label>
                    <input type="text" class="form-control" placeholder="Processing Fee" formControlName="fee"
                        readonly="true" />
                </div>
                <div class="col-1 label-space">
                    <label class="form-label">Interest</label>
                    <input type="text" class="form-control" value="{{monthlyDue}}"
                        readonly="true" />
                </div>
                <div class="col-2 label-space">
                    <label class="form-label">Tenure(in months)</label>
                    <input type="text" class="form-control" placeholder="Tenure" readonly="true"
                        formControlName="tenure" />
                </div>
                <div class="col-2 label-space">
                    <label class="form-label">Interest(%)</label>
                    <input type="text" class="form-control" placeholder="Rate of Interest" readonly="true"
                        formControlName="roi" />
                </div>
                <div class="col-2 label-space">
                    <label class="form-label">Less(Round Off)</label>
                    <input type="text" class="form-control" placeholder="roundoff" formControlName="roundoff"
                        (blur)="calculateAmount()" />
                </div>
                <div class="col-2 label-space">
                    <label class="form-label">Amount</label>
                    <input type="text" class="form-control" placeholder="Amount" formControlName="tamount"
                        readonly="true" />
                </div>
                <div class="col-2 label-space">
                    <label class="form-label">Cash in Hand</label>
                    <input type="text" class="form-control" placeholder="Cash in Hand" formControlName="ch"
                        readonly="true" />
                </div>
                <div class="col-2 label-space">
                    <label class="form-label">Remarks</label>
                    <input type="text" class="form-control" placeholder="Remarks" formControlName="remarks" />
                </div>
                <div class="row">
                    <div class="col-1 pt-3">
                        <h3>Products</h3>
                    </div>
                    <div class="col-4 pt-2">
                        <button type="button" (click)="addNewProduct()" class="btn btn-sm btn-warning mt-2">+
                            Add row</button>
                    </div>
                </div>
                <div [formGroup]="productsFormGroup()">
                    <div formArrayName="productsArray">
                        <div *ngFor="let studentSub of productArray().controls; let j = index;">
                            <div [formGroupName]="j" class="row">
                                <div class="col-1 label-space">
                                    <label class="form-label">Photo</label>
                                    <div class="row">
                                        <div class="col-6">
                                            <button type="button" class="btn btn-sm btn-dark"
                                                (click)="openWebCam(j)"><svg cIcon name="cilCamera"></svg></button>
                                        </div>
                                        <div class="col-6">
                                            <button type="button" class="btn btn-sm btn-dark"
                                                (click)="enlargeImg(j)">
                                                <svg cIcon name="cilMap"></svg>
                                            </button>
                                            <input type="hidden" name="image" formControlName="image" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 label-space">
                                    <label class="form-label">Product Name</label>
                                    <select class="form-select" name="product" formControlName="product">
                                        <option value=""> Select Product</option>
                                        <option *ngFor="let prd of product">{{prd}}</option>
                                    </select>
                                </div>
                                <div class="col-2 label-space">
                                    <label class="form-label">Touch</label>
                                    <select class="form-select" name="touch" (change)="calculateAmount()"
                                        formControlName="touch">
                                        <option value="">Select Touch</option>
                                        <option value="nineone">916</option>
                                        <option value="eightyfive">85</option>
                                        <option value="eighty">80</option>
                                        <option value="seventyfive">75</option>
                                        <option value="silver">Silver</option>
                                    </select>
                                </div>
                                <div class="col-1 label-space">
                                    <label class="form-label">Weight</label>
                                    <input type="text" class="form-control" placeholder="Weight in grams"
                                        formControlName="weight" (blur)="weightChange(j)" />
                                </div>
                                <div class="col-1 label-space">
                                    <label class="form-label">Stone</label>
                                    <input type="text" class="form-control" placeholder="(-) Less"
                                        formControlName="less" (blur)="weightChange(j)" />
                                </div>
                                <div class="col-2 label-space">
                                    <label class="form-label">Final Weight</label>
                                    <input type="text" class="form-control" placeholder="Final Weight"
                                        formControlName="fweight" />
                                </div>
                                <div class="col-2 label-space">
                                    <label class="form-label">Description</label>
                                    <input type="text" class="form-control" placeholder="Description"
                                        formControlName="description" />
                                </div>
                                <div class="col-1 label-space btn__row__del">
                                    <label class="form-label">&nbsp;</label>
                                    <button type="button" *ngIf="productArray().length > 1"
                                        (click)="removeNewProduct(j)" class="btn btn-sm btn-danger"><svg cIcon
                                            name="cilTrash"></svg></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-sm btn-success mt-4 ms-2 mb-5">Proceed</button>
        </form>
    </div>
</div>