import { Component, ViewChild, TemplateRef, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentsFormComponent } from '../../forms/payments-form/payments-form.component';
import { LockerComponent } from '../../forms/locker/locker.component';
import { Location } from '@angular/common';
import { ClosingComponent } from '../closing/closing.component';
import { ActivatedRoute } from '@angular/router';
import { GetDataService } from 'src/app/services/get-data.service';
import { NumberToWordsService } from 'src/app/services/number-to-words.service';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from "pdfmake/build/vfs_fonts";
// import * as htmlToPdfmake from 'pdfmake/build';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DeleteDataService } from 'src/app/services/delete-data.service';

// (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

interface LoanData {
  amount: string;
  tenure: string;
  roi: string;
  date: string;
}

interface Payment {
  id: number;
  amount: string;
  isTotal: string;
  user: number;
  date: string;
  createdAt: string;
  uniqKey: string;
  mode: string;
}
interface PaymentData {
  id: number;
  principal: string;
  interest: string;
  penalty: string;
  amount: number;
  date: string;
  user: string;
  uniqKey: string;
  mode: string | null;
}

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent {
  loanid: any;
  paymentApiData: any;
  paymentData: any;
  loanData: any;
  selectedUserImg: any;
  touch: any;
  prdNow: any;
  products: any;
  printDtls: any;
  paintPayment: any = [];
  totalAmountToPay: any;
  loanDetails: any = {
    interestDue: '0.00',
    penaltyDue: '0.00',
    nextDueDate: '',
    totalAmountPaid: '0.00',
    remainingPrincipal: '0.00',
    monthsElapsed: 0,
    tenure: ''
  };

  @ViewChild('dialogBox') dialogBox!: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    private _location: Location,
    public route: ActivatedRoute,
    public getData: GetDataService,
    private renderer: Renderer2,
    private numberToWords: NumberToWordsService
  ) {
    this.loanid = history.state.id;
    this.loanData = history.state.data;
    this.products = history.state.products;
    this.touch = {
      "nineone": 916,
      "eightyfive": 85,
      "eighty": 80,
      "seventyfive": 75,
      "silver": "silver"
    };
    this.printDtls = {
      "billno": "",
      "date": "",
      "mode": "",
      "amount": "",
      "amountWord": ""
    }
  }

  ngOnInit() {
    this.getPaymentInfo();
    this.loanData.name = this.loanData.customername.split(":")[0];
  }

  getMonthsAndDays(loanData: LoanData) {
    const dueDateArray = [];
    const monthlyInterestRate = parseInt(loanData.roi) / 12 / 100;
    const halfMonthlyInterest = parseFloat(loanData.amount) * (monthlyInterestRate / 2);
    const loanStartDate = new Date(loanData.date);
    for (let i = 0; i <= parseInt(loanData.tenure); i++) {
      const currentDueDate = new Date(loanStartDate);
      currentDueDate.setMonth(loanStartDate.getMonth() + i);
      const previousDueDate = new Date(loanStartDate);
      previousDueDate.setMonth(loanStartDate.getMonth() + i - 1);
      const daysElapsed = Math.ceil((currentDueDate.getTime() - previousDueDate.getTime()) / (1000 * 60 * 60 * 24));
      const isoDate = currentDueDate.toISOString().split('T')[0];
      dueDateArray.push({
        date: isoDate,
        days: daysElapsed,
        interest: (halfMonthlyInterest * 2),
        penalty: 0,
        interestElapsed: 0,
        penaltyElapsed: 0,
        interestPaid: 0,
        penaltyPaid: 0,
      });
    }
    let totalCycles = 0;
    let monthsElapsed = 0;
    const currentDate = new Date();
    dueDateArray.forEach((item) => {
      const itemDate = new Date(item.date);
      if (itemDate < currentDate) {
        monthsElapsed++;
        const daysPassed = (currentDate.getTime() - itemDate.getTime()) / (1000 * 3600 * 24);
        if (daysPassed <= 15) {
          totalCycles++;
          item.interestElapsed = halfMonthlyInterest;
        } else {
          totalCycles += 2;
          item.interestElapsed = (halfMonthlyInterest * 2);
        }
      }
    });
    return { totalCycles, dueDateArray, monthsElapsed };
  }

  calculatePendingAmounts(loanData: LoanData, paymentData: PaymentData[]) {
    const annualInterestRate = parseFloat(loanData.roi);
    const monthlyInterestRate = annualInterestRate / 1200;
    const currentDateObj = new Date();
    const Calcdata = this.getMonthsAndDays(loanData);
    let pendingInterest = 0;
    let pendingPenalty = 0;
    let principalPaid = 0;
    let interestPaid = 0;
    let penaltyPaid = 0;
    let totalinterest = 0;
    let dueDate = '';
    let totalPenalty = 0;
    let principalArray: { amount: string; date: string; }[] = [];
    let interestArray: { amount: string; date: string; }[] = [];
    let tempInterestPaid = 0;
    paymentData.forEach(({ principal, interest, penalty, date }) => {
      interestPaid += parseFloat(interest) || 0;
      penaltyPaid += parseFloat(penalty) || 0;
      principalPaid += parseFloat(principal) || 0;
      if (principal != "0") {
        principalArray.push({ "amount": principal, "date": date.split(" ")[0] });
      }
      if (interest != "0") {
        interestArray.push({ "amount": interest, "date": date.split(" ")[0] });
      }
    });
    tempInterestPaid = interestPaid;
    Calcdata.dueDateArray.forEach((ele: any,i) => {
      const itemDate = new Date(ele.date);
      let pendingPrincipal = parseFloat(loanData.amount);
      let paidMonthlyInterest = 0;
      const daysPassed = (currentDateObj.getTime() - itemDate.getTime()) / (1000 * 3600 * 24);
      principalArray.forEach((paidInfo: any) => {
        const paidDate = new Date(paidInfo.date);
        if (itemDate.getTime() > paidDate.getTime()) {
          pendingPrincipal = pendingPrincipal - paidInfo.amount;
        }
      });
      for (let i = 0; i < interestArray.length; i++) {
        const paidInfo = interestArray[i];
        const paidDate = new Date(paidInfo.date);
        if (itemDate.getTime() > paidDate.getTime()) {
          paidMonthlyInterest -= parseFloat(paidInfo.amount);
          interestArray.splice(i, 1);
          i--;
        }
      }
      if(paidMonthlyInterest < ele.interest && i !=0){
        if (itemDate.getTime() < currentDateObj.getTime() && daysPassed > 3) {
          const pendingInt = ele.interest - paidMonthlyInterest;
          ele.penaltyElapsed = (pendingInt * 0.001 * daysPassed).toFixed(2);
        }
      }
      let interest = pendingPrincipal * (monthlyInterestRate / 2);
      ele.interest = (interest * 2).toFixed(2)
      if (itemDate.getTime() < currentDateObj.getTime()) {
        if (daysPassed <= 15) {
          ele.interestElapsed = interest.toFixed(2);
        } else {
          ele.interestElapsed = (interest * 2).toFixed(2);
        }
      }
    })
    Calcdata.dueDateArray.forEach((ele: any) => {
      const itemDate = new Date(ele.date);
      const daysPassed = (currentDateObj.getTime() - itemDate.getTime()) / (1000 * 3600 * 24);
      if (ele.interest < tempInterestPaid) {
        ele.interestPaid = ele.interest;
        tempInterestPaid -= ele.interest;
      } else {
        ele.interestPaid = tempInterestPaid;
        tempInterestPaid = 0;
      }
      ele.interestPaid = parseFloat(ele.interestPaid).toFixed(2);
    });

    Calcdata.dueDateArray.forEach((ele: any) => {
      totalinterest += parseFloat(ele.interestElapsed);
      totalPenalty += parseFloat(ele.penaltyElapsed);
    })
    for (let i = 0; i < Calcdata.dueDateArray.length; i++) {
      const ele = Calcdata.dueDateArray[i];
      const itemDate = new Date(ele.date);
      
      if (itemDate.getTime() > currentDateObj.getTime()) {
        dueDate = ele.date;
        break; // Exit the loop when the condition is met
      }
    }
    console.log(Calcdata.dueDateArray);
    
    pendingInterest = totalinterest - interestPaid;
    if(pendingInterest < 0){
      pendingInterest = 0;
    }
    pendingPenalty = totalPenalty - penaltyPaid;
    if(pendingPenalty < 0){
      pendingPenalty = 0;
    }
    let remainingPrincipal = parseFloat(loanData.amount) - principalPaid;
    const result = {
      interestDue: Math.abs(pendingInterest).toFixed(2),
      penaltyDue: Math.abs(pendingPenalty).toFixed(2),
      nextDueDate: dueDate,
      totalAmountPaid: paymentData.reduce((sum: any, payment: any) => sum + parseFloat(payment.amount), 0).toFixed(2),
      remainingPrincipal: remainingPrincipal.toFixed(2),
      tenure: loanData.tenure
    };
    this.totalAmountToPay = (pendingInterest + totalPenalty + remainingPrincipal).toFixed(2);
    this.loanDetails = result;
    return result;
  }

  formatDateToLocalISOString(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  mapPaymentData(data: any) {
    const result: any = {};
    data.forEach((item: any) => {
      if (!result[item.uniqKey]) {
        result[item.uniqKey] = {
          id: item.id,
          principal: "0",
          interest: "0",
          penalty: "0",
          amount: item.amount,
          date: item.date,
          user: item.user,
          uniqKey: item.uniqKey,
          mode: item.mode
        };
      }

      if (item.isTotal === "0") {
        result[item.uniqKey].interest = parseFloat(item.amount).toFixed(2);
      } else if (item.isTotal === "1") {
        result[item.uniqKey].principal = parseFloat(item.amount).toFixed(2);
      } else if (item.isTotal === "2") {
        result[item.uniqKey].penalty = parseFloat(item.amount).toFixed(2);
      }
      result[item.uniqKey].amount = parseFloat(result[item.uniqKey].principal) + parseFloat(result[item.uniqKey].interest) + parseFloat(result[item.uniqKey].penalty);
    });
    return Object.values(result);
  };


  getPaymentInfo() {
    this.paymentData = [];
    this.getData.getPayments(this.loanid).subscribe(res => {
      this.paymentApiData = res;
      this.paymentApiData.data.map((ele: any) => {
        let tempData: Payment = {
          id: ele.id,
          amount: ele.attributes.amount,
          date: ele.attributes.date,
          user: ele.attributes.user,
          isTotal: ele.attributes.isTotal,
          createdAt: ele.attributes.createdAt,
          uniqKey: ele.attributes.uniqKey,
          mode: ele.attributes.mode
        };
        this.paymentData.push(tempData);
        this.paintPayment = this.mapPaymentData(this.paymentData);
      });
      this.calculatePendingAmounts(this.loanData, this.paintPayment);
    }, err => console.log(err));
  }

  addPayment() {
    const dialogRef = this.dialog.open(PaymentsFormComponent, {
      width: '30%',
      data: {
        data: {
          loanid: this.loanid,
          paymentData: this.loanDetails
        }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPaymentInfo();
    });
  }

  backClicked() {
    this._location.back();
  }

  updateLocker(id: any, index: any) {
    const dialogRef = this.dialog.open(LockerComponent, {
      width: '40%',
      data: {
        prdId: id,
        prdData: this.products[index].attributes
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getData.getLoanProducts(this.loanid).subscribe(res => {
        this.prdNow = res;
        this.products = this.prdNow.data;
      }, err => {
        console.log(err);
      });
    });
  }

  enlargeImg(add: any) {
    this.selectedUserImg = add;
    this.dialog.open(this.dialogBox);
  }

  calculateClosing() {
    const dialogRef = this.dialog.open(ClosingComponent, {
      width: '20%',
      data: {
        loandId: this.loanid,
        loanData: this.loanData,
        paymentdata: this.paintPayment
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  prdtouch(val: string) {
    return this.touch[val];
  }
  downloadPdf(dtls: any): void {
    console.log(dtls);
    let number = parseInt(dtls.amount.toFixed(0));
    let words = this.numberToWords.numberToWords(number);
    this.printDtls.billno = dtls.id;
    this.printDtls.date = dtls.date.split(" ")[0];
    this.printDtls.mode = dtls.mode;
    this.printDtls.amount = dtls.amount;
    this.printDtls.amountWord = words;
    setTimeout(() => {
      const data = document.getElementById('print-ele');
      this.renderer.removeClass(data, "d-none");
      if (data) {
        html2canvas(data).then(canvas => {
          const paperWidth = 76.2;
          const paperHeight = 230;
          const imgWidth = paperWidth;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const contentDataURL = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', [paperWidth, paperHeight]);
          const positionX = 0;
          const positionY = (paperHeight - imgHeight) / 2;
          pdf.addImage(contentDataURL, 'PNG', positionX, positionY, imgWidth, imgHeight);
          pdf.save('payment_receipt_' + this.printDtls.billno + '.pdf');
        });
      }
      this.renderer.addClass(data, "d-none");
    }, 20)
  }
}
