<!--<c-footer>-->
  <div>
    <a href="#" target="_blank">Cashway Finance Pvt Ltd</a>
    <span> &copy; 2024</span>
  </div>
  <div class="ms-auto">
    Developed by
    <a href="http://thinkaside.com" target="_blank">
      <span> ThinkAside</span>
    </a>
  </div>
<!--</c-footer>-->
