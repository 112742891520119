<div class="container my-6 card-bg p-4">
    <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
        <div class="row g-3">
            <div class="col-12">
                <h3 class="mb-0">Check Closing</h3>
            </div>
            <div class="col-12">
                <mat-form-field appearance="fill">
                    <mat-label>Select Closing Date</mat-label>
                    <input matInput [matDatepicker]="picker" [matDatepickerFilter]="dateFilter" formControlName="date" readonly>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-12" *ngIf="principalAmount">
                <table>
                    <tr>
                        <td>Principal</td>
                        <td>: Rs.{{principalAmount}}</td>
                    </tr>
                    <tr>
                        <td>Total Interest</td>
                        <td>: Rs.{{totalInterestAccrued}}</td>
                    </tr>
                    <tr>
                        <td>Penalty</td>
                        <td>: Rs.{{penalty}}</td>
                    </tr>
                    <tr *ngIf="lateTenureInt != 0 || lateTenureInt != '0'">
                        <td>Interest After Tenure</td>
                        <td>: Rs.{{lateTenureInt}}</td>
                    </tr>
                    <tr>
                        <td>Total Amount to be Paid</td>
                        <td>: Rs.{{totalAmountToPay}}</td>
                    </tr>
                </table>
            </div>
        <div class="mt-3">
            <button type="button" class="btn btn-dark" (click)="onNoClick()">Close</button> &nbsp;
            <input type="submit" class="btn btn-primary" value="Check">
        </div>
        </div>
    </form>
</div>