<section class="h-100 gradient-form">
  <div class="container py-4 h-100 mb-2">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-xl-4">
        <div class="card rounded-3 text-black">
          <div class="row g-0">
            <div class="col-lg-12 mx-auto">
              <div class="card-body px-md-3 py-md-5 mx-md-2">
                <div class="text-center">
                  <img
                    src="../../../../assets/images/cashway.png"
                    style="width: 60px"
                    alt="logo"
                  />
                  <h4 class="mt-1 mb-3 pb-1">Sri Cashway Finance</h4>
                </div>

                <form [formGroup]="loginForm" (ngSubmit)="authUser()">
                  <p>Please login to your account</p>
                  <div class="form-outline mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Username"
                      formControlName="identifier"
                      name="identifier"
                    />
                    <label class="form-label">Username</label>
                  </div>
                  <div class="form-outline mb-3">
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      formControlName="password"
                      name="password"
                    />
                    <label class="form-label">Password</label>
                  </div>
                  <div class="text-center pt-1 mb-2 pb-1">
                    <button
                      type="submit"
                      class="btn btn-block fa-lg gradient-custom-2 px-4"
                      style="color: #fff"
                    >
                      Log in
                    </button>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-center pb-2"
                  >
                    <p class="mb-0 me-2">Developed by ThinkAside</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- 
<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="5">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form cForm>
                <img src="assets/images/logo.jpg" alt="Logo" class="mb-4" style="max-width: 50%;" />
                <h1>Login</h1>
                <p class="text-medium-emphasis">Sign In to your account</p> 
                <c-input-group class="mb-3">
                  <span cInputGroupText>
                    <svg cIcon name="cilUser"></svg>
                  </span>
                  <input autoComplete="username" cFormControl placeholder="Username" />
                </c-input-group>
                <c-input-group class="mb-4">
                  <span cInputGroupText>
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input
                    autoComplete="current-password"
                    cFormControl
                    placeholder="Password"
                    type="password"
                  />
                </c-input-group>
                <c-row>
                  <c-col xs="6">
                    <button cButton class="px-4" color="primary">
                      Login
                    </button>
                  </c-col>
                  <c-col class="text-right" xs="6">
                    <button cButton class="px-0" color="link">
                      Forgot password?
                    </button>
                  </c-col>
                </c-row>
              </form>
            </c-card-body>
          </c-card>
          <c-card [ngStyle]="{'width.%': 44}" class="text-white bg-primary py-5">
            <c-card-body class="text-center">
              <div>
                <h2>Sign up</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <button [active]="true" cButton class="mt-3" color="primary" routerLink="/register">
                  Register Now!
                </button>
              </div>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div> -->
