<div class="container my-6 card-bg">
    <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
      <div class="row g-3">
        <div class="col-12">
          <h3 class="mb-0">{{pageTitle}}</h3>
        </div>
        <div class="col-12">
          <label for="name" class="form-label">Role Title</label>
          <input type="text" class="form-control" name="Name" formControlName="Name" id="name" placeholder="Role Title" aria-label="Role's Name">
        </div>
        <div class="col-12 pt-2">
          <label for="name" class="form-label">Permissions</label>
        </div>
        <div class="col-3 mt-0">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="loans" id="loans" name="loans" formControlName="loans">
            <label class="form-check-label" for="loans">
              Loans
            </label>
          </div>
        </div>
        <div class="col-3 mt-0">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="payments" id="payments" name="payments" formControlName="payments">
            <label class="form-check-label" for="payments">
              Payments
            </label>
          </div>
        </div>
        <div class="col-3 mt-0">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="settings" id="settings" name="settings" formControlName="settings">
            <label class="form-check-label" for="settings">
              Schemes
            </label>
          </div>
        </div>
        <div class="col-3 mt-0">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="users" id="users" name="users" formControlName="users">
            <label class="form-check-label" for="users">
              Users
            </label>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button type="button" class="btn btn-dark" (click)="onNoClick()">Close</button> &nbsp;
        <input type="submit" class="btn btn-primary" value="Save">
      </div>
    </form>
  </div>
  