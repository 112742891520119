import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ProductsFormComponent } from "../../forms/products-form/products-form.component";
import { GetDataService } from "src/app/services/get-data.service";
import { DeleteDataService } from "src/app/services/delete-data.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit {
  displayedData: any;
  dtls: any;
  editData: any;
  deleteId: any;
  metaData: any = {};
  pageLen: number = 10;
  dataPage: number = 1;
  roles: any;
  @ViewChild("dialogBox") dialogBox!: TemplateRef<any>;
  constructor(
    public dialog: MatDialog,
    public getData: GetDataService,
    private toastr: ToastrService,
    public deleteData: DeleteDataService
  ) {}
  ngOnInit() {
    this.loadData();
    let role: any = localStorage.getItem("userPrivileges");
    this.roles = JSON.parse(role);
  }
  paginationChange(page: any) {
    if (page == "prev") {
      this.dataPage = this.dataPage - 1;
    } else if (page == "next") {
      this.dataPage = this.dataPage + 1;
    } else {
      this.dataPage = page.target.value;
    }
    this.loadData();
  }
  pageSize(event: any) {
    this.pageLen = event.target.value;
    this.loadData();
  }
  loadData() {
    this.getData.getProduct(this.pageLen, this.dataPage).subscribe(
      (res) => {
        this.dtls = res;
        this.metaData = this.dtls.meta.pagination;
        this.displayedData = this.dtls.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  addProduct() {
    const dialogRef = this.dialog.open(ProductsFormComponent, {
      width: "400px",
      data: {
        data: this.editData,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.editData = {};
      this.loadData();
    });
  }
  editProduct(product: any) {
    this.editData = product;
    this.addProduct();
  }
  cancelDelete() {
    this.deleteId = "";
    this.dialog.closeAll();
  }
  confirmDelete() {
    this.deleteData.deleteProduct(this.deleteId).subscribe(
      (res) => {
        this.deleteId = "";
        this.cancelDelete();
        this.loadData();
        this.showToast();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  showToast() {
    this.toastr.success("Product Deleted Succesfully", "Success!");
  }

  deleteProduct(product: any) {
    this.deleteId = product.id;
    this.dialog.open(this.dialogBox);
  }
}
