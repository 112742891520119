import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InsertDataService } from './../../../services/insert-data.service';
import { UpdateDataService } from './../../../services/update-data.service';
import { ToastrService } from 'ngx-toastr';
import { GetDataService } from 'src/app/services/get-data.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  formInputs: FormGroup;
  navData: any;
  regUserId: any;
  regpid: any;
  roles : any;
  branches:any;
  constructor(
    public dialogRef: MatDialogRef<UserFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private addDataService: InsertDataService,
    private getDataServices: GetDataService,
    private updateDataService: UpdateDataService,
    private toastr: ToastrService
  ) {
    this.formInputs = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      bid: ['', Validators.required],
      role: ['', Validators.required],
      username: ['', Validators.required],
    });
    this.navData = data.data;
    if(!this.navData.id){
      this.navData = null;
    }
  }

  ngOnInit(): void {
    this.getRoles();
    this.getBranch();
    if (this.navData && this.navData.id) {
      this.formInputs.patchValue({
        name: this.navData.attributes.name,
        username: this.navData.attributes.username,
        email: this.navData.attributes.email,
        mobile: this.navData.attributes.mobile,
        bid: this.navData.attributes.bid,
        role: this.navData.attributes.role,
      });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  getRoles(){
    this.getDataServices.getRoles().subscribe(res=>{
      this.roles = res;
      this.roles = this.roles.data;
    },err=>{console.log(err)})
  }
  getBranch(){
    this.getDataServices.getBranches(10,1).subscribe(res=>{
      this.branches = res;
      this.branches = this.branches.data;
    },err=>{console.log(err)})
  }
  onSubmit(): void {
    if (this.formInputs.valid) {
      if (this.formInputs.value.password !== this.formInputs.value.confirmPassword) {
        this.toastr.error('Password dosen\'t match', 'Form Invalid');
      } else {
          this.addUser();
      }
    } else if(this.navData && this.navData.id) {
      this.updateProfile();
    }
     else {
      this.toastr.error('Please fill in all required fields.', 'Form Invalid');
    }
  }

  addUser(): void {
    const registrationData = {
      username: this.formInputs.value.username,
      email: this.formInputs.value.email,
      password: this.formInputs.value.password,
    };
    this.addDataService.addUsers(registrationData).subscribe(
      (res) => {
        this.regUserId = res;
        this.insertProfileData();
      },
      (err) => {
        this.showToast('Something went wrong, try again later', 'Oops!');
        console.error(err);
      }
    );
  }
  insertProfileData() {
    const registrationData = {
      name: this.formInputs.value.name,
      email: this.formInputs.value.email,
      username: this.formInputs.value.username,
      role: this.formInputs.value.role,
      uid: this.regUserId.user.id,
      mobile: this.formInputs.value.mobile,
      bid: this.formInputs.value.bid,
    };
    this.addDataService.addProfile(registrationData).subscribe(
      () => {
        this.formInputs.reset();
        this.showToast('User Added Successfully', 'Success!');
        this.onNoClick();
      },
      (err) => {
        this.showToast('Something went wrong, try again later', 'Oops!');
        console.error(err);
      }
    );
  }
  updateProfile(): void {
    const registrationData = {
      id: this.navData.id,
      name: this.formInputs.value.name,
      email: this.formInputs.value.email,
      username: this.formInputs.value.username,
      role: this.formInputs.value.role,
      mobile: this.formInputs.value.mobile,
      bid: this.formInputs.value.bid,
    };
    this.updateDataService.updateUsers(registrationData).subscribe(
      () => {
        this.formInputs.reset();
        this.showToast('User Updated Successfully', 'Success!');
        this.onNoClick();
      },
      (err) => {
        this.showToast('Something went wrong, try again later', 'Oops!');
        console.error(err);
      }
    );
  }

  showToast(message: string, title: string): void {
    this.toastr.success(message, title);
  }
}
