import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UserRoleFormComponent } from "../../forms/user-role-form/user-role-form.component";
import { GetDataService } from "src/app/services/get-data.service";
import { DeleteDataService } from "src/app/services/delete-data.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.scss"],
})
export class RolesComponent implements OnInit {
  displayedData: any[] = [];
  dtls: any;
  editData: any;
  deleteId: string = "";
  metaData: any = {};
  pageLen: number = 10;
  dataPage: number = 1;

  @ViewChild("dialogBox") dialogBox!: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    public getData: GetDataService,
    private toastr: ToastrService,
    public deleteData: DeleteDataService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  paginationChange(page: any): void {
    if (page === "prev" && this.dataPage > 1) {
      this.dataPage--;
    } else if (page === "next" && this.dataPage < this.metaData.pageCount) {
      this.dataPage++;
    } else {
      this.dataPage = Number(page.target.value);
    }
    this.loadData();
  }

  pageSize(event: any): void {
    this.pageLen = Number(event.target.value);
    this.loadData();
  }

  loadData(): void {
    this.getData.getRoles().subscribe(
      (res) => {
        this.dtls = res;
        this.metaData = this.dtls.meta.pagination;
        this.displayedData = this.dtls.data;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  addProduct(): void {
    const dialogRef = this.dialog.open(UserRoleFormComponent, {
      width: "40%",
      data: {
        data: this.editData,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.editData = {};
      this.loadData();
    });
  }

  editProduct(product: any): void {
    this.editData = product;
    this.addProduct();
  }

  cancelDelete(): void {
    this.deleteId = "";
    this.dialog.closeAll();
  }

  confirmDelete(): void {
    if (!this.deleteId) return;

    this.deleteData.deleteRoles(this.deleteId).subscribe(
      () => {
        this.deleteId = "";
        this.cancelDelete();
        this.loadData();
        this.showToast();
      },
      (err) => {
        console.error(err);
      }
    );
  }

  showToast(): void {
    this.toastr.success("Role Deleted Successfully", "Success!");
  }

  deleteProduct(product: any): void {
    this.deleteId = product.id;
    this.dialog.open(this.dialogBox);
  }
}
