import { Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { GetDataService } from "./../../../services/get-data.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-diy-payment",
  templateUrl: "./diy-payment.component.html",
  styleUrls: ["./diy-payment.component.scss"],
})
export class DiyPaymentComponent {
  formInputs: FormGroup;
  isTab1: any;
  isTab2: any;
  accDtls: any;
  prtData: any;
  constructor(
    public formBuilder: FormBuilder,
    public getData: GetDataService,
    private toastr: ToastrService
  ) {
    this.formInputs = this.formBuilder.group({
      loanid: ["", [Validators.required]],
      // mobile: ['', [Validators.required]],
    });
    this.isTab1 = true;
    this.isTab2 = false;
  }
  onSubmit() {
    this.getData
      .getLoansForPayments(this.formInputs.controls["loanid"].value)
      .subscribe(
        (res) => {
          this.accDtls = res;
          if (this.accDtls.data.length > 0) {
            this.prtData = this.accDtls.data[0].attributes;
            this.isTab1 = false;
            this.isTab2 = true;
          } else {
            this.toastr.error("Invalid loan ID", "Invalid");
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
