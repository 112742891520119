import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GetDataService {
  url: string = environment.apiUrl;
  opts = { params: { populate: "*" } };
  len = "pagination[pageSize]="
  size = "&pagination[page]="
  constructor(public http: HttpClient) { }
  getCustomer(length: any, page: any) {
    return this.http.get(this.url + '/customers?' + this.len + length + this.size + page);
  }
  getProduct(length: any, page: any) {
    return this.http.get(this.url + '/products?' + this.len + length + this.size + page);
  }
  getSchemes(length: any, page: any) {
    return this.http.get(this.url + '/schemes?' + this.len + length + this.size + page);
  }
  getAllScheme() {
    return this.http.get(this.url + '/schemes');
  }
  getUsers(length: any, page: any) {
    return this.http.get(this.url + '/profiles?' + this.len + length + this.size + page);
  }
  getBranches(length: any, page: any) {
    return this.http.get(this.url + '/branches?' + this.len + length + this.size + page);
  }
  getBrancheName(id:any) {
    return this.http.get(this.url + '/branches/' + id);
  }
  getRoleName(id:any) {
    return this.http.get(this.url + '/privileges/' + id);
  }
  getRoles() {
    return this.http.get(this.url + '/privileges');
  }
  getPermissions(id:any) {
    return this.http.get(this.url + '/user-permissions?filters[role][$eq]='+id);
  }
  getPid(id:any) {
    return this.http.get(this.url + '/profiles?filters[uid][$eq]='+id);
  }
  getCustomerSearch() {
    return this.http.get(this.url + '/customers?fields[0]=name&fields[1]=contact1');
  }
  getProductSearch() {
    return this.http.get(this.url + '/products?fields[0]=name');
  }
  getLoans(id: any) {
    return this.http.get(this.url + '/loanmasters?filters[custId]=' + id + '&sort=createdAt:desc');
  }
  getCustomersId(id: any) {
    return this.http.get(this.url + '/customers?filters[id]=' + id);
  }
  getLoanProducts(id: any) {
    return this.http.get(this.url + '/loans?filters[loanid]=' + id);
  }
  getPayments(id: any) {
    return this.http.get(this.url + '/payments?filters[loanid]=' + id + '&sort=createdAt:desc&pagination[pageSize]=100');
  }
  getLoansForPayments(id:any){
    return this.http.get(this.url + '/loanmasters?filters[id]=' + id);
  }
}
