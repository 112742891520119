import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { InsertDataService } from "src/app/services/insert-data.service";
import { UpdateDataService } from "src/app/services/update-data.service";
import { GetDataService } from "src/app/services/get-data.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-user-role-form",
  templateUrl: "./user-role-form.component.html",
  styleUrls: ["./user-role-form.component.scss"],
})
export class UserRoleFormComponent implements OnInit {
  formInputs: FormGroup;
  navData: any;
  pageTitle: any;
  idvar: any;
  perId: any;
  selectedPermission: any;

  constructor(
    public dialogRef: MatDialogRef<UserRoleFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private addDataService: InsertDataService,
    private updateDataService: UpdateDataService,
    private getDataServices: GetDataService,
    private toastr: ToastrService
  ) {
    this.formInputs = this.formBuilder.group({
      Name: ["", Validators.required],
      makepayment: [],
      updatelocker: [],
      editcustomer: [],
      addcustomer: [],
      deletecustomer: [],
      editproduct: [],
      addproduct: [],
      deleteproduct: [],
      editscheme: [],
      addscheme: [],
      deletescheme: [],
      viewuser: [],
      adduser: [],
      edituser: [],
      deleteuser: [],
      createloan: [],
      renewloan: [],
    });

    this.navData = data.data;
    this.pageTitle = "Add User Role";
  }

  ngOnInit(): void {
    if (this.navData && this.navData.id) {
      this.pageTitle = "Update User Role";
      this.formInputs.patchValue({
        Name: this.navData.attributes.Name,
      });
      this.getPermissions(this.navData.id);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.formInputs.valid) {
      const formData = this.formInputs.value;
      if (this.navData && this.navData.id) {
        this.updateUser(formData);
      } else {
        this.addUser(formData);
      }
    } else {
      this.toastr.error("Please fill in all required fields.", "Form Invalid");
    }
  }

  setPermissions(data: any, id: any) {
    var postData = {
      makepayment: data.makepayment ? true : false,
      updatelocker: data.updatelocker ? true : false,
      editcustomer: data.editcustomer ? true : false,
      addcustomer: data.addcustomer ? true : false,
      deletecustomer: data.deletecustomer ? true : false,
      editproduct: data.editproduct ? true : false,
      addproduct: data.addproduct ? true : false,
      deleteproduct: data.deleteproduct ? true : false,
      editscheme: data.editscheme ? true : false,
      addscheme: data.addscheme ? true : false,
      deletescheme: data.deletescheme ? true : false,
      viewuser: data.viewuser ? true : false,
      adduser: data.adduser ? true : false,
      edituser: data.edituser ? true : false,
      deleteuser: data.deleteuser ? true : false,
      createloan: data.createloan ? true : false,
      renewloan: data.renewloan ? true : false,
      role: id.toString(),
    };
    this.addDataService.addPermissions(postData).subscribe(
      (res) => {
        this.formInputs.reset();
        this.showToast("User Role Added Successfully", "Success!");
        this.onNoClick();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getPermissions(id: any) {
    this.getDataServices.getPermissions(id).subscribe((res) => {
      this.selectedPermission = res;
      const currentData = this.selectedPermission.data[0].attributes;
      this.formInputs.patchValue({
        makepayment: currentData.makepayment,
        updatelocker: currentData.updatelocker,
        editcustomer: currentData.editcustomer,
        addcustomer: currentData.addcustomer,
        deletecustomer: currentData.deletecustomer,
        editproduct: currentData.editproduct,
        addproduct: currentData.addproduct,
        deleteproduct: currentData.deleteproduct,
        editscheme: currentData.editscheme,
        addscheme: currentData.addscheme,
        deletescheme: currentData.deletescheme,
        viewuser: currentData.viewuser,
        adduser: currentData.adduser,
        edituser: currentData.edituser,
        deleteuser: currentData.deleteuser,
        createloan: currentData.createloan,
        renewloan: currentData.renewloan,
      });
    });
  }

  getPermissionsId(postData: any, id: any) {
    this.getDataServices.getPermissions(id).subscribe((res) => {
      this.perId = res;
      postData.id = this.perId.data[0].id;
      this.updateDataService.updatePermissions(postData).subscribe(
        (res) => {
          this.formInputs.reset();
          this.showToast("User Role Updated Successfully", "Success!");
          this.onNoClick();
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  updatePermissions(data: any, id: any) {
    var postData = {
      makepayment: data.makepayment ? true : false,
      updatelocker: data.updatelocker ? true : false,
      editcustomer: data.editcustomer ? true : false,
      addcustomer: data.addcustomer ? true : false,
      deletecustomer: data.deletecustomer ? true : false,
      editproduct: data.editproduct ? true : false,
      addproduct: data.addproduct ? true : false,
      deleteproduct: data.deleteproduct ? true : false,
      editscheme: data.editscheme ? true : false,
      addscheme: data.addscheme ? true : false,
      deletescheme: data.deletescheme ? true : false,
      viewuser: data.viewuser ? true : false,
      adduser: data.adduser ? true : false,
      edituser: data.edituser ? true : false,
      deleteuser: data.deleteuser ? true : false,
      createloan: data.createloan ? true : false,
      renewloan: data.renewloan ? true : false,
    };
    this.getPermissionsId(postData, id.toString());
  }

  addUser(formData: any): void {
    if (this.formInputs.valid) {
      this.addDataService.addRole({ Name: formData.Name }).subscribe(
        (res) => {
          this.idvar = res;
          this.setPermissions(formData, this.idvar.data.id);
        },
        (err) => {
          this.showToast("Something went wrong, try again later", "Oops!");
          console.error(err);
        }
      );
    } else {
      this.formInputs.markAllAsTouched();
    }
  }

  updateUser(formData: any): void {
    var postdata = {
      Name: formData.Name,
      id: this.navData.id,
    };
    this.updateDataService.updateRoles(postdata).subscribe(
      () => {
        this.updatePermissions(formData, this.navData.id);
      },
      (err) => {
        this.showToast("Something went wrong, try again later", "Oops!");
        console.error(err);
      }
    );
  }

  showToast(message: string, title: string): void {
    this.toastr.success(message, title);
  }
}
