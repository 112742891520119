import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    iconComponent: { name: 'cil-speedometer' }
  },
  {
    name: 'Products',
    url: '/products',
    iconComponent: { name: 'cil-star' }
  },
  {
    name: 'Customers',
    url: '/customers',
    iconComponent: { name: 'cil-people' },
  },
  {
    name: 'Create Loan',
    url: '/loans',
    iconComponent: { name: 'cil-tags' }
  },
  {
    name: 'Schemes',
    url: '/schemes',
    iconComponent: { name: 'cil-notes' }
  },
  {
    name: 'Users',
    url: '/Users',
    iconComponent: { name: 'cil-list' }
  },
  {
    name: 'Branch',
    url: '/Branch',
    iconComponent: { name: 'cil-credit-card' }
  },
  {
    name: 'Users privileges',
    url: '/preveliges',
    iconComponent: { name: 'cil-code' }
  }
];
