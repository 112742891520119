<ng-template #dialogBox>
  <div class="py-3 px-4 col-12">
    <h2 matDialogTitle>Are you Sure ?</h2>
    <p matDialogContent>Do you want to delete?</p>
    <hr />
    <div class="float-right">
      <button cButton color="dark" matDialogClose (click)="cancelDelete()">
        Cancel</button
      >&nbsp;
      <button cButton color="danger" matDialogClose (click)="confirmDelete()">
        Confirm
      </button>
    </div>
  </div>
</ng-template>
<c-card class="mb-4">
  <c-card-body>
    <c-row>
      <c-col md="10">
        <h3><b>User Roles & Privileges Details </b></h3>
      </c-col>
      <c-col class="mb-2">
        <button (click)="addProduct()" cButton color="primary">
          Add User Roles
        </button>
      </c-col>
      <c-col xs="12">
        <table cTable>
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <!-- <th scope="col">Price</th> -->
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of displayedData; let i = index">
              <th ope="row">{{ i + 1 }}</th>
              <td>{{ product.attributes.Name }}</td>
              <!-- <td>{{ product.attributes.price }}</td> -->
              <td class="action-buttons" style="width: 20%">
                <button
                  cButton
                  color="warning"
                  class="py-1"
                  (click)="editProduct(product)"
                >
                  <svg cIcon name="cilPencil"></svg></button
                >&nbsp;
                <button
                  cButton
                  color="danger"
                  class="py-1"
                  (click)="deleteProduct(product)"
                >
                  <svg cIcon name="cilTrash"></svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </c-col>
    </c-row>
  </c-card-body>
</c-card>
