<div class="container my-6 card-bg">
  <form [formGroup]="formInputs" (ngSubmit)="onSubmit()">
    <div class="row g-3">
      <div class="col-12">
        <h3 class="mb-0">Make Payment</h3>
      </div>
      <div class="col-6">
        <label class="form-label">Amount</label>
        <input type="text" class="form-control" name="amount" formControlName="amount" placeholder="Amount">
      </div>
      <div class="col-6">
        <label class="form-label">Payment Mode</label>
        <select class="form-select" name="mode" formControlName="mode">
          <option value="">--Select--</option>
          <option value="Cash">Cash</option>
          <option value="UPI">UPI</option>
          <option value="Bank">Bank</option>
          <option value="Cheque/DD">Cheque/DD</option>
        </select>
      </div>
    </div>
    <div class="mt-3">
      <button type="button" class="btn btn-dark" (click)="onNoClick()">Close</button> &nbsp;
      <input type="submit" class="btn btn-primary" value="Save">
    </div>
  </form>
</div>