<ng-template #dialogBox>
  <div class="py-3 px-4 col-12">
    <h2 matDialogTitle>Are you Sure ?</h2>
    <p matDialogContent>Do you want to delete?</p>
    <hr />
    <div class="float-right">
      <button cButton color="dark" matDialogClose (click)="cancelDelete()">
        Cancel</button
      >&nbsp;
      <button cButton color="danger" matDialogClose (click)="confirmDelete()">
        Confirm
      </button>
    </div>
  </div>
</ng-template>
<c-card class="mb-4">
  <c-card-body>
    <c-row>
      <c-col md="10">
        <h3><b>Scheme Details </b></h3>
      </c-col>
      <c-col class="mb-2">
        <button (click)="addSchemes()" cButton color="primary" *ngIf="roles.addscheme">
          Add Scheme
        </button>
      </c-col>
      <c-col xs="12">
        <table cTable>
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">RoI</th>
              <th scope="col">Tenure</th>
              <th scope="col">916 Price</th>
              <th scope="col">85 Price</th>
              <th scope="col">80 Price</th>
              <th scope="col">75 Price</th>
              <th scope="col">Silver Price</th>
              <th scope="col" *ngIf="roles.editscheme || roles.deletescheme">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let scheme of displayedData; let i = index">
              <th ope="row">{{ i + 1 }}</th>
              <td>{{ scheme.attributes.name }}</td>
              <td>{{ scheme.attributes.roi }} %</td>
              <td>{{ scheme.attributes.tenure }}</td>
              <td>Rs.{{ scheme.attributes.nineone }}</td>
              <td>Rs.{{ scheme.attributes.eightyfive }}</td>
              <td>Rs.{{ scheme.attributes.eighty }}</td>
              <td>Rs.{{ scheme.attributes.seventyfive }}</td>
              <td>Rs.{{ scheme.attributes.silver }}</td>
              <td class="action-buttons" *ngIf="roles.editscheme || roles.deletescheme">
                <button
                  cButton
                  color="warning"
                  class="py-1"
                  (click)="editSchemes(scheme)"
                  *ngIf="roles.editscheme"
                >
                  <svg cIcon name="cilPencil"></svg></button
                >&nbsp;
                <button
                  cButton
                  color="danger"
                  class="py-1"
                  (click)="deleteScheme(scheme)"
                  *ngIf="roles.deletescheme"
                >
                  <svg cIcon name="cilTrash"></svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-4 row">
            <div class="col-6 pt-4 pb-2">Rows per Page</div>
            <div class="col-4 pt-4 pb-2">
              <select class="form-select" (change)="pageSize($event)">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>75</option>
                <option>100</option>
              </select>
            </div>
          </div>
          <div class="text-end offset-5 col-3 pt-4 pb-2 row">
            <div class="col-4">
              <span *ngIf="dataPage > 1"
                ><button
                  class="btn btn-light"
                  (click)="paginationChange('prev')"
                >
                  Prev
                </button>
              </span>
            </div>
            <div class="col-2 p-0">
              <input
                type="text"
                value="{{ dataPage }}"
                class="form-control"
                style="width: 40px"
                (blur)="paginationChange($event)"
              />
            </div>
            <div class="col-2 p-0 fs-18">
              / <b>{{ metaData.pageCount }}</b>
            </div>
            <div class="col-4">
              <span *ngIf="metaData && dataPage < metaData.pageCount"
                ><button
                  class="btn btn-light"
                  (click)="paginationChange('next')"
                >
                  Next
                </button></span
              >
            </div>
          </div>
        </div>
      </c-col>
    </c-row>
  </c-card-body>
</c-card>
