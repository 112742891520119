import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { FormArray, FormControl, Validators, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { GetDataService } from "src/app/services/get-data.service";
import { InsertDataService } from "src/app/services/insert-data.service";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { WebCamComponent } from "../../web-cam/web-cam.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-loan",
  templateUrl: "./loan.component.html",
  styleUrls: ["./loan.component.scss"],
})
export class LoanComponent implements OnInit {
  loanForm: FormGroup;
  custCtrl = new FormControl();
  prdCtrl = new FormControl();
  amount: any;
  customer: string[];
  product: string[];
  loanId: any;
  schemeData: any;
  loanObj: any;
  filteredCustomer: Observable<string[]>;
  schnme: any;
  filteredProduct: Observable<string[]>[] = [];
  prdName: any;
  cusName: any;
  customerDetails: any = [];
  localSet: any;
  userData: any;
  selectedUserImg: any;
  currentDateAndTime: any;
  monthlyDue: any = 0.0;
  @ViewChild("dialogBox") dialogBox!: TemplateRef<any>;
  @ViewChild("confirmPledge") confirmPledge!: TemplateRef<any>;
  constructor(
    public getdata: GetDataService,
    public insertData: InsertDataService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    public dialog: MatDialog
  ) {
    this.localSet = {
      roi: "",
      tenure: "",
      nineone: "",
      eighty: "",
      eightyfive: "",
      seventyfive: "",
      silver: "",
    };
    this.userData = localStorage.getItem("userData");
    this.getCusomer();
    this.getProduct();
    this.getScheme();
    this.loanForm = new FormGroup({
      customername: new FormControl("", [Validators.required]),
      custId: new FormControl("", [Validators.required]),
      amount: new FormControl(""),
      scheme: new FormControl("", [Validators.required]),
      tenure: new FormControl(""),
      roi: new FormControl(""),
      fee: new FormControl(""),
      tamount: new FormControl(""),
      roundoff: new FormControl(""),
      interest: new FormControl(""),
      remarks: new FormControl(""),
      date: new FormControl(""),
      pp: new FormControl(""),
      ch: new FormControl(""),
      image: new FormControl("", [Validators.required]),
      products: new FormGroup({
        productsArray: new FormArray([this.putNewProduct()]),
      }),
    });
    this.filteredCustomer = this.loanForm.controls[
      "customername"
    ].valueChanges.pipe(
      startWith(""),
      map((value) => {
        const filterValue = this._normalizeValue(value);
        return this.customer.filter((customer) =>
          this._normalizeValue(customer).includes(filterValue)
        );
      })
    );
    this.customer = [];
    this.product = [];
  }

  getMonthlyInst() {
    let amount = parseFloat(this.loanForm.controls["amount"].value);
    let annualInterestRate = parseFloat(this.loanForm.controls["roi"].value);
    let tenure = parseInt(this.loanForm.controls["tenure"].value);
    let monthlyInterestRate = annualInterestRate / 12;
    let monthlyInterest = (amount * monthlyInterestRate) / 100;
    this.monthlyDue = monthlyInterest.toFixed(0);
  }

  getCustId(ev: any) {
    let cusArray = this.loanForm.value.customername.split(" : ");
    if (cusArray.length > 1) {
      this.customerDetails.forEach((ele: any) => {
        if (ele.name == cusArray[0]) {
          this.loanForm.controls["custId"].setValue(ele.id);
        }
      });
    }
  }
  ngOnInit(): void {
    this.currentDateAndTime = this.datePipe.transform(
      new Date(),
      "yyyy-MM-dd HH:mm:ss"
    );
  }
  private _normalizeValue(value: any): string {
    return value.toLowerCase().replace(/\s/g, "");
  }
  getCusomer() {
    this.getdata.getCustomerSearch().subscribe(
      (res) => {
        this.cusName = res;
        this.cusName.data.map((el: any) => {
          let tempObj = el.attributes;
          tempObj.id = el.id;
          this.customerDetails.push(tempObj);
        });
        this.cusName.data.forEach((ele: any) => {
          let cust = ele.attributes.name + " : " + ele.attributes.contact1;
          this.customer.push(cust);
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getProduct() {
    this.getdata.getProductSearch().subscribe(
      (res) => {
        this.prdName = res;
        this.prdName.data.forEach((ele: any) =>
          this.product.push(ele.attributes.name)
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }
  putNewProduct() {
    return new FormGroup({
      product: new FormControl("", [Validators.required]),
      touch: new FormControl("", [Validators.required]),
      weight: new FormControl("", [Validators.required]),
      less: new FormControl(""),
      fweight: new FormControl(""),
      description: new FormControl(""),
    });
  }
  weightChange(index: any) {
    let thisData = this.productArray().at(index);
    let weight = thisData.get("weight")?.value;
    let less = thisData.get("less")?.value;
    if (less == "") {
      less = 0;
    }
    let fweight = parseFloat(weight) - parseFloat(less);
    thisData.get("fweight")?.setValue(fweight.toFixed(2));
    this.calculateAmount();
  }
  calculateCh() {
    let pp = this.loanForm.controls["pp"].value;
    let tamount = this.loanForm.controls["tamount"].value;
    pp == "" ? (pp = 0) : "";
    tamount == "" ? (tamount = 0) : "";
    let ch = parseFloat(tamount) - parseFloat(pp);
    this.loanForm.controls["ch"].setValue(ch.toFixed(0));
  }
  calculateAmount() {
    this.amount = 0;
    let tamount = 0;
    let fee = 0.0;
    let thisData = this.productArray().value;
    let roundoff = this.loanForm.controls["roundoff"].value;
    if (roundoff == "") {
      roundoff = 0;
    }
    thisData.forEach((ele: any) => {
      if (ele.fweight !== "") {
        this.amount =
          parseFloat(this.amount) +
          parseFloat(this.localSet[ele.touch]) * parseFloat(ele.fweight);
      }
    });
    let tempFee = parseFloat(this.amount) + parseFloat(roundoff);
    fee = tempFee * (0.5 / 100);
    tamount = tempFee - fee;
    this.loanForm.controls["amount"].setValue(tempFee.toFixed(2));
    this.loanForm.controls["fee"].setValue(fee.toFixed(0));
    this.loanForm.controls["tamount"].setValue(tamount.toFixed(0));
    this.calculateCh();
    this.getMonthlyInst();
  }
  selectedScheme(ev: any) {
    let tempScheme = ev.target.value;
    this.schemeData.forEach((ele: any) => {
      if (ele.attributes.name == tempScheme) {
        this.localSet = ele.attributes;
      }
    });
    this.loanForm.controls["tenure"].setValue(this.localSet.tenure);
    this.loanForm.controls["roi"].setValue(this.localSet.roi);
  }
  productsFormGroup() {
    return this.loanForm.get("products") as FormGroup;
  }
  productArray() {
    return this.productsFormGroup().get("productsArray") as FormArray;
  }
  addNewProduct() {
    this.productArray().push(this.putNewProduct());
  }
  removeNewProduct(j: number) {
    this.productArray().removeAt(j);
  }
  getScheme() {
    this.getdata.getAllScheme().subscribe(
      (res) => {
        this.schnme = res;
        this.schemeData = this.schnme.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  validateAllFormFields(formGroup: FormGroup | FormArray): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.validateAllFormFields(control);
      }
    });
  }
  getFormData() {
    // if (this.loanForm.valid) {
    let tempLoanFormData = JSON.parse(JSON.stringify(this.loanForm.value));
    const req = {
      customername: tempLoanFormData.customername,
      amount: tempLoanFormData.amount.toString(),
      scheme: tempLoanFormData.scheme,
      tenure: tempLoanFormData.tenure.toString(),
      roi: tempLoanFormData.roi.toString(),
      remarks: tempLoanFormData.remarks,
      custId: tempLoanFormData.custId.toString(),
      tamount: tempLoanFormData.tamount.toString(),
      fee: tempLoanFormData.fee.toString(),
      roundoff: tempLoanFormData.roundoff.toString(),
      user: JSON.parse(this.userData).id.toString(),
      image: tempLoanFormData.image,
      date: this.currentDateAndTime,
    };
    this.insertData.addLoan(req).subscribe(
      (res) => {
        this.loanObj = res;
        this.loanId = this.loanObj.data.id;
        tempLoanFormData.products.productsArray.forEach(async (ele: any) => {
          ele.loanid = this.loanId;
          await this.insertData.addProducts(ele).subscribe(
            (nres) => {
              this.loanForm.reset();
              this.showToast("1");
            },
            (nerr) => {
              console.log(nerr);
              this.showToast("2");
            }
          );
        });
      },
      (err) => {
        console.log(err);
        this.showToast("2");
      }
    );
  }

  showToast(params: any) {
    if (params == "1") {
      this.toastr.success("Loan created Succesfully", "Success!");
    } else {
      this.toastr.warning("Something went wrong, try again later", "Oops!");
    }
  }
  openWebCam() {
    const dialogRef = this.dialog.open(WebCamComponent, {
      width: "950px",
      height: "450px",
      data: {
        data: 0,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      const productsArray = this.loanForm.get(
        "products.productsArray"
      ) as FormArray;
      this.loanForm.controls["image"].setValue(result.image);
    });
  }
  enlargeImg() {
    this.selectedUserImg = this.loanForm.get("image")?.value;
    this.dialog.open(this.dialogBox);
  }
  confirmForm() {
    if (this.loanForm.valid) {
      this.dialog.open(this.confirmPledge);
    } else {
      this.loanForm.markAllAsTouched();
    }
  }
  getImageUrl(): string {
    return this.loanForm.get("image")?.value || "default-image-url";
  }
  closeForm() {
    this.dialog.closeAll();
  }
  isValidImage(): boolean {
    const imageUrl = this.loanForm.get("image")?.value;
    return !!imageUrl && imageUrl.trim().length > 0;
  }
}
