<style>
  td {
    border: 1px solid #000;
    padding: 5px 10px;
  }
</style>
<table style="width: 100%; border: 1px solid #000" cellspacing="0">
  <tr>
    <td colspan="13" style="text-align: center; border-bottom: 0px">
      <img
        src="https://sricashway.com/wp-content/uploads/2024/05/sri-cashway-name-1280-X-180.png"
        style="height: auto; width: 900px"
      />
      <br />
      No-133, Bharathiyar Road, Maniyakarampalayam, Ganapathy,
      Coimbatore-641106. <br />
      L. No. 29/2022-2023, Call: 96299 33336, 98434 86662,
      <a style="color: #005fa7">(Gpay No: 98622 44014)</a>
    </td>
  </tr>
  <tr>
    <td
      colspan="2"
      style="text-align: center; border-top: 0px; border-right: 0px"
    ></td>
    <td
      colspan="9"
      style="text-align: center; border-top: 0px; border-left: 0px"
    >
      பணம் தேவையா? நகையை விற்க தேவையில்லை!
    </td>
    <td style="text-align: center; border-top: 2px solid #000">HC</td>
    <td style="text-align: center; border-top: 2px solid #000">
      {{ navData.attributes.fee }}
    </td>
  </tr>
  <tr>
    <td colspan="3" rowspan="2"><b>LOAN NO</b> : {{ navData.id }}</td>
    <td colspan="2" style="text-align: center">
      <b>Date</b> : {{ filterDate(navData.attributes.date, "D") }}
    </td>
    <td
      colspan="4"
      rowspan="2"
      style="
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: #fff;
        background-color: #a4a500;
      "
    >
      {{ navData.attributes.tenure }} Month Plan
    </td>
    <td colspan="2" style="text-align: center">
      <span *ngIf="navData.attributes.scheme != 'Silver'"
        >Gold Value Approx</span
      >
      <span *ngIf="navData.attributes.scheme == 'Silver'"
        >Silver Value Approx</span
      >
    </td>

    <td colspan="2" style="text-align: center">
      <b>Loan Amount</b> : Rs.{{ navData.attributes.amount }}
    </td>
  </tr>
  <tr>
    <td colspan="2" style="text-align: center">
      <b>Time</b> : {{ filterDate(navData.attributes.date, "T") }}
    </td>
    <td colspan="2" style="text-align: center">
      Rs.{{ getApproxAmt(navData.attributes.amount) }}
    </td>
    <td colspan="3" style="text-align: center">
      <b>Closing Date</b> :
      {{ getClosing(navData.attributes.date, navData.attributes.tenure) }}
    </td>
  </tr>
  <tr>
    <td colspan="11">
      <b>Address</b> <br />
      {{ custData.address }}
    </td>
    <td colspan="3" rowspan="9" style="width: 20%; text-align: center">
      <img [src]="custData.photo" style="width: 80%; height: auto" />
      <img
        [src]="navData.attributes.image"
        class="mt-1"
        style="width: 80%; height: auto"
      />
    </td>
  </tr>
  <tr>
    <td colspan="4">
      Contact : {{ custData.contact1 }}, {{ custData.contact2 }}
    </td>
    <td colspan="7">
      {{ getProofType(custData.proofType) }} No : {{ custData.proofno }}
    </td>
  </tr>
  <tr>
    <td colspan="4">Nominee Name : {{ custData.nominee }}</td>
    <td colspan="3">Relation: {{ custData.relation }}</td>
    <td colspan="4">Contact: {{ custData.nomineeContact }}</td>
  </tr>
  <!-- Asset Details starts -->
  <tr style="font-weight: bold; text-align: center">
    <td>
      <span *ngIf="navData.attributes.scheme != 'Silver'">GOLD DETAILS</span>
      <span *ngIf="navData.attributes.scheme == 'Silver'">SILVER DETAILS</span>
    </td>
    <td>Nos</td>
    <td>Wt/g</td>
    <td>Tou</td>
    <td>S Wt</td>
    <td>
      <span *ngIf="navData.attributes.scheme != 'Silver'">GOLD DETAILS</span>
      <span *ngIf="navData.attributes.scheme == 'Silver'">SILVER DETAILS</span>
    </td>
    <td>Nos</td>
    <td>Wt/g</td>
    <td>Tou</td>
    <td>S Wt</td>
    <td>Total Items</td>
  </tr>
  <tr style="text-align: center">
    <td>
      {{ navData.products[0]?.attributes.product }}
      <span *ngIf="navData.products.length < 1">&nbsp;</span>
    </td>
    <td><span *ngIf="navData.products.length >= 1">1</span></td>
    <td>{{ navData.products[0]?.attributes.weight }}</td>
    <td>{{ getTouch(navData.products[0]?.attributes.touch) }}</td>
    <td>{{ navData.products[0]?.attributes.less }}</td>
    <td>{{ navData.products[5]?.attributes.product }}</td>
    <td><span *ngIf="navData.products.length >= 6">1</span></td>
    <td>{{ navData.products[5]?.attributes.weight }}</td>
    <td>{{ getTouch(navData.products[5]?.attributes.touch) }}</td>
    <td>{{ navData.products[5]?.attributes.less }}</td>
    <td rowspan="2">
      {{ items }}
    </td>
  </tr>
  <tr style="text-align: center">
    <td>
      {{ navData.products[1]?.attributes.product }}
      <span *ngIf="navData.products.length < 2">&nbsp;</span>
    </td>
    <td><span *ngIf="navData.products.length >= 2">1</span></td>
    <td>{{ navData.products[1]?.attributes.weight }}</td>
    <td>{{ getTouch(navData.products[1]?.attributes.touch) }}</td>
    <td>{{ navData.products[1]?.attributes.less }}</td>
    <td>{{ navData.products[6]?.attributes.product }}</td>
    <td><span *ngIf="navData.products.length >= 7">1</span></td>
    <td>{{ navData.products[6]?.attributes.weight }}</td>
    <td>{{ getTouch(navData.products[6]?.attributes.touch) }}</td>
    <td>{{ navData.products[6]?.attributes.less }}</td>
  </tr>
  <tr style="text-align: center">
    <td>
      {{ navData.products[2]?.attributes.product }}
      <span *ngIf="navData.products.length < 3">&nbsp;</span>
    </td>
    <td><span *ngIf="navData.products.length >= 3">1</span></td>
    <td>{{ navData.products[2]?.attributes.weight }}</td>
    <td>{{ getTouch(navData.products[2]?.attributes.touch) }}</td>
    <td>{{ navData.products[2]?.attributes.less }}</td>
    <td>{{ navData.products[7]?.attributes.product }}</td>
    <td><span *ngIf="navData.products.length >= 8">1</span></td>
    <td>{{ navData.products[7]?.attributes.weight }}</td>
    <td>{{ getTouch(navData.products[7]?.attributes.touch) }}</td>
    <td>{{ navData.products[7]?.attributes.less }}</td>
    <td style="font-weight: bold">Total Wt/g</td>
  </tr>
  <tr style="text-align: center">
    <td>
      {{ navData.products[3]?.attributes.product }}
      <span *ngIf="navData.products.length < 4">&nbsp;</span>
    </td>
    <td><span *ngIf="navData.products.length >= 4">1</span></td>
    <td>{{ navData.products[3]?.attributes.weight }}</td>
    <td>{{ getTouch(navData.products[3]?.attributes.touch) }}</td>
    <td>{{ navData.products[3]?.attributes.less }}</td>
    <td>{{ navData.products[8]?.attributes.product }}</td>
    <td><span *ngIf="navData.products.length >= 9">1</span></td>
    <td>{{ navData.products[8]?.attributes.weight }}</td>
    <td>{{ getTouch(navData.products[8]?.attributes.touch) }}</td>
    <td>{{ navData.products[8]?.attributes.less }}</td>
    <td rowspan="2">
      {{ weight }}
    </td>
  </tr>
  <tr style="text-align: center">
    <td>
      {{ navData.products[4]?.attributes.product }}
      <span *ngIf="navData.products.length < 5">&nbsp;</span>
    </td>
    <td><span *ngIf="navData.products.length >= 5">1</span></td>
    <td>{{ navData.products[4]?.attributes.weight }}</td>
    <td>{{ getTouch(navData.products[4]?.attributes.touch) }}</td>
    <td>{{ navData.products[4]?.attributes.less }}</td>
    <td>{{ navData.products[9]?.attributes.product }}</td>
    <td><span *ngIf="navData.products.length >= 10">1</span></td>
    <td>{{ navData.products[9]?.attributes.weight }}</td>
    <td>{{ getTouch(navData.products[9]?.attributes.touch) }}</td>
    <td>{{ navData.products[9]?.attributes.less }}</td>
  </tr>
  <!-- Assets ends -->
  <tr style="text-align: center">
    <td colspan="2">Loan Details</td>
    <td>Interest</td>
    <td colspan="2">Interest Type</td>
    <td colspan="2">Interest Amount</td>
    <td colspan="2">Interest Date</td>
    <td colspan="3">Interest Cross Time</td>
    <td>Penalty</td>
  </tr>
  <tr style="text-align: center">
    <td colspan="2">
      <!-- Loan Details -->
      {{ navData.attributes.tenure }} Month Plan
    </td>
    <td>
      <!-- Interest -->
      {{ navData.attributes.roi }} %
    </td>
    <td colspan="2">
      <!-- Interest Type -->
      Diminishing
    </td>
    <td colspan="2">
      {{ calculateInterestForOneMonth() }}
    </td>
    <td colspan="2">{{ getDueDate(navData.attributes.date) }} / Month</td>
    <td colspan="3">3 Days</td>
    <td>0.1% / Day</td>
  </tr>
  <tr style="text-align: center;">
    <td colspan="3">Amount in Words</td>
    <td colspan="6">
      {{ amountinWords(navData.attributes.amount) }} Rupees Only
    </td>
    <td *ngIf="navData.attributes.scheme == 'Silver'" colspan="4">{{silver}}</td>
    <td *ngIf="navData.attributes.scheme != 'Silver'">{{nineone}}</td>
    <td *ngIf="navData.attributes.scheme != 'Silver'">{{seventyfive}}</td>
    <td *ngIf="navData.attributes.scheme != 'Silver'">{{eighty}}</td>
    <td *ngIf="navData.attributes.scheme != 'Silver'">{{eightyfive}}</td>
  </tr>
</table>

<!-- Page 1 ends -->
<table style="width: 100%; border: 1px solid #000" cellspacing="0">
  <tr>
    <td colspan="13" style="text-align: center; border-bottom: 0px">
      <img
        src="https://sricashway.com/wp-content/uploads/2024/05/sri-cashway-name-1280-X-180.png"
        style="height: auto; width: 900px"
      />
      <br />
      No-133, Bharathiyar Road, Maniyakarampalayam, Ganapathy,
      Coimbatore-641106. <br />
      L. No. 29/2022-2023, Call: 96299 33336, 98434 86662,
      <a style="color: #005fa7">(Gpay No: 98622 44014)</a>
    </td>
  </tr>
  <tr>
    <td
      colspan="2"
      style="text-align: center; border-top: 0px; border-right: 0px"
    ></td>
    <td
      colspan="9"
      style="text-align: center; border-top: 0px; border-left: 0px"
    >
      பணம் தேவையா? நகையை விற்க தேவையில்லை!
    </td>
    <td style="text-align: center; border-top: 2px solid #000">HC</td>
    <td style="text-align: center; border-top: 2px solid #000">
      {{ navData.attributes.fee }}
    </td>
  </tr>
  <tr>
    <td colspan="3" rowspan="2"><b>LOAN NO</b> : {{ navData.id }}</td>
    <td colspan="2" style="text-align: center">
      <b>Date</b> : {{ filterDate(navData.attributes.date, "D") }}
    </td>
    <td
      colspan="4"
      rowspan="2"
      style="
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: #fff;
        background-color: #930000;
      "
    >
      {{ navData.attributes.tenure }} Month Plan
    </td>
    <td colspan="2" style="text-align: center">
      <span *ngIf="navData.attributes.scheme != 'Silver'"
        >Gold Value Approx</span
      >
      <span *ngIf="navData.attributes.scheme == 'Silver'"
        >Silver Value Approx</span
      >
    </td>

    <td colspan="2" style="text-align: center">
      <b>Loan Amount</b> : Rs.{{ navData.attributes.amount }}
    </td>
  </tr>
  <tr>
    <td colspan="2" style="text-align: center">
      <b>Time</b> : {{ filterDate(navData.attributes.date, "T") }}
    </td>
    <td colspan="2" style="text-align: center">
      Rs.{{ getApproxAmt(navData.attributes.amount) }}
    </td>
    <td colspan="3" style="text-align: center">
      <b>Closing Date</b> :
      {{ getClosing(navData.attributes.date, navData.attributes.tenure) }}
    </td>
  </tr>
  <tr>
    <td colspan="11">
      <b>Address</b> <br />
      {{ custData.address }}
    </td>
    <td colspan="3" rowspan="9" style="width: 20%; text-align: center">
      <img [src]="custData.photo" style="width: 80%; height: auto" />
      <img
        [src]="navData.attributes.image"
        class="mt-1"
        style="width: 80%; height: auto"
      />
    </td>
  </tr>
  <tr>
    <td colspan="4">
      Contact : {{ custData.contact1 }}, {{ custData.contact2 }}
    </td>
    <td colspan="7">
      {{ getProofType(custData.proofType) }} No : {{ custData.proofno }}
    </td>
  </tr>
  <tr>
    <td colspan="4">Nominee Name : {{ custData.nominee }}</td>
    <td colspan="3">Relation: {{ custData.relation }}</td>
    <td colspan="4">Contact: {{ custData.nomineeContact }}</td>
  </tr>
  <!-- Asset Details starts -->
  <tr style="font-weight: bold; text-align: center">
    <td colspan="3">
      <span *ngIf="navData.attributes.scheme != 'Silver'">GOLD DETAILS</span>
      <span *ngIf="navData.attributes.scheme == 'Silver'">SILVER DETAILS</span>
    </td>
    <td>Nos</td>
    <td>Wt/g</td>
    <td colspan="3">
      <span *ngIf="navData.attributes.scheme != 'Silver'">GOLD DETAILS</span>
      <span *ngIf="navData.attributes.scheme == 'Silver'">SILVER DETAILS</span>
    </td>
    <td>Nos</td>
    <td>Wt/g</td>
    <td>Total Items</td>
  </tr>
  <tr style="text-align: center">
    <td colspan="3">
      {{ navData.products[0]?.attributes.product }}
      <span *ngIf="navData.products.length < 1">&nbsp;</span>
    </td>
    <td><span *ngIf="navData.products.length >= 1">1</span></td>
    <td>{{ navData.products[0]?.attributes.weight }}</td>
    <td colspan="3">{{ navData.products[5]?.attributes.product }}</td>
    <td><span *ngIf="navData.products.length >= 6">1</span></td>
    <td>{{ navData.products[5]?.attributes.weight }}</td>
    <td rowspan="2">
      {{ items }}
    </td>
  </tr>
  <tr style="text-align: center">
    <td colspan="3">
      {{ navData.products[1]?.attributes.product }}
      <span *ngIf="navData.products.length < 2">&nbsp;</span>
    </td>
    <td><span *ngIf="navData.products.length >= 2">1</span></td>
    <td>{{ navData.products[1]?.attributes.weight }}</td>
    <td colspan="3">{{ navData.products[6]?.attributes.product }}</td>
    <td><span *ngIf="navData.products.length >= 7">1</span></td>
    <td>{{ navData.products[6]?.attributes.weight }}</td>
  </tr>
  <tr style="text-align: center">
    <td colspan="3">
      {{ navData.products[2]?.attributes.product }}
      <span *ngIf="navData.products.length < 3">&nbsp;</span>
    </td>
    <td><span *ngIf="navData.products.length >= 3">1</span></td>
    <td>{{ navData.products[2]?.attributes.weight }}</td>
    <td colspan="3">{{ navData.products[7]?.attributes.product }}</td>
    <td><span *ngIf="navData.products.length >= 8">1</span></td>
    <td>{{ navData.products[7]?.attributes.weight }}</td>
    <td style="font-weight: bold">Total Wt/g</td>
  </tr>
  <tr style="text-align: center">
    <td colspan="3">
      {{ navData.products[3]?.attributes.product }}
      <span *ngIf="navData.products.length < 4">&nbsp;</span>
    </td>
    <td><span *ngIf="navData.products.length >= 4">1</span></td>
    <td>{{ navData.products[3]?.attributes.weight }}</td>
    <td colspan="3">{{ navData.products[8]?.attributes.product }}</td>
    <td><span *ngIf="navData.products.length >= 9">1</span></td>
    <td>{{ navData.products[8]?.attributes.weight }}</td>
    <td rowspan="2">
      {{ weight }}
    </td>
  </tr>
  <tr style="text-align: center">
    <td colspan="3">
      {{ navData.products[4]?.attributes.product }}
      <span *ngIf="navData.products.length < 5">&nbsp;</span>
    </td>
    <td><span *ngIf="navData.products.length >= 5">1</span></td>
    <td>{{ navData.products[4]?.attributes.weight }}</td>
    <td colspan="3">{{ navData.products[9]?.attributes.product }}</td>
    <td><span *ngIf="navData.products.length >= 10">1</span></td>
    <td>{{ navData.products[9]?.attributes.weight }}</td>
  </tr>
  <!-- Assets ends -->
  <tr style="text-align: center">
    <td colspan="2">Loan Details</td>
    <td>Interest</td>
    <td colspan="2">Interest Type</td>
    <td colspan="2">Interest Amount</td>
    <td colspan="2">Interest Date</td>
    <td colspan="3">Interest Cross Time</td>
    <td>Penalty</td>
  </tr>
  <tr style="text-align: center">
    <td colspan="2">
      <!-- Loan Details -->
      {{ navData.attributes.tenure }} Month Plan
    </td>
    <td>
      <!-- Interest -->
      {{ navData.attributes.roi }} %
    </td>
    <td colspan="2">
      <!-- Interest Type -->
      Diminishing
    </td>
    <td colspan="2">
      {{ calculateInterestForOneMonth() }}
    </td>
    <td colspan="2">{{ getDueDate(navData.attributes.date) }} / Month</td>
    <td colspan="3">3 Days</td>
    <td>0.1% / Day</td>
  </tr>
  <tr>
    <td colspan="3">Amount in Words</td>
    <td colspan="10">
      {{ amountinWords(navData.attributes.amount) }} Rupees Only
    </td>
  </tr>
</table>
