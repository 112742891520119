import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './containers';
import { ProductsComponent } from './views/pages/products/products.component';
import { CustomersComponent } from './views/pages/customers/customers.component';
import { SchemesComponent } from './views/pages/schemes/schemes.component';
import { PaymentsComponent } from './views/pages/payments/payments.component';
import { LoanComponent } from './views/pages/loan/loan.component';
import { UsersComponent } from './views/pages/users/users.component';
import { BranchComponent } from './views/pages/branch/branch.component';
import { CustomerDetailsComponent } from './views/pages/customer-details/customer-details.component';
import { RolesComponent } from './views/pages/roles/roles.component';
import { WebCamComponent } from './views/web-cam/web-cam.component';
import { LoginComponent } from './views/pages/login/login.component';
import { DiyPaymentComponent } from './views/pages/diy-payment/diy-payment.component';
import { AuthGuard } from './auth.guard';

import { CalculatorComponent } from './views/pages/calculator/calculator.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'theme',
        loadChildren: () =>
          import('./views/theme/theme.module').then((m) => m.ThemeModule)
      },
      {
        path: 'base',
        loadChildren: () =>
          import('./views/base/base.module').then((m) => m.BaseModule)
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./views/forms/forms.module').then((m) => m.CoreUIFormsModule)
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./views/charts/charts.module').then((m) => m.ChartsModule)
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./views/icons/icons.module').then((m) => m.IconsModule)
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./views/pages/pages.module').then((m) => m.PagesModule)
      },
      {
        path: 'customers',
        component: CustomersComponent,
        data: {
          title: 'Customers'
        }
      },
      {
        path: 'products',
        component: ProductsComponent,
        data: {
          title: 'Products'
        }
      },
      {
        path: 'customers',
        component: CustomersComponent,
        data: {
          title: 'Customers'
        },
    children: []
      },
      {
        path: 'loans',
        component: LoanComponent,
        data: {
          title: 'Loans'
        }
      },
      {
        path: 'schemes',
        component: SchemesComponent,
        data: {
          title: 'Schemes'
        }
      },
      {
        path: 'payments',
        component: PaymentsComponent,
        data: {
          title: 'Payments'
        }
      },
      {
        path: 'Branch',
        component: BranchComponent,
        data: {
          title: 'Branch'
        }
      },
      {
        path: 'Users',
        component: UsersComponent,
        data: {
          title: 'Users'
        }
      },
      {
        path: 'customers/loan-details/:id',
        component: CustomerDetailsComponent,
        data: {
          title: 'Customers / Loans'
        }
      },
      {
        path: 'customers/payments/:id',
        component: PaymentsComponent,
        data: {
          title: 'Customers / Loans / Payments'
        }
      },
      {
        path: 'preveliges',
        component: RolesComponent,
        data: {
          title: 'User Preveliges'
        }
      },
      {
        path: 'camera',
        component: WebCamComponent,
        data: {
          title: 'Web Camera'
        }
        },
        {
          path: 'calculator',
          component: CalculatorComponent,
          data: {
            title: 'Calculator'
          }
        }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'make-payment',
    component: DiyPaymentComponent,
  },
  {path: '**', redirectTo: 'dashboard'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
